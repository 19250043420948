import React, { Fragment, memo, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Collapse, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Badge, Box, Typography } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { styled } from "@mui/material/styles";

// *Imported Components
import colors from "app/styles/colors";
import useAuth from "app/hooks/useAuth";
import Images from "app/assets/Images";
import Navigation from "app/Navigation";
import useNotification from "app/hooks/useNotification";

//* badge style For Message Notification
const StyledAvtBadge = styled(Badge)(() => ({
  "& .MuiBadge-badge": {
    backgroundColor: colors.darkMintGreen,
    right: 3,
    top: 1,
    border: `4px solid ${colors.white}`,
    padding: "1 4px",
  },
}));

const drawerWidth = 270;

function SideNav() {
  const { pathname } = useLocation();
  const { role } = useAuth();
  const { notifyMessage } = useNotification();

  const [expand, setExpand] = useState([]);

  // *For Collapse
  const handleCollapse = (value) => {
    const currentIndex = expand.indexOf(value);
    const newExpand = [...expand];

    if (currentIndex === -1) {
      newExpand.push(value);
    } else {
      newExpand.splice(currentIndex, 1);
    }

    setExpand(newExpand);
  };

  // *For Active
  const handleSubChildActive = (item) => {
    return item.childPath?.indexOf(pathname) !== -1 && item.childPath?.indexOf(pathname);
  };

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
          bgcolor: colors.white,
          overflowY: "scroll",
          "&::-webkit-scrollbar": {
            width: 6,
            bgcolor: "transparent",
          },
          "&::-webkit-scrollbar-thumb": {
            borderRadius: 1,
            bgcolor: colors.primary,
          },
        },
      }}
      variant="permanent"
      anchor="left"
    >
      <Box sx={{ display: "flex", p: 1, justifyContent: "center", mb: 1 }}>
        <img src={Images.logo} alt="Takseem" width="70%" />
      </Box>
      <List sx={{ px: 2 }}>
        {Navigation.map((item, index) =>
          role === item.type || item.type === "" ? (
            <Fragment key={index}>
              <ListItemButton
                key={index}
                component={item.path ? Link : "div"}
                to={item.path ? item.path : ""}
                onClick={() => item.childPath && handleCollapse(item.label)}
                sx={{
                  borderRadius: 1,
                  // mt: 1.5,
                  bgcolor: item.path === pathname || handleSubChildActive(item) || handleSubChildActive(item) === 0 ? colors.primaryLight : "",
                  "&:hover": {
                    bgcolor: colors.primaryLight,
                    color: colors.primary,
                    ".MuiTypography-root": {
                      color: colors.primary,
                    },
                    ".MuiListItemIcon-root": {
                      color: colors.primary,
                    },
                  },
                }}
              >
                <ListItemIcon
                  sx={{
                    color: item.path === pathname || handleSubChildActive(item) || handleSubChildActive(item) === 0 ? colors.primary : colors.textSecondary,
                    minWidth: 40,
                    fontSize: "20px",
                  }}
                >
                  {item.icon}
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography
                      type="body2"
                      sx={{
                        fontSize: 13,
                        fontWeight: item.path === pathname || handleSubChildActive(item) || handleSubChildActive(item) === 0 ? "bold" : "normal",
                        color: item.path === pathname || handleSubChildActive(item) || handleSubChildActive(item) === 0 ? colors.primary : colors.textSecondary,
                      }}
                    >
                      {item.label}
                    </Typography>
                  }
                />
                {item.label === "Chat Support" && notifyMessage ? <StyledAvtBadge badgeContent={""} /> : ""}
                {item?.children && (expand.indexOf(item.label) !== -1 ? <ExpandLess sx={{ color: colors.textSecondary }} /> : <ExpandMore sx={{ color: colors.textSecondary }} />)}
              </ListItemButton>
              {item?.children && (
                <Collapse in={expand.indexOf(item.label) !== -1 || item.childPath?.indexOf(pathname) !== -1 ? true : false} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {item.children.map((child, i) => (
                      <Fragment>
                        <ListItemButton
                          key={i}
                          component={child.path ? Link : "div"}
                          to={child.path ? child.path : ""}
                          onClick={() => child.path && handleCollapse(child.label)}
                          sx={{
                            pl: 4,
                            borderRadius: 1,
                            mt: 1.5,
                            "&:hover": {
                              color: colors.primary,
                              bgcolor: colors.white,
                              ".MuiListItemIcon-root ": {
                                color: colors.primary,
                              },
                            },
                          }}
                        >
                          <ListItemIcon sx={{ color: child.path === pathname ? colors.primary : colors.textSecondary, minWidth: 30, ".MuiSvgIcon-root": { fontSize: "18px" } }}>
                            {child.icon}
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Typography
                                type="body2"
                                sx={{
                                  fontSize: 13,
                                  fontWeight: child.path === pathname ? "bold" : "normal",
                                  color: child.path === pathname ? colors.primary : colors.textSecondary,
                                }}
                              >
                                {child.label}
                              </Typography>
                            }
                          />
                          {child?.children && (expand.indexOf(item.label) !== -1 ? <ExpandLess sx={{ color: colors.textSecondary }} /> : <ExpandMore sx={{ color: colors.textSecondary }} />)}
                        </ListItemButton>
                        {child?.children && (
                          <Collapse in={expand.indexOf(child.label) !== -1 ? true : false} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                              {child.children.map((subChild, i) => (
                                <ListItemButton
                                  key={i}
                                  component={Link}
                                  to={subChild.path}
                                  sx={{
                                    pl: 4,
                                    borderRadius: 1,
                                    mt: 1.5,
                                    bgcolor: subChild.path === pathname && colors.secondary,
                                    "&:hover": {
                                      bgcolor: colors.secondary,
                                    },
                                  }}
                                >
                                  <ListItemIcon sx={{ color: colors.white, minWidth: 30, ".MuiSvgIcon-root": { fontSize: 15 } }}>{subChild.icon}</ListItemIcon>
                                  <ListItemText primary={subChild.label} sx={{ ".MuiTypography-root": { fontSize: 15, textTransform: "capitalize" } }} />
                                </ListItemButton>
                              ))}
                            </List>
                          </Collapse>
                        )}
                      </Fragment>
                    ))}
                  </List>
                </Collapse>
              )}
            </Fragment>
          ) : (
            ""
          )
        )}
      </List>
    </Drawer>
  );
}

export default memo(SideNav);
