import React, { Fragment, useEffect, useState } from "react";
import { Box, Divider, Grid, Typography } from "@mui/material";
import { BsBuilding, BsFillCalendar2MinusFill, BsFillPersonFill, } from "react-icons/bs";
import { AiFillCar } from "react-icons/ai";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, LineElement, PointElement } from "chart.js";
import { makeStyles } from "@mui/styles";
import { Bar, Line } from "react-chartjs-2";
import { faker } from "@faker-js/faker";
import { ExpandLess } from "@mui/icons-material";
import clsx from "clsx"
import colors from "app/styles/colors";
import SecondaryCard from "app/components/Cards/SecondaryCard";
import PrimaryCard from "app/components/Cards/PrimaryCard";
import { ErrorToaster } from "app/components/Toaster/Toaster";
import DashboardServices from "app/apis/Dashboard/DashboardServices";
import { Loading } from "app/assets/Images";
import useAuth from "app/hooks/useAuth";

// *import components

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
);

const useStyles = makeStyles({
  iconStyle: {
    fontSize: "30px",
    borderRadius: "8px",
    zIndex: 99,
    padding: "10px",
    position: "relative",
    top: -15
  },
  smallIconStyle: {
    borderRadius: "8px",
    padding: 8,
    fontSize: "24px",
    marginRight: 12,
  },
  typeIconBgColor: {
    backgroundColor: "#a8b1af66"
  },
  makeIconBgColor: {
    backgroundColor: "#eddaa98f"
  },
})

function Dashboard() {

  const classes = useStyles()
  const { role } = useAuth();
  // Chart  Object
  const options = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
      },
      title: {
        display: true,
        text: "Total Growth",
        fontSize: "18px",
      },
    },
  };

  const labels = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  const data = {
    labels,
    datasets: [
      {
        label: "Rides",
        data: labels.map(() => faker.datatype.number({ min: 0, max: 100 })),
        backgroundColor: colors.primaryDark,
      },
      {
        label: "Employees",
        data: labels.map(() => faker.datatype.number({ min: 0, max: 100 })),
        backgroundColor: colors.secondaryDark,
      },
    ],
  };

  const lineOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'CO2 Emissions',
      },
    },
  };

  // const lineLabels = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  const lineData = {
    labels,
    datasets: [
      {
        label: 'Monthly Emissions',
        data: [720, 839, 408, 421, 600, 345, 730, 599, 515, 490, 650, 790],
        borderColor: colors.secondary,
        backgroundColor: colors.secondary + '80',
      },
      {
        label: 'Co2 Footprint Produce',
        data: [820, 909, 708, 681, 820, 545, 930, 830, 670, 780, 815, 945],
        borderColor: colors.primary,
        backgroundColor: colors.primary + '80',
      },
      {
        label: 'Co2 Footprint Saved',
        data: [780, 850, 500, 581, 710, 425, 840, 710, 570, 620, 700, 845],
        borderColor: colors.secondaryLight,
        backgroundColor: colors.secondaryLight + '80',
      },
    ],
  };

  console.log(lineData.datasets[0].data)

  const [stats, setStats] = useState([]);
  const [loading, setLoading] = useState(true);

  // *For Get Company Stats
  const getDashboardStats = async () => {
    try {
      const { status, responseCode, data } = await DashboardServices.getDashboardStats()
      if (status === true && responseCode === 200) {
        setStats(data)
      }
    } catch (error) {
      ErrorToaster(error)
    }
    finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getDashboardStats()
  }, []);

  return (
    <Fragment>
      <Grid
        container
        justifyContent={"space-between"}
        sx={{
          backgroundColor: colors.lightGray,
          borderRadius: "12px",
          p: 3,
          width: "100%",
        }}
      >
        {loading ? <Box sx={{ width: 1, textAlign: "center", pt: 5 }}>
          <Loading />
        </Box> :
          <Fragment>
            <Grid item xs={12} md={3.9}>
              <PrimaryCard
                icon={<BsBuilding
                  className={classes.iconStyle}
                  color={colors.primaryLight}
                />}
                number={stats?.companiesCount}
                name={role === "admin" ? "Total Companies" : "Total Locations"}
                color={colors.primaryLight}
                bgColor={colors.primary}
                circle1={"#0c7548d4"}
                circle2={"#3f6a5838"}
              />
            </Grid>
            <Grid item xs={12} md={3.9}>
              <PrimaryCard
                icon={<BsFillCalendar2MinusFill
                  className={classes.iconStyle}
                  color={colors.secondaryLight}
                />}
                number={stats?.bookingsCount}
                name={"Total Bookings"}
                color={colors.primaryLight}
                bgColor={colors.secondary}
                circle1={"#100f167a"}
                circle2={"#ffffff2e"}
              />
            </Grid>

            <Grid item xs={12} md={3.9}>
              <SecondaryCard icon={<BsFillPersonFill
                className={clsx(classes.smallIconStyle, classes.typeIconBgColor)}
                color={colors.secondaryLight}
              />}
                number={stats?.employeesCount}
                name={"Total Employees"}
                nameColor={colors.secondaryLight}
                color={colors.gunMetal}
                bgColor={colors.cardBg}
                circle1={"#dfd1d09e"}
                circle2={"#ebcfcc"}
              />
              <SecondaryCard icon={<AiFillCar
                className={clsx(classes.smallIconStyle, classes.makeIconBgColor)}
                color={colors.artyClickAmber}
              />}
                number={stats?.vehiclesCount}
                name={"Total Vehicles"}
                color={colors.gunMetal}
                nameColor={colors.artyClickAmber}
                bgColor={colors.eggSour}
                circle1={"#ffc1073d"}
                circle2={colors.eggSour}
              />
            </Grid>

            <Grid item xs={12} md={7.9}>
              {role === 'admin' &&
                <Box
                  sx={{
                    backgroundColor: colors.white,
                    my: 2,
                    p: 2,
                    borderRadius: "12px",
                    height: 350
                  }}
                >
                  <Bar options={options} data={data} />
                </Box>
              }
              {role !== 'admin' &&
                <Box
                  sx={{
                    backgroundColor: colors.white,
                    my: 2,
                    p: 2,
                    borderRadius: "12px",
                    height: 350
                  }}
                >
                  <Line options={lineOptions} data={lineData} />
                </Box>
              }
            </Grid>
            <Grid item xs={12} md={3.9}>
              <Box
                sx={{
                  backgroundColor: colors.white,
                  p: 2,
                  my: 2,
                  borderRadius: "12px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography sx={{ fontWeight: "bold", mb: 2 }}>
                    Popular Car Providers
                  </Typography>
                  {/* <BiDotsVerticalRounded
                  style={{
                    transform: `rotate(90deg)`,
                    fontSize: "25px",
                    color: colors.textSecondary,
                    cursor: "pointer",
                  }}
                /> */}
                </Box>
                {stats?.popularDriver?.map((item, index) => (
                  <Fragment key={index}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        minHeight: "29px",
                      }}
                    >
                      <Box>
                        <Typography
                          sx={{
                            fontSize: "16px",
                            fontWeight: "bold",
                            color: colors.textSecondary,
                          }}
                        >
                          {item.name}
                        </Typography>
                        {/* <Typography
                        sx={{ fontSize: "12px", color: colors.darkMintGreen }}
                      >
                        Total Rides: {item.ridesCount}
                      </Typography> */}
                      </Box>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography
                          sx={{ fontSize: "13px", color: colors.textSecondary, mr: 2 }}
                        >
                          Total Rides: {item.ridesCount}
                        </Typography>
                        {/* <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "bold",
                          color: colors.textSecondary,
                          mr: 3,
                        }}
                      >
                        Total Rides
                        <b>{item.ridesCount}</b>
                      </Typography> */}
                        <ExpandLess
                          sx={{
                            color: colors.primary,
                            backgroundColor: colors.primaryLight,
                            p: 0.5,
                            borderRadius: "6px",
                            fontSize: "18px",
                          }}
                        />
                      </Box>
                    </Box>
                    <Divider
                      sx={{
                        color: colors.textSecondary,
                        width: "100%",
                        my: 2,
                      }}
                    />
                  </Fragment>
                ))}
                {/* {[...Array(Number(2))].map((item, index) => (
                <Fragment key={index}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Box>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "bold",
                          color: colors.textSecondary,
                        }}
                      >
                        Rushan
                      </Typography>
                      <Typography
                        sx={{ fontSize: "12px", color: colors.darkMintGreen }}
                      >
                        10% Profit
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "bold",
                          color: colors.textSecondary,
                          mr: 3,
                        }}
                      >
                        $1839.00
                      </Typography>
                      <ExpandLess
                        sx={{
                          color: colors.primary,
                          backgroundColor: colors.primaryLight,
                          p: 0.5,
                          borderRadius: "6px",
                          fontSize: "18px",
                        }}
                      />
                    </Box>
                  </Box>
                  <Divider
                    sx={{
                      coolor: colors.textSecondary,
                      width: "100%",
                      my: 2,
                    }}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Box>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "bold",
                          color: colors.textSecondary,
                        }}
                      >
                        Shahriyar
                      </Typography>
                      <Typography
                        sx={{ fontSize: "12px", color: colors.grapeFruit }}
                      >
                        10% Loss
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "bold",
                          color: colors.textSecondary,
                          mr: 3,
                        }}
                      >
                        $100.00
                      </Typography>
                      <ExpandMore
                        sx={{
                          color: colors.grapeFruit,
                          backgroundColor: colors.dawnPink,
                          p: 0.5,
                          borderRadius: "6px",
                          fontSize: "18px",
                        }}
                      />
                    </Box>
                  </Box>
                  <Divider
                    sx={{
                      coolor: colors.textSecondary,
                      width: "100%",
                      my: 2,
                    }}
                  />
                </Fragment>
              ))} */}
                {/* <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  justifyContent: "center",
                  flexDirection: "row",
                }}
              >
                <Typography color={colors.textSecondary}>View All</Typography>
                <ArrowForwardIos sx={{ ml: 1, fontSize: "16px" }} />
              </Box> */}
              </Box>
              <Box
                sx={{
                  backgroundColor: colors.white,
                  p: 2,
                  my: 2,
                  borderRadius: "12px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography sx={{ fontWeight: "bold", mb: 2 }}>
                    Popular Passengers
                  </Typography>
                </Box>
                {stats?.popularDriver?.slice(0, 2).map((item, index) => (
                  <Fragment key={index}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        minHeight: "29px",
                      }}
                    >
                      <Box>
                        <Typography
                          sx={{
                            fontSize: "16px",
                            fontWeight: "bold",
                            color: colors.textSecondary,
                          }}
                        >
                          {item.name}
                        </Typography>
                      </Box>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography
                          sx={{ fontSize: "13px", color: colors.textSecondary, mr: 2 }}
                        >
                          Total Rides: {item.ridesCount}
                        </Typography>
                        <ExpandLess
                          sx={{
                            color: colors.primary,
                            backgroundColor: colors.primaryLight,
                            p: 0.5,
                            borderRadius: "6px",
                            fontSize: "18px",
                          }}
                        />
                      </Box>
                    </Box>
                    <Divider
                      sx={{
                        color: colors.textSecondary,
                        width: "100%",
                        my: 2,
                      }}
                    />
                  </Fragment>
                ))}
              </Box>
            </Grid>
          </Fragment>
        }
      </Grid>
    </Fragment>
  );
}

export default Dashboard;
