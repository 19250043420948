import React from "react";
import { Box, Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography } from "@mui/material";
import { CloseRounded } from "@mui/icons-material";
import SelectField from "app/components/UI/SelectFields";
import { Divider, TextField, FormControl } from "@mui/material";
import { InputField } from "app/components/UI/TextField";
import { PrimaryButton } from "app/components/Buttons/Button";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { SuccessToaster, ErrorToaster } from "app/components/Toaster/Toaster";
import PricingServices from "app/apis/PricingConfig/PricingServices";

const AddOilPriceDialog = ({ open, handleClose, getFuelType, countryName }) => {
  const [loading, setLoading] = useState(false);
  const [countryId, setCountryId] = useState("");
  const [typeName, setTypeName] = useState("");
  const [oilPrice, setOilPrice] = useState("");
  const [currency, setCurrency] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm();

  const defaultPrice = (price) => {
    setValue("oilPrice", price);
  };

  // *For Update oil Price
  const addOilPrice = async (formData) => {
    try {
      let obj = {
        id: countryId,
        oil_price_petrol: formData.oilPricePetrol,
        oil_price_diesel: formData.oilPriceDiesel,
      };
      const { status, responseCode, message } = await PricingServices.updateOilPrice(obj);
      if (status === true && responseCode === 200) {
        SuccessToaster(message);
        reset();
        handleClose();
        getFuelType();
      }
    } catch (error) {
      ErrorToaster(error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Dialog
      open={open}
      fullWidth
      onClose={() => {
        handleClose();
      }}
      sx={{ "& .MuiDialog-paper": { borderRadius: "12px" } }}
    >
      <DialogTitle sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        Update Oil Price
        <IconButton
          onClick={() => {
            handleClose();
          }}
        >
          <CloseRounded />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Typography component={"form"} onSubmit={handleSubmit(addOilPrice)}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={4}>
              <SelectField
                setState={(item) => {
                  setCountryId(item.id);
                  setTypeName(item.name);
                  defaultPrice(item.oil_price);
                  setOilPrice(item.oil_price);
                  setCurrency(item.currency);
                }}
                stateValue={typeName}
                data={countryName}
                label={"Country"}
                error={errors?.countryId?.message}
                register={register("countryId", {
                  required: "Please Select Country ID.",
                })}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <InputField
                defaultValue={oilPrice}
                InputLabelProps={{ shrink: true, required: true }}
                label="Oil Price"
                error={errors?.oilPricePetrol?.message}
                register={register("oilPrice", {
                  required: "Please enter Oil Price.",
                })}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <InputField
                defaultValue={oilPrice}
                InputLabelProps={{ shrink: true, required: true }}
                label="Diesel Price"
                error={errors?.oilPriceDiesel?.message}
                register={register("oilPrice", {
                  required: "Please enter Oil Price.",
                })}
              />
            </Grid>
            <Grid item xs={8} display="flex" justifyContent="flex-end">
              <Typography sx={{ fontSize: "11px", mt: -4 }}>{currency}</Typography>
            </Grid>
            <Grid item xs={4} display="flex" justifyContent="flex-end">
              <Typography sx={{ fontSize: "11px", mt: -4 }}>{currency}</Typography>
            </Grid>
          </Grid>
          <Box display="flex" justifyContent="center">
            <PrimaryButton
              // data-testId="save-btn"
              type="submit"
              title={"Update"}
              loading={loading}
            />
          </Box>
        </Typography>
      </DialogContent>
    </Dialog>
  );
};

export default AddOilPriceDialog;
