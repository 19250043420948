import React, { Fragment } from "react";
import { Box } from "@mui/material";

// *Import Components
import Header from "./Header";
import SideNav from "./SideNav";
import ScrollTop from "app/hooks/scrollTop";
import { Outlet } from "react-router-dom";

function Layout({ children }) {
  return (
    <Fragment>

      {/* ========== Header ========== */}

      <Header />

      <Box sx={{ display: "flex" }}>

        {/* ========== SideNav ========== */}
        <Box>
          <SideNav />
        </Box>

        <Box sx={{ mt: "100px", mx: 2, flexGrow: 1 }}>
          {/* {children} */}
          <Outlet />
        </Box>

      </Box>

      {/* ========== Scroll Top ========== */}
      <ScrollTop />

    </Fragment>
  );
}

export default Layout;
