import { get, post, patch, deleted } from 'app/apis';
import DashboardRoutes from './Dashboard.Routes';

const DashboardServices = {
  getDashboardStats: async () => {
    const data = await get(DashboardRoutes.getDashboardStats);
    return data;
  },
}

export default DashboardServices