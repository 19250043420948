import { get, post, patch, deleted } from 'app/apis';
import BookingsRoutes from './Bookings.Routes';

const BookingsServices = {
  getBookings: async () => {
    const data = await get(BookingsRoutes.getBookings);
    return data;
  },
  getBookingDetails: async (id) => {
    const data = await get(BookingsRoutes.getBookingDetails + `?booking_id=${id}`);
    return data;
  },
}

export default BookingsServices