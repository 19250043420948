import { get, post, patch, deleted } from "app/apis";
import PricingConfigRoutes from "./PricingConfig.Routes";

const PricingServices = {
  getPricing: async () => {
    const data = await get(PricingConfigRoutes.getPricing);
    return data;
  },
  updatePricing: async (obj) => {
    const data = await patch(PricingConfigRoutes.updatePricing, obj);
    return data;
  },
  // getFuelType: async (page, rows, search, countries) => {
  //   const data = await get(PricingConfigRoutes.getFuelType + `?page=${page}` + `&size=${rows}` + `&search=${search}` + `&countries=${countries}`);
  //   return data;
  // },
  // *update on 25/5/23
  getFuelType: async (params) => {
    const data = await get(PricingConfigRoutes.getFuelType, params);
    return data;
  },
  createFuelType: async (obj) => {
    const data = await post(PricingConfigRoutes.createFuelType, obj);
    return data;
  },
  deleteFuelType: async (id) => {
    const data = await deleted(PricingConfigRoutes.deleteFuelType + `?id=${id}`);
    return data;
  },
  updateFuelType: async (obj) => {
    const data = await patch(PricingConfigRoutes.updateFuelType, obj);
    return data;
  },
  updateOilPrice: async (obj) => {
    const data = await patch(PricingConfigRoutes.updateOilPrice, obj);
    return data;
  },
};

export default PricingServices;
