import { Box } from "@mui/material";

import { Loading } from "app/assets/Images";

export const LoadingCircle = () => {
  return (
    <Box sx={{ display: 'block', position: 'relative', justifyContent: 'center', alignItems: 'center', minHeight: 380, height: 'calc(100vh - 210px)' }}>
      <Box sx={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%,-50%)' }}>
        <Loading />
      </Box>
    </Box>
  );
}