import { get, post, patch, deleted } from 'app/apis';
import AuthRoutes from './Auth.Routes';

const AuthServices = {
  login: async (obj) => {
    const data = await post(AuthRoutes.login, obj);
    return data;
  },
  register: async (obj) => {
    const data = await post(AuthRoutes.register, obj);
    return data;
  },
  changePassword: async (obj) => {
    const data = await post(AuthRoutes.changePassword, obj);
    return data;
  },
}

export default AuthServices