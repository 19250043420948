import React, { Fragment, useEffect, useRef, useState } from 'react'
import { Box, Button, Divider, FormControl, Grid, IconButton, InputAdornment, Tab, Tabs, TextField, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { LockOpenTwoTone, LoopTwoTone, PasswordTwoTone, PersonOutlineTwoTone, Visibility, VisibilityOff, VpnKeyTwoTone } from '@mui/icons-material'
import { useForm } from 'react-hook-form'
import PhoneInput from 'react-phone-input-2'
import { makeStyles } from "@mui/styles";
import useAuth from 'app/hooks/useAuth'
import colors from 'app/styles/colors'
import BreadCrumbs from 'app/components/Breadcrumbs/Breadcrumbs'
import Layout from 'app/layout/Layout'
import { ErrorToaster, SuccessToaster } from 'app/components/Toaster/Toaster'
import AuthServices from 'app/apis/Auth/AuthServices'
import { OutlineInputField } from 'app/components/UI/TextField'
import { PrimaryButton } from 'app/components/Buttons/Button'

function AccountSettings() {

  const { email, companyCode } = useAuth()
  const navigate = useNavigate()
  const { register, handleSubmit, formState: { errors }, setValue, watch, reset } = useForm();
  const password = useRef({});
  password.current = watch("password", "");

  const [currentTab, setCurrentTab] = useState("0");
  const [telephoneNumber, setTelephoneNumber] = useState("")
  const [selectedProfileImage, setSelectedProfileImage] = useState("");
  const [selectedNicImage, setSelectedNicImage] = useState("");
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(false);


  const handleTelephoneNumber = (value) => {
    setValue('telephone', value);
    setTelephoneNumber(value)
  }

  // *For Profile Image change
  const imageChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedProfileImage(URL.createObjectURL(e.target.files[0]));

    }
  };
  // *For NIC Image change
  const imageNicChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedNicImage(URL.createObjectURL(e.target.files[0]));

    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword)
  };

  const handleClickShowOldPassword = () => {
    setShowOldPassword(!showOldPassword)
  };

  const handleChangePassword = async (formData) => {
    setLoading(true)
    try {
      let obj = {
        email,
        old_password: formData.oldPassword,
        password: formData.password
      }
      const { status, responseCode, message } = await AuthServices.changePassword(obj)
      if (status === true && responseCode === 200) {
        SuccessToaster(message)
        navigate("/")
        reset()
      }

    } catch (error) {
      ErrorToaster(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Fragment>

      <Box sx={{ backgroundColor: colors.lightGray, p: 3, borderRadius: "12px" }}>
        <Grid container
          alignItems="center"
          justifyContent="space-between"
          sx={{
            bgcolor: colors.white, p: 2, borderRadius: "12px",
          }}>
          <Grid item xs={6}>
            <Typography variant="body1" fontWeight="bold" color="initial">Company Settings</Typography>
          </Grid>
          <Grid item xs={6} sx={{ display: "flex", justifyContent: "flex-end" }}>
            <BreadCrumbs currentPage={['Company Settings']} />
          </Grid>
        </Grid>

        <Box sx={{ bgcolor: colors.white, p: 3, borderRadius: "12px", mt: 2 }}>
          <TabContext value={currentTab}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={currentTab} onChange={(e, newValue) => setCurrentTab(newValue)} aria-label="basic tabs example">
                <Tab value={"0"} icon={<PasswordTwoTone />}
                  iconPosition="start" label="Company Code" sx={{ fontWeight: currentTab === "0" ? "bold" : 100, textTransform: "capitalize", minHeight: "auto" }} />
                <Tab value={"1"} icon={<LockOpenTwoTone />} iconPosition="start" label="Change Password" sx={{ fontWeight: currentTab === "1" ? "bold" : 100, textTransform: "capitalize", minHeight: "auto" }} />
              </Tabs>
            </Box>
            <TabPanel value={"0"} sx={{ px: 0 }}>
              <Box sx={{ textAlign: "center" }} >
                <Typography variant="h1" color="primary" sx={{ fontWeight: "bold" }}>{companyCode}</Typography>
              </Box>
            </TabPanel>
            <TabPanel value={"1"} sx={{ px: 0 }}>
              <Typography component={"form"} onSubmit={handleSubmit(handleChangePassword)}>
                <Grid container spacing={2} >
                  <Grid item container xs={6}>
                    {/* old password */}
                    <Grid item xs={12}>
                      <OutlineInputField
                        label="Old Password"
                        type={showOldPassword ? 'text' : 'password'}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => { handleClickShowOldPassword() }}
                              edge="end"
                              sx={{ color: colors.darkMintGreen, fontSize: "18px" }}
                            >
                              {showOldPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        }
                        error={errors?.oldPassword?.message}
                        register={register("oldPassword", {
                          required: 'Please enter oldPassword.'
                        })
                        }
                      />
                    </Grid>
                    {/* new password */}
                    <Grid item xs={12}>
                      <OutlineInputField
                        label="Password"
                        type={showPassword ? 'text' : 'password'}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => { handleClickShowPassword() }}
                              edge="end"
                              sx={{ color: colors.darkMintGreen, fontSize: "18px" }}
                            >
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        }
                        error={errors?.password?.message}
                        register={register("password", {
                          required: 'Please enter password.',
                          minLength: {
                            value: 6,
                            message: "Minimum length is 6"
                          }
                        })

                        }
                      />
                    </Grid>
                    {/* confirm password */}
                    <Grid item xs={12}>
                      <OutlineInputField
                        label="Confirm Password"
                        type={showConfirmPassword ? 'text' : 'password'}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => { handleClickShowConfirmPassword() }}
                              edge="end"
                              sx={{ color: colors.darkMintGreen, fontSize: "18px" }}
                            >
                              {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        }
                        error={errors?.confirm_password?.message}
                        register={register("confirm_password", {
                          required: 'Please enter confirm password.',
                          validate: value =>
                            value === password.current || "Confirm password does not match"
                        })
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Divider sx={{ mt: 3 }} />
                {/* <PrimaryButton myStyle={{ mt: 2 }} loading={loading} type="submit">Save Changes</PrimaryButton> */}
                <PrimaryButton
                  data-testId="save-btn"
                  title={"Save Changes"}
                  type="submit"
                  loading={loading}
                />
              </Typography>
            </TabPanel>
          </TabContext>
        </Box>

      </Box>
    </Fragment>
  )
}

export default AccountSettings