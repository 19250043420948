import React, { useState } from 'react'
import { Box, Button, Collapse, Grid, IconButton, InputAdornment, Typography } from '@mui/material'
import { FilterAltTwoTone, Search } from '@mui/icons-material'
import colors from 'app/styles/colors';
import { InputField, OutlineInputField } from '../UI/TextField';
import SelectField from '../UI/SelectFields';
import { PrimaryButton } from '../Buttons/Button';


function TripFilter({ bookingId, sortByBooking }) {

  const [filterCollapse, setFilterCollapse] = useState(false);
  const [selectedId, setSelectedId] = useState("");

  const filterData = () => {
    sortByBooking(selectedId)
  }

  return (
    <Box sx={{ bgcolor: colors.white, p: 2, borderRadius: "12px", mt: 2 }}>
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <Typography variant="body1" fontWeight="bold" color="initial">Filters</Typography>
        <IconButton onClick={() => setFilterCollapse(!filterCollapse)} color="primary" sx={{ bgcolor: colors.primaryLight }}>
          <FilterAltTwoTone />
        </IconButton>
      </Box>

      <Collapse in={filterCollapse} >
        <Grid container spacing={2} justifyContent="space-between" >
          <Grid item xs={4} ml={10}>
            <SelectField
              setState={(item) => { setSelectedId(item) }}
              stateValue={selectedId}
              data={bookingId}
              label={"Booking Id"}
            />
          </Grid>
          <Grid item xs={3}>
            <Button
              onClick={filterData}
              variant="contained"
              sx={{
                textTransform: 'capitalize',
                minWidth: '100px',
                boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
              }}
            >Search</Button>
          </Grid>
        </Grid>
      </Collapse>
    </Box>
  )
}

export default TripFilter