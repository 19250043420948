import React, { useState } from 'react'
import { Box, Collapse, Grid, IconButton, InputAdornment, Typography } from '@mui/material'
import { FilterAltTwoTone, Search } from '@mui/icons-material'
import colors from 'app/styles/colors';
import { InputField, OutlineInputField } from '../UI/TextField';
import SelectField from '../UI/SelectFields';
import { PrimaryButton } from '../Buttons/Button';


function EmployeeFilter() {

  const [filterCollapse, setFilterCollapse] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("");

  return (
    <Box sx={{ bgcolor: colors.white, p: 2, borderRadius: "12px", mt: 2 }}>
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <Typography variant="body1" fontWeight="bold" color="initial">Filters</Typography>
        <IconButton onClick={() => setFilterCollapse(!filterCollapse)} color="primary" sx={{ bgcolor: colors.primaryLight }}>
          <FilterAltTwoTone />
        </IconButton>
      </Box>

      <Collapse in={filterCollapse} >
        <Grid container spacing={2} alignItems="center" justifyContent={"flex-end"}>
          <Grid item xs={6}>
            <OutlineInputField
              label="Search"
              endAdornment={
                <InputAdornment position="end" sx={{ color: colors.primary }}>
                  <Search />
                </InputAdornment>
              }
            />
          </Grid>
          <Grid item xs={6}>
            <InputField label="Company Name" />
          </Grid>
          <Grid item xs={4}>
            <InputField label="Employee Name" />
          </Grid>
          <Grid item xs={4}>
            <InputField label="Email" />
          </Grid>
          <Grid item xs={4}>
            <SelectField
              data={["Active", "De-active"]}
              label="Status"
              stateValue={selectedStatus}
              setState={(e) => setSelectedStatus(e)}
            />
          </Grid>
          <Grid item xs={3}>
            {/* <PrimaryButton fullWidth={true}>Apply Filter</PrimaryButton> */}
            <PrimaryButton
              data-testId="save-btn"
              title={"Apply Filter"}
              type="submit"
            // loading={loading}
            />
          </Grid>
        </Grid>
      </Collapse>
    </Box>
  )
}

export default EmployeeFilter