import React, { Fragment, useState } from 'react'
import { Box, Grid, IconButton, Tooltip, Typography } from '@mui/material'
import { DeleteTwoTone, EditTwoTone } from '@mui/icons-material'
import { BsPlusLg } from 'react-icons/bs'
import VehiclesServices from 'app/apis/Vehicles/VehiclesServices'
import colors from 'app/styles/colors'
import { ErrorToaster, SuccessToaster } from 'app/components/Toaster/Toaster'
import { Loading } from 'app/assets/Images'
import SimpleDialog from 'app/components/Dialog/ValidationDialog'
import VehicleMakeDialog from 'app/components/Dialog/VehicleDialogs/VehicleMakeDialog'

function VehicleMakes({ getVehicleMakes, vehicleMakes, vehicleTypes }) {

  // *For Alert Dialog
  const [alertDialog, setAlertDialog] = useState(false);

  // *For Delete
  const [deleteId, setDeleteId] = useState();

  // *For Dialog Box
  const [openDialog, setOpenDialog] = useState(false);

  // *For Update Data
  const [updateData, setUpdateData] = useState();

  // *For Loading
  const [loading, setLoading] = useState(false);

  // *For Add Vehicle Type
  const createVehicleMake = async (obj) => {
    setLoading(true)
    try {
      const { status, responseCode, message } = await VehiclesServices.createVehicleMake(obj)
      if (status === true && responseCode === 200) {
        getVehicleMakes()
        SuccessToaster(message)
        onClose()
      }
    } catch (error) {
      ErrorToaster(error)
    } finally {
      setLoading(false)
    }
  }

  // *For Update Vehicle Make
  const updateVehicleMake = async (obj) => {
    setLoading(true)
    try {
      const { status, responseCode, message } = await VehiclesServices.updateVehicleMake(obj)
      if (status === true && responseCode === 200) {
        getVehicleMakes()
        SuccessToaster(message)
        onClose()
      }
    } catch (error) {
      ErrorToaster(error)
    } finally {
      setLoading(false)
    }
  }

  // *For Delete Makes
  const deleteVehicleMakes = async () => {
    try {
      const { status, responseCode, message } = await VehiclesServices.deleteVehicleMake(deleteId)
      if (status === true && responseCode === 200) {
        getVehicleMakes()
        SuccessToaster(message)
        setAlertDialog(false)
      }
    } catch (error) {
      ErrorToaster(error)
    }
  }

  const onClose = () => {
    setUpdateData('')
    setOpenDialog(false)
  }

  return (
    <Fragment>

      {/* ======= Alert Dialog =====  */}
      <SimpleDialog open={alertDialog} onClose={() => setAlertDialog(false)} title={"Are you sure to remove this?"} action={() => deleteVehicleMakes()} />

      {/* ========== Vehicle Make Dialog ========== */}
      <VehicleMakeDialog
        open={openDialog}
        close={() => onClose()}
        add={(data) => createVehicleMake(data)}
        update={(data) => updateVehicleMake(data)}
        loading={loading}
        updateData={updateData}
        vehicleTypes={vehicleTypes}
      />

      {vehicleMakes.length > 0 ? (
        <Grid container spacing={2} >
          {vehicleMakes.map((element, index) => (
            <Grid item key={index} xs={3}>
              <Box sx={{
                display: "flex", alignItems: "center", justifyContent: "space-between",
                bgcolor: colors.primaryLight, p: 2, borderRadius: "12px",
                overflow: "hidden", position: "relative",
                boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px"
              }}>
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                  <Typography variant="body1" fontWeight="bold">
                    {element.name}
                  </Typography>
                </Box>
                <Box>
                  <Tooltip title="Edit" >
                    <IconButton onClick={() => { setUpdateData(element); setOpenDialog(true) }}>
                      <EditTwoTone sx={{
                        fontSize: "20px",
                        color: colors.black
                      }} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete" >
                    <IconButton onClick={() => { setDeleteId(element.id); setAlertDialog(true) }} >
                      <DeleteTwoTone sx={{
                        fontSize: "20px",
                        color: colors.black
                      }} />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
            </Grid>
          ))}
          <Grid item xs={1.5}>
            <Box
              onClick={() => setOpenDialog(true)}
              sx={{
                bgcolor: colors.primaryLight, p: 2, borderRadius: "12px", height: 0.5,
                display: "flex", alignItems: "center", justifyContent: "center",
                cursor: "pointer"
              }}>
              <BsPlusLg color={colors.primaryDark} size={25} />
            </Box>
          </Grid>
        </Grid>
      ) : (
        <Box sx={{ width: 1, textAlign: "center", pt: 5 }}>
          <Loading />
        </Box>
      )}

    </Fragment>
  )
}

export default VehicleMakes