import axios from 'axios';

const server = 'https://backend.takseem.io/takseem/api/v1/';

const instance = axios.create({
  baseURL: server,
});

instance.interceptors.request.use((request) => {

  const token = localStorage.getItem('jwt')

  request.headers = {
    'Accept': "application/json, text/plain, */*",
    'Authorization': `Bearer ${token}`,
  }
  return request
});

instance.interceptors.response.use(
  (response) => response,
  (error) =>
    Promise.reject(
      error
    )
)

export default instance;