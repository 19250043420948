import Axios from '../../axios';
import { ErrorHandler } from 'app/utils/ErrorHandler';

export const get = async (endPoint, param) => {
    try {
        const result = await Axios.get(endPoint, { params: param });
        return result.data;
    } catch (e) {
        throw ErrorHandler(e);
    }
};

export const post = async (endPoint, data) => {
    try {
        const result = await Axios.post(endPoint, data);
        return result.data;
    } catch (e) {
        throw ErrorHandler(e);
    }
};

export const patch = async (endPoint, data) => {
    try {
        const result = await Axios.patch(endPoint, data);
        return result.data;
    } catch (e) {
        throw ErrorHandler(e);
    }
};

export const deleted = async (endPoint, param) => {
    try {
        const result = await Axios.delete(endPoint, { params: param });
        return result.data;
    } catch (e) {
        throw ErrorHandler(e);
    }
};