import React, { Fragment, useEffect, useState } from 'react'
import { Avatar, Box, Chip, Grid, IconButton, Paper, Tab, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, Tabs, Tooltip, tooltipClasses, Typography } from '@mui/material'
import { TabContext, TabPanel } from '@mui/lab'
import { FilterAltTwoTone, BuildCircleTwoTone, ColorLensTwoTone, DeleteTwoTone, DirectionsCarFilledTwoTone, EditTwoTone, KeyboardArrowLeft, KeyboardArrowRight, PersonOutlineTwoTone, TwoWheelerTwoTone, VisibilityTwoTone } from '@mui/icons-material'
import { BsPlusLg } from 'react-icons/bs'
import VehiclesServices from 'app/apis/Vehicles/VehiclesServices'
import Layout from 'app/layout/Layout'
import AddVehicleTypeDialog from 'app/components/Dialog/VehicleDialogs/VehicleTypeDialog'
import colors from 'app/styles/colors'
import { ErrorToaster, SuccessToaster } from 'app/components/Toaster/Toaster'
import AddVehicleColorDialog from 'app/components/Dialog/VehicleDialogs/VehicleColorDialog'
import AddVehicleMakeDialog from 'app/components/Dialog/VehicleDialogs/VehicleMakeDialog'
import AddVehicleModelDialog from 'app/components/Dialog/VehicleDialogs/VehicleModelDialog'
import BreadCrumbs from 'app/components/Breadcrumbs/Breadcrumbs'
import { Loading } from 'app/assets/Images'
import { useTheme } from '@emotion/react'
import { styled } from '@mui/styles'
import SimpleDialog from 'app/components/Dialog/ValidationDialog'
import { useNavigate, useParams } from 'react-router-dom'
import { StatusChip } from 'app/components/UI/Chip'
import { ImageAvatar } from 'app/components/Avatar/ImageAvatar'
import { minuteIntoHours } from 'app/utils'
import PassengerDialog from 'app/components/Dialog/PassengerDialog'
import DailyTripsServices from 'app/apis/DailyTrips/DailyTripsServices'
import TripFilter from 'app/components/Filters/TripFilter'
import BookingsServices from 'app/apis/Bookings/BookingsServices'

// Table Pagination ActionsComponent
function TablePaginationActions(props) {

  const theme = useTheme

  const { count, page, rowsPerPage, onPageChange } = props;

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  useEffect(() => {
    window.scrollTo({ top: 0 })
  }, [])


  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
    </Box>
  );
}

// custom tooltip
const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 200,
    textAlign: "center",
    background: "#0D253C",
    "&>.MuiTooltip-arrow": {
      "&:before": {
        color: "#0D253C"
      }
    }
  },
});


function DailyTrips() {

  const navigate = useNavigate()
  const bookingId = useParams()

  const tableHead = ['Trip Id', 'Address', 'Driver Id', 'Driver', 'No of Passengers', 'Status', 'View Details']

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  //* For Daily Trip Data
  const [dailyTrips, setDailyTrips] = useState([]);

  // *For Loader
  const [loader, setLoader] = useState(true);

  // *For Alert Dialog
  const [alertDialog, setAlertDialog] = useState(false);
  const [passengerDialog, setPassengerDialog] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  // *For Selected Passengers
  const [passengerData, setPassengerData] = useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // *For Get All Trips
  const getAllTrips = async (id) => {
    setLoader(true)
    try {
      const { status, responseCode, data } = await DailyTripsServices.getAllTrips(id ? id : Object.keys(bookingId).length > 0 ? bookingId.id : "")
      if (status === true && responseCode === 200) {
        setDailyTrips(data.rides)
      }
    } catch (error) {
      ErrorToaster(error)
    } finally {
      setLoader(false)
    }
  }
  // *For Get Booking
  const sortByBooking = (id) => {
    getAllTrips(id)
  }
  const [bookings, setBookings] = useState([])
  const getBookings = async () => {
    setLoader(true)
    try {
      const { status, responseCode, data } = await BookingsServices.getBookings()
      if (status === true && responseCode === 200) {
        setBookings(data)
      }
    } catch (error) {
      ErrorToaster(error)
    } finally {
      setLoader(false)
    }
  }

  useEffect(() => {
    getBookings()
    getAllTrips()
  }, [])

  return (
    <Fragment>

      {/* ======= Alert Dialog =====  */}
      <SimpleDialog
        title={alertMessage}
        onClose={() => setAlertDialog(false)}
        open={alertDialog}
      />

      {/* ======= Passenger Detail Dialog =====  */}
      <PassengerDialog
        data={passengerData}
        onClose={() => setPassengerDialog(false)}
        open={passengerDialog}
      />

      <Box sx={{
        backgroundColor: colors.lightGray, p: 3, borderRadius: "12px", width: "calc(100vw - 370px)"
      }}>
        <Grid container
          alignItems="center"
          justifyContent="space-between"
          sx={{
            bgcolor: colors.white, p: 2, borderRadius: "12px",
          }}>
          <Grid item xs={6}>
            <Typography variant="body1" fontWeight="bold" color="initial">Daily Trips</Typography>
          </Grid>
          <Grid item xs={6} sx={{ display: "flex", justifyContent: "flex-end" }}>
            <BreadCrumbs currentPage={['Daily Trips']} />
          </Grid>
        </Grid>
        {loader ? (
          <Box sx={{ width: 1, textAlign: "center", pt: 5 }}>
            <Loading />
          </Box>
        ) : (
          <Fragment>
            <Box >
              <TripFilter sortByBooking={(id) => sortByBooking(id)} bookingId={bookings?.map((item) => { return item.id })} />
            </Box>

            <TableContainer component={Paper}
              sx={{
                '&::-webkit-scrollbar': {
                  bgcolor: colors.white,
                  height: "8px",
                  borderRadius: "10px",
                  cursor: "pointer"
                },
                '&::-webkit-scrollbar-thumb': {
                  bgcolor: colors.primary,
                  borderRadius: "10px",
                  cursor: "pointer"
                },
                "&.MuiPaper-root": {
                  borderRadius: "12px",
                  mt: 2,
                  boxShadow: 0,
                }
              }}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead >
                  <TableRow >
                    {tableHead.map((item, index) => (
                      <TableCell key={index} sx={{ fontWeight: "bold", whiteSpace: 'nowrap' }}>{item}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                {dailyTrips?.length ?
                  <TableBody>
                    {(rowsPerPage > 0 ? dailyTrips?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : dailyTrips).map((row, index) => (
                      <TableRow key={index}>
                        <TableCell sx={{ textAlign: 'center' }}>
                          {row.id}
                        </TableCell>
                        <TableCell sx={{ width: '250px' }}>
                          {row?.address ?? '--'}
                        </TableCell>
                        {/* <TableCell >
                        {row?.vehicle?.transmission ?? '--'}
                      </TableCell> */}
                        <TableCell>
                          {row?.vehicle?.user?.id ?? '--'}
                        </TableCell>
                        <TableCell>
                          <Box display='flex' alignItems='center'>
                            <Avatar src={row?.vehicle?.user?.picture} alt={"driver"}
                              sx={{ width: 32, height: 32, mr: 1 }} />
                            {row?.vehicle?.user?.name ?? '--'}
                          </Box>
                        </TableCell>
                        <TableCell sx={{ textAlign: 'center' }}>
                          {row.passengers?.length}
                        </TableCell>
                        <TableCell>
                          <Chip label={row?.logs[row?.logs.length - 1]?.status?.status}
                            sx={{ color: row?.logs[row?.logs.length - 1]?.status?.color }} />
                        </TableCell>
                        <TableCell>
                          <Box sx={{ display: "flex" }}>
                            <Tooltip title="Details" >
                              <IconButton onClick={() => navigate(`/trip-details/${row.id}`)} >
                                <VisibilityTwoTone color="secondary" sx={{ fontSize: "20px" }} />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}

                  </TableBody>
                  : <Box sx={{ p: 1 }}>
                    <Typography>No Detail Found</Typography>
                  </Box>}
              </Table>
            </TableContainer>
            <Table sx={{ marginTop: "12px" }}>
              <TablePagination
                sx={{ borderBottom: "none", bgcolor: colors.white, borderRadius: "12px" }}
                rowsPerPageOptions={[10, 25, 50]}
                colSpan={15}
                count={dailyTrips.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    'aria-label': 'rows per page',
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </Table>
          </Fragment>
        )}

      </Box>
    </Fragment>
  )
}

export default DailyTrips