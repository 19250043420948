import AccountSettings from "app/views/AccountSettings/AccountSettings";
import CompanyDetails from "app/views/Companies/CompanyDetail";
import EmissionReports from "app/views/EmissionReports/EmissionReports";

const CompanyRoutes = [
  {
    path: "/account-settings",
    component: <AccountSettings />,
  },
  {
    path: "/company-details",
    component: <CompanyDetails />,
  },
  {
    path: "/co2-emissions-report",
    component: <EmissionReports />,
  },

];

export default CompanyRoutes