import React, { useEffect, useState } from 'react'
import { Box, Dialog, DialogContent, DialogTitle, Divider, FormControl, Grid, IconButton, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, Typography } from '@mui/material';
import { CloseRounded, KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import colors from 'app/styles/colors';
import { ImageAvatar } from '../Avatar/ImageAvatar';


// Table Pagination ActionsComponent
function TablePaginationActions(props) {

  const { count, page, rowsPerPage, onPageChange } = props;

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };



  useEffect(() => {
    window.scrollTo({ top: 0 })
  }, [])


  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
    </Box>
  );
}

function PassengerDialog({ onClose, open, data }) {

  const tableHead = ['#ID', 'Image', 'Name', 'Phone', 'Email', 'Gender', 'Company Name']

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Dialog
      open={open}
      fullWidth
      onClose={() => onClose()}
      sx={{ '& .MuiDialog-paper': { borderRadius: "12px" } }}
    >
      <DialogTitle sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        View Passenger
        <IconButton onClick={() => onClose()}>
          <CloseRounded />
        </IconButton>
      </DialogTitle>
      <Divider sx={{ my: 1 }} />
      <DialogContent sx={{ pt: 0 }}>

        <TableContainer component={Paper}
          sx={{
            '&::-webkit-scrollbar': {
              bgcolor: colors.white,
              height: "8px",
              borderRadius: "10px",
              cursor: "pointer"
            },
            '&::-webkit-scrollbar-thumb': {
              bgcolor: colors.primary,
              borderRadius: "10px",
              cursor: "pointer"
            },
            "&.MuiPaper-root": {
              borderRadius: "12px",
              boxShadow: 0,
            }
          }}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead >
              <TableRow >
                {tableHead.map((item, index) => (
                  <TableCell key={index} sx={{ fontWeight: "bold" }} >{item}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0 ? data?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : data).map((row, index) => (
                <TableRow key={index}>
                  <TableCell component="th" scope="row">
                    {row.passenger_id}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <ImageAvatar image={row.user?.picture} />
                  </TableCell>
                  <TableCell >
                    {row.user?.name}
                  </TableCell>
                  <TableCell >
                    {row.user?.phone}
                  </TableCell>
                  <TableCell >
                    {row.user?.email}
                  </TableCell>
                  <TableCell >
                    {row.user?.gender}
                  </TableCell>
                  <TableCell >
                    {row.user?.companyName}
                  </TableCell>
                </TableRow>
              ))}

            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 50]}
                  colSpan={15}
                  count={data.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      'aria-label': 'rows per page',
                    },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>

      </DialogContent>
    </Dialog>
  );
}

export default PassengerDialog


