import React, { Fragment, useEffect, useState } from 'react'
import { Box, Chip, Grid, IconButton, Paper, Tab, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, Tabs, Tooltip, tooltipClasses, Typography } from '@mui/material'
import { TabContext, TabPanel } from '@mui/lab'
import { AddBusinessTwoTone, BuildCircleTwoTone, ColorLensTwoTone, DeleteTwoTone, DirectionsCarFilledTwoTone, EditTwoTone, KeyboardArrowLeft, KeyboardArrowRight, PersonOutlineTwoTone, TwoWheelerTwoTone, VisibilityTwoTone } from '@mui/icons-material'
import { BsPlusLg } from 'react-icons/bs'
import VehiclesServices from 'app/apis/Vehicles/VehiclesServices'
import Layout from 'app/layout/Layout'
import AddVehicleTypeDialog from 'app/components/Dialog/VehicleDialogs/VehicleTypeDialog'
import colors from 'app/styles/colors'
import { ErrorToaster, SuccessToaster } from 'app/components/Toaster/Toaster'
import AddVehicleColorDialog from 'app/components/Dialog/VehicleDialogs/VehicleColorDialog'
import AddVehicleMakeDialog from 'app/components/Dialog/VehicleDialogs/VehicleMakeDialog'
import AddVehicleModelDialog from 'app/components/Dialog/VehicleDialogs/VehicleModelDialog'
import BreadCrumbs from 'app/components/Breadcrumbs/Breadcrumbs'
import { Loading } from 'app/assets/Images'
import { useTheme } from '@emotion/react'
import { styled } from '@mui/styles'
import SimpleDialog from 'app/components/Dialog/ValidationDialog'
import { useNavigate } from 'react-router-dom'
import PassengersServices from 'app/apis/Passengers/PassengersServices'

// Table Pagination ActionsComponent
function TablePaginationActions(props) {

  const theme = useTheme

  const { count, page, rowsPerPage, onPageChange } = props;

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };



  useEffect(() => {
    window.scrollTo({ top: 0 })
  }, [])


  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
    </Box>
  );
}

// custom tooltip
const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 200,
    textAlign: "center",
    background: "#0D253C",
    "&>.MuiTooltip-arrow": {
      "&:before": {
        color: "#0D253C"
      }
    }
  },
});


function Passengers() {

  const navigate = useNavigate()

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [companies, setCompanies] = useState([]);

  // *For Loader
  const [loader, setLoader] = useState(true);

  // *For Alert Dialog
  const [alertDialog, setAlertDialog] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [selectedItem, setSelectedItem] = useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // *For Get Companies
  const getCompanies = async () => {
    setLoader(true)
    try {
      const { status, responseCode, data } = await PassengersServices.getPassengers()
      if (status === true && responseCode === 200) {
        setCompanies(data)
      }
    } catch (error) {
      ErrorToaster(error)
    } finally {
      setLoader(false)
    }
  }


  useEffect(() => {
    getCompanies()
  }, [])

  return (
    <Fragment>

      {/* ======= Alert Dialog =====  */}
      <SimpleDialog
        title={alertMessage}
        onClose={() => setAlertDialog(false)}
        open={alertDialog}
      />

      <Box sx={{
        backgroundColor: colors.lightGray, p: 3, borderRadius: "12px", width: "calc(100vw - 370px)"
      }}>
        <Grid container
          alignItems="center"
          justifyContent="space-between"
          sx={{
            bgcolor: colors.white, p: 2, borderRadius: "12px",
          }}>
          <Grid item xs={6}>
            <Typography variant="body1" fontWeight="bold" color="initial">Passengers</Typography>
          </Grid>
          <Grid item xs={6} sx={{ display: "flex", justifyContent: "flex-end" }}>
            <BreadCrumbs currentPage={['Companies']} />
          </Grid>
        </Grid>
        {loader ? (
          <Box sx={{ width: 1, textAlign: "center", pt: 5 }}>
            <Loading />
          </Box>
        ) : (
          <Fragment>

            <TableContainer component={Paper}
              sx={{
                '&::-webkit-scrollbar': {
                  bgcolor: colors.white,
                  height: "8px",
                  borderRadius: "10px",
                  cursor: "pointer"
                },
                '&::-webkit-scrollbar-thumb': {
                  bgcolor: colors.primary,
                  borderRadius: "10px",
                  cursor: "pointer"
                },
                "&.MuiPaper-root": {
                  borderRadius: "12px",
                  mt: 2,
                  boxShadow: 0,
                }
              }}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead >
                  <TableRow >
                    <TableCell sx={{ fontWeight: "bold" }} >#ID</TableCell>
                    <TableCell sx={{ fontWeight: "bold" }} >Name</TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>Phone No.</TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>Email</TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>Gender</TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>Booking</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(rowsPerPage > 0 ? companies?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : companies).map((row, index) => (
                    <TableRow
                      key={index}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                      >
                        {row.id}
                      </TableCell>
                      <TableCell >
                        <Typography variant="body2" color="initial" noWrap>{row.name}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2" color="initial" noWrap>{row.phone ?? "-"}</Typography>
                      </TableCell>
                      <TableCell>
                        <CustomTooltip title={row.email} arrow>
                          <Typography variant="body2" color="initial" noWrap
                            sx={{ cursor: "default", maxWidth: 170 }}>{row.email}</Typography>
                        </CustomTooltip>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2" color="initial" noWrap>{row.gender ?? "0"}</Typography>
                      </TableCell>
                      <TableCell>
                        {row?.bookingDetail.length > 0 ?
                          <Chip
                            label={`View`}
                            sx={{
                              bgcolor: colors.bondiBlueLight + 26,
                              color: colors.bondiBlueLight,
                              height: 26,
                              textTransform: 'capitalize',
                              cursor: 'pointer'
                            }}
                            onClick={() => navigate(`/booking-detail/${row?.bookingDetail[0]?.id}`)}
                          /> :
                          <Typography variant="body2" color="initial" noWrap>--</Typography>
                        }
                      </TableCell>
                    </TableRow>
                  ))}

                </TableBody>
              </Table>
            </TableContainer>
            <Table sx={{ marginTop: "12px" }}>
              <TablePagination
                sx={{ borderBottom: "none", bgcolor: colors.white, borderRadius: "12px" }}
                rowsPerPageOptions={[10, 25, 50]}
                colSpan={15}
                count={companies.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    'aria-label': 'rows per page',
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </Table>
          </Fragment>
        )}

      </Box>
    </Fragment>
  )
}

export default Passengers