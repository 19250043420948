import React, { Fragment, useEffect, useState } from "react";
import { Alert, AlertTitle, Box, Divider, FormControl, FormControlLabel, Grid, IconButton, InputAdornment, Radio, RadioGroup, Tooltip, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { FaMapMarkerAlt } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import { AddLocationAltTwoTone, DeleteTwoTone, EditTwoTone, GroupAddTwoTone, LoopTwoTone } from "@mui/icons-material";
import { ErrorToaster, SuccessToaster } from "app/components/Toaster/Toaster";
import colors from "app/styles/colors";
import MapModal from "app/components/Dialog/MapDialogBox";
import Layout from "app/layout/Layout";
import { emailRegex } from "app/utils";
import { InputField, OutlineInputField, PhoneInputField } from "app/components/UI/TextField";
import SelectField from "app/components/UI/SelectFields";
import { empStrengthRange, industryTypes } from "app/utils/CustomData";
import BreadCrumbs from "app/components/Breadcrumbs/Breadcrumbs";
import { PrimaryButton, SecondaryButton } from "app/components/Buttons/Button";
import { Error } from "app/components/UI/Error";
import CompaniesServices from "app/apis/Companies/CompaniesServices";
import AddressesServices from "app/apis/Addresses/AddressesServices";
import { LoadingCircle } from "../UI/Loader";

function CompanyForm(props) {
  const { onSubmit, loading } = props;

  const navigate = useNavigate();
  const { id } = useParams();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const [telephoneNumber, setTelephoneNumber] = useState("");
  const [empStrength, setEmpStrength] = useState("");
  const [updatedCity, setUpdatedCity] = useState({});
  const [updatedRole, setUpdatedRole] = useState("");
  const [currentLocation, setCurrentLocation] = useState({ latitude: 0, longitude: 0 });
  const [latLng, setLatLng] = useState({ lat: 0, lng: 0 });
  const [updatedAddress, setUpdatedAddress] = useState("");
  const [updatedIndustryType, setUpdatedIndustryType] = useState("");

  // *For Loading
  const [loader, setLoader] = useState(false);

  // *For Company Roles
  const [companyRoles, setCompanyRoles] = useState([]);

  // *For Cities
  const [cities, setCities] = useState([]);

  // *For Company Code
  const [companyCode, setCompanyCode] = useState();

  // *For Dialog Box
  const [openDialog, setOpenDialog] = useState(false);

  // *For Enable on Changes
  const [buttonEnable, setButtonEnable] = useState(false);

  // *For Company Status
  const [isVerified, setIsVerified] = useState(false);

  // *For Add Addresses
  const [contactPersonFields, setContactPersonFields] = useState([]);
  const [contactPersonDetails, setContactPersonDetails] = useState({
    name: "",
    phone: "",
    email: "",
    department: "",
  });
  const [addAddressFields, setAddAddressFields] = useState([]);
  const [addressAndLocationName, setAddressAndLocationName] = useState({
    locationName: "",
    address: "",
  });

  // *For Disabled Form Fields
  const [disableFields, setDisableFields] = useState(false);

  // *For Get Company Roles
  const getCompanyRoles = async () => {
    try {
      const { status, responseCode, data } = await CompaniesServices.getCompanyRoles();
      if (status === true && responseCode === 200) {
        setCompanyRoles(data);
      }
    } catch (error) {
      // ErrorToaster(error)
    }
  };

  // *For Get Cities
  const getCities = async () => {
    try {
      const { status, responseCode, data } = await AddressesServices.getCities();
      if (status === true && responseCode === 200) {
        setCities(data.rows);
      }
    } catch (error) {
      // ErrorToaster(error)
    }
  };

  // *For Get Company Detail
  const getCompanyDetail = async (id) => {
    setLoader(true);
    try {
      const { company } = await CompaniesServices.getCompanyDetail(id);
      if (company.status === true && company.responseCode === 200) {
        fillData(company.data);
        setDisableFields(true);
      }
    } catch (error) {
      ErrorToaster(error);
    } finally {
      setLoader(false);
    }
  };

  // *For Set Update Data
  const fillData = async (data) => {
    try {
      setValue("name", data.name);
      setValue("email", data.email);
      setValue("telephone", data.owner_contact);
      setTelephoneNumber(data.owner_contact);
      setValue("emp_strength", data.employee_strength);
      setEmpStrength(data.employee_strength);
      setUpdatedIndustryType(data.type_industry);
      setValue("typeOfIndustry", data.type_industry);
      setValue("ntn", data.ntn);
      setValue("website", data.website);
      setCompanyCode(data.company_codes.code);
      setAddAddressFields(data.addresses);
      setContactPersonFields(data.contactPersons);
      setIsVerified(data.is_verified);
    } catch (error) {
      ErrorToaster(error);
    }
  };

  // *For Submit Form
  const submitForm = async (formData) => {
    try {
      let sortedAddresses = [];

      for (let index = 0; index < addAddressFields.length; index++) {
        let addressSort = {
          city_id: addAddressFields[index].city?.id,
          formatted_address: addAddressFields[index].formatted_address,
          google_address: addAddressFields[index].google_address,
          latitude: parseInt(addAddressFields[index].latitude).toFixed(6),
          longitude: parseInt(addAddressFields[index].longitude).toFixed(6),
          name: addAddressFields[index].name,
        };
        sortedAddresses.push(addressSort);
      }
      let sortedPersons = [];
      for (let i = 0; i < contactPersonFields.length; i++) {
        let personSort = {
          phone: contactPersonFields[i].phone,
          name: contactPersonFields[i].name,
          email: contactPersonFields[i].email,
          designation: contactPersonFields[i]?.designation?.name,
          department: contactPersonFields[i].department,
          company_role_id: 1,
        };
        sortedPersons.push(personSort);
      }
      let obj = {
        company: {
          name: formData.name,
          email: formData.email,
          type_industry: formData.typeOfIndustry,
          employee_strength: empStrength,
          telephone: formData.telephone,
          ntn: formData.ntn,
          website: formData.website,
        },
        contact_persons: sortedPersons,
        addresses: sortedAddresses,
      };

      if (id) {
        obj.company.id = id;
      } else {
        obj.user = {
          name: formData.OBPersonName,
          email: formData.OBPersonEmail,
          phone: formData.OBPersonTelephone,
          designation: formData.ObPersonDesignation,
          gender: formData.gender,
        };
      }

      if (contactPersonFields.length === 0 && addAddressFields.length === 0) {
        ErrorToaster("Please enter atleast 1 address and contact person");
      } else if (contactPersonFields.length === 0) {
        ErrorToaster("Please enter atleast 1 contact person");
      } else if (addAddressFields.length === 0) {
        ErrorToaster("Please enter atleast 1 address");
      } else {
        onSubmit(obj);
      }
    } catch (error) {
      ErrorToaster(error);
    }
  };

  // *For Add Address Details
  const handleAddressFields = (id) => {
    if (addressAndLocationName.locationName && addressAndLocationName.address && updatedAddress && updatedCity) {
      let obj = {
        id: id,
        name: addressAndLocationName.locationName,
        formatted_address: addressAndLocationName.address,
        google_address: updatedAddress,
        city: updatedCity,
        latitude: latLng.lat,
        longitude: latLng.lng,
      };
      let fields = [...addAddressFields];
      fields.push(obj);
      setAddAddressFields(fields);
      setAddressAndLocationName({
        locationName: "",
        address: "",
      });
      setValue("city", "");
      setValue("pinLocation", "");
      setValue("locationName", "");
      setValue("address", "");
      setUpdatedCity("");
      setUpdatedAddress("");
    } else {
      ErrorToaster("All address fields must be filled to enter new address");
    }
  };

  const deleteAddressFields = (id) => {
    let filterAddress = addAddressFields.filter((e) => e.id !== id);
    setAddAddressFields(filterAddress);
    setButtonEnable(true);
  };

  // *For Add Contact Person Details
  const handleContactPerson = (id) => {
    if (contactPersonDetails.name && contactPersonDetails.phone && contactPersonDetails.email && contactPersonDetails.department && updatedRole) {
      let obj = {
        id: id,
        name: contactPersonDetails.name,
        phone: contactPersonDetails.phone,
        email: contactPersonDetails.email,
        designation: updatedRole,
        department: contactPersonDetails.department,
        company_role_id: 1,
      };
      let fields = [...contactPersonFields];
      fields.push(obj);
      setContactPersonFields(fields);
      setContactPersonDetails({
        name: "",
        phone: "",
        email: "",
        department: "",
      });
      setUpdatedRole("");
      setValue("contactPersonName", "");
      setValue("contactPersonTelephone", "");
      setValue("contactPersonEmail", "");
      setValue("contactPersonRole", "");
      setValue("contactPersonDepartment", "");
    } else {
      ErrorToaster("All contact person fields must be filled to enter new");
    }
  };

  const deletePersonField = (id) => {
    let filterPerson = contactPersonFields.filter((e) => e.id !== id);
    setContactPersonFields(filterPerson);
    setButtonEnable(true);
  };

  // *For Get User Current Location
  const getCurrentPosition = () => {
    navigator.geolocation.getCurrentPosition((location) => {
      const latitude = location.coords.latitude;
      const longitude = location.coords.longitude;
      setCurrentLocation({ latitude: latitude, longitude: longitude });
      setLatLng({ lat: latitude, lng: longitude });
    });
  };

  // *For Get Map Address
  const handleAddress = (val) => {
    let findCityID = cities.find((e) => e.name.toLowerCase() == val.city.toLowerCase());
    if (findCityID) {
      setUpdatedAddress(val.address);
      setValue("pinLocation", val.address);
      setUpdatedCity({
        id: findCityID.id,
        name: val.city,
      });
      setValue("city", val.city);
      setLatLng({ lat: val.lat, lng: val.lng });
    }
  };

  useEffect(() => {
    getCities();
    getCompanyRoles();
    getCurrentPosition();
  }, []);

  useEffect(() => {
    if (id) {
      getCompanyDetail(id);
    }
  }, [id]);

  return (
    <Fragment>
      {/* ========== Map Dialog Box ========== */}
      <MapModal open={openDialog} onClose={() => setOpenDialog(false)} currentLocation={currentLocation} updatedLocation={latLng} handleAddress={handleAddress} />

      {/* {id &&
        <Alert severity={isVerified ? "success" : "warning"} sx={{ mt: 2 }}>
          <AlertTitle>Verification Status</AlertTitle>
          {isVerified ? (
            <Fragment>
              Your company is <strong>verified</strong>
            </Fragment>
          ) : (
            <Fragment>
              Your company is not <strong>verified</strong> yet.
            </Fragment>
          )}
        </Alert>
      } */}

      {loader ? (
        <LoadingCircle />
      ) : (
        <Typography component={"form"} onSubmit={handleSubmit(submitForm)}>
          <Box sx={{ bgcolor: colors.white, p: 3, borderRadius: "12px", mt: 2 }}>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "flex-end" }}>
              <Typography variant="body1" fontWeight="bold" color="initial">
                {disableFields && (
                  <Box
                    onClick={() => {
                      setDisableFields(false);
                    }}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      borderRadius: 1,
                      p: 1,
                      mb: 1,
                      cursor: "pointer",
                      width: "fit-content",
                      color: colors.primary,
                      bgcolor: colors.primaryLight,
                    }}
                  >
                    <EditTwoTone sx={{ color: colors.primary, mr: 1, fontSize: "20px" }} />
                    <Typography sx={{ fontSize: "14px" }}>Edit</Typography>
                  </Box>
                )}
                Company Details
              </Typography>
              {id && (
                <Box sx={{ textAlign: "center" }}>
                  <Typography variant="body2" sx={{ fontSize: "12px", color: colors.secondaryDark }}>
                    Company Code
                  </Typography>
                  <Typography variant="h4" color="primary" sx={{ fontWeight: "bold" }}>
                    {companyCode}
                  </Typography>
                </Box>
              )}
            </Box>
            <Divider sx={{ my: 2 }} />

            {/* Company details Section */}
            <Grid container spacing={2} alignItems="center">
              {/* Name */}
              <Grid item xs={12} md={6}>
                <InputField
                  disable={disableFields}
                  label="Company Name"
                  error={errors?.name?.message}
                  register={register("name", {
                    required: "Please enter your name.",
                    onChange: () => setButtonEnable(true),
                  })}
                />
              </Grid>
              {/* Email */}
              <Grid item xs={12} md={6}>
                <InputField
                  disable={Boolean(id)}
                  label="Email"
                  error={errors?.email?.message}
                  register={register("email", {
                    required: "Please enter your email.",
                    onChange: () => setButtonEnable(true),
                    pattern: {
                      value: emailRegex,
                      message: "Please enter a valid email address",
                    },
                  })}
                />
              </Grid>
              {/* Telephone */}
              <Grid item xs={12} md={4}>
                <PhoneInputField
                  disable={disableFields}
                  error={errors?.telephone?.message}
                  register={register("telephone", {
                    required: "Please enter your telephone number.",
                  })}
                  onChange={(e) => {
                    setValue("telephone", e);
                    setButtonEnable(true);
                  }}
                  value={telephoneNumber}
                />
              </Grid>
              {/* Employee Strength */}
              <Grid item xs={12} md={3}>
                <SelectField
                  data={empStrengthRange}
                  label="Employee Strength"
                  error={errors?.emp_strength?.message}
                  register={register("emp_strength", {
                    required: "Please select employee strength.",
                    onChange: () => setButtonEnable(true),
                  })}
                  disable={disableFields}
                  stateValue={empStrength}
                  setState={(e) => setEmpStrength(e)}
                />
              </Grid>
              {/* Type Of Industry */}
              <Grid item xs={12} md={5}>
                <SelectField
                  data={industryTypes}
                  label="Type Of Industry"
                  error={errors?.typeOfIndustry?.message}
                  register={register("typeOfIndustry", {
                    required: "Please select industry type.",
                    onChange: () => setButtonEnable(true),
                  })}
                  disable={disableFields}
                  stateValue={updatedIndustryType}
                  setState={(e) => setUpdatedIndustryType(e)}
                />
              </Grid>
              {/* NTN */}
              <Grid item xs={12} md={6}>
                <InputField
                  disable={disableFields}
                  label="NTN (optional)"
                  error={errors?.ntn?.message}
                  register={register("ntn", {
                    onChange: () => setButtonEnable(true),
                    // required: 'Please enter ntn number.',
                  })}
                />
              </Grid>
              {/* Website */}
              <Grid item xs={12} md={6}>
                <InputField
                  disable={disableFields}
                  label="Website (optional)"
                  error={errors?.website?.message}
                  register={register("website", {
                    onChange: () => setButtonEnable(true),
                    // required: 'Please enter your website url.',
                  })}
                />
              </Grid>
            </Grid>
          </Box>

          {/* Address Section */}
          <Box sx={{ bgcolor: colors.white, p: 3, borderRadius: "12px", mt: 2 }}>
            <Typography variant="body1" fontWeight="bold" color="initial">
              Company Address
            </Typography>
            <Divider sx={{ my: 2 }} />
            <Grid container spacing={2} alignItems="center">
              <Grid item container xs={11} spacing={2}>
                {/* Location Name */}
                <Grid item xs={12} md={6}>
                  <InputField
                    disable={disableFields}
                    label="Location Name"
                    register={register("locationName", {
                      onChange: (e) => {
                        setButtonEnable(true);
                        setAddressAndLocationName({ ...addressAndLocationName, locationName: e.target.value });
                      },
                    })}
                  />
                </Grid>
                {/* Address */}
                <Grid item xs={12} md={6}>
                  <InputField
                    disable={disableFields}
                    label="Address"
                    register={register("address", {
                      onChange: (e) => {
                        setButtonEnable(true);
                        setAddressAndLocationName({ ...addressAndLocationName, address: e.target.value });
                      },
                    })}
                  />
                </Grid>
                {/* Pin Location */}
                <Grid item xs={12} md={6}>
                  <OutlineInputField
                    disable={disableFields}
                    label="Pin Location"
                    updatedValue={updatedAddress}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          onClick={
                            !disableFields
                              ? (e) => {
                                  e.stopPropagation();
                                  setOpenDialog(true);
                                }
                              : undefined
                          }
                          edge="end"
                          sx={{ color: colors.darkMintGreen, fontSize: "18px" }}
                        >
                          <FaMapMarkerAlt />
                        </IconButton>
                      </InputAdornment>
                    }
                    register={register("pinLocation", {})}
                    onClick={
                      !disableFields
                        ? () => {
                            setOpenDialog(true);
                            setButtonEnable(true);
                          }
                        : undefined
                    }
                  />
                </Grid>
                {/* City */}
                <Grid item xs={12} md={6}>
                  <SelectField
                    data={cities}
                    label="City"
                    register={register("city", {
                      onChange: () => setButtonEnable(true),
                    })}
                    disable={disableFields}
                    stateValue={updatedCity?.name}
                    setState={(e) => setUpdatedCity(e)}
                  />
                </Grid>
              </Grid>
              {/* Add address icon*/}
              <Grid item xs={12} md={1}>
                <Tooltip title="Add Address">
                  <Box
                    onClick={!disableFields ? () => handleAddressFields(addAddressFields.length + 1) : undefined}
                    sx={{
                      bgcolor: disableFields ? colors.lightGray : colors.primaryLight,
                      p: 1,
                      borderRadius: "4px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                    }}
                  >
                    <AddLocationAltTwoTone sx={{ color: disableFields ? colors.textLight : colors.primaryDark }} size={35} />
                  </Box>
                </Tooltip>
              </Grid>

              {/* Additional Address fields */}
              {addAddressFields?.map((addr, index) => (
                <Fragment key={index}>
                  <Grid item container xs={11} spacing={2}>
                    <Grid item xs={12} md={6}>
                      <Typography variant="body1" fontWeight="bold" color="initial">
                        Location Name
                      </Typography>
                      <Typography variant="body2" color="initial">
                        {addr.name}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography variant="body1" fontWeight="bold" color="initial">
                        City
                      </Typography>
                      <Typography variant="body2" color="initial">
                        {addr.city?.name}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography variant="body1" fontWeight="bold" color="initial">
                        Address
                      </Typography>
                      <Typography variant="body2" color="initial">
                        {addr.formatted_address}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography variant="body1" fontWeight="bold" color="initial">
                        Pin Location
                      </Typography>
                      <Typography variant="body2" color="initial">
                        {addr.google_address}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={1}>
                    <Tooltip title="Remove address">
                      <Box
                        onClick={!disableFields ? () => deleteAddressFields(addr.id) : undefined}
                        sx={{
                          bgcolor: disableFields ? colors.lightGray : "#ff21211f",
                          p: 1,
                          borderRadius: "4px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          cursor: "pointer",
                        }}
                      >
                        <DeleteTwoTone sx={{ color: disableFields ? colors.textLight : colors.danger }} size={35} />
                      </Box>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                </Fragment>
              ))}
            </Grid>
          </Box>

          {/* =================== Contact Person =================== */}
          <Box sx={{ bgcolor: colors.white, p: 3, borderRadius: "12px", mt: 2 }}>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              <Typography variant="body1" fontWeight="bold" color="initial">
                Contact Person Details
              </Typography>
              {/* Add Cp icon*/}
              <Grid item xs={12} md={1}>
                <Tooltip title="Add Contact Person">
                  <Box
                    onClick={!disableFields ? () => handleContactPerson(contactPersonFields.length + 1) : undefined}
                    sx={{
                      bgcolor: disableFields ? colors.lightGray : colors.primaryLight,
                      p: 1,
                      borderRadius: "4px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                    }}
                  >
                    <GroupAddTwoTone sx={{ color: disableFields ? colors.textLight : colors.primaryDark }} size={35} />
                  </Box>
                </Tooltip>
              </Grid>
            </Box>
            <Divider sx={{ my: 2 }} />

            <Grid container columnSpacing={2}>
              {/* Name */}
              <Grid item xs={12} md={4}>
                <InputField
                  disable={disableFields}
                  label="Person Name"
                  register={register("contactPersonName", {
                    onChange: (e) => {
                      setButtonEnable(true);
                      setContactPersonDetails({ ...contactPersonDetails, name: e.target.value });
                    },
                  })}
                />
              </Grid>
              {/* Phone */}
              <Grid item xs={12} md={4}>
                <PhoneInputField
                  disable={disableFields}
                  error={errors?.contactPersonTelephone?.message}
                  register={register("contactPersonTelephone")}
                  onChange={(e) => {
                    setValue("contactPersonTelephone", e);
                    setContactPersonDetails({ ...contactPersonDetails, phone: e });
                    setButtonEnable(true);
                  }}
                  value={contactPersonDetails.phone}
                />
              </Grid>
              {/* Email */}
              <Grid item xs={12} md={4}>
                <InputField
                  disable={disableFields}
                  label="Person Email"
                  register={register("contactPersonEmail", {
                    onChange: (e) => {
                      setButtonEnable(true);
                      setContactPersonDetails({ ...contactPersonDetails, email: e.target.value });
                    },
                    pattern: {
                      value: emailRegex,
                      message: "Please enter a valid email address",
                    },
                  })}
                />
              </Grid>
              {/* Roles */}
              <Grid item xs={12} md={6}>
                <SelectField
                  data={companyRoles}
                  label="Person Role"
                  register={register("contactPersonRole", {
                    onChange: () => setButtonEnable(true),
                  })}
                  disable={disableFields}
                  stateValue={updatedRole?.name}
                  setState={(e) => setUpdatedRole(e)}
                />
              </Grid>
              {/* Department  */}
              <Grid item xs={12} md={6}>
                <InputField
                  disable={disableFields}
                  label="Person Department"
                  register={register("contactPersonDepartment", {
                    onChange: (e) => {
                      setButtonEnable(true);
                      setContactPersonDetails({ ...contactPersonDetails, department: e.target.value });
                    },
                    // onChange: () => setButtonEnable(true)
                  })}
                />
              </Grid>
            </Grid>

            <Grid container alignItems={"center"}>
              {contactPersonFields?.map((contact, index) => (
                <Fragment key={index}>
                  <Grid item container xs={11} spacing={2}>
                    <Grid item xs={12} md={4}>
                      <Typography variant="body1" fontWeight="bold" color="initial">
                        Name
                      </Typography>
                      <Typography variant="body2" color="initial">
                        {contact.name}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Typography variant="body1" fontWeight="bold" color="initial">
                        Phone
                      </Typography>
                      <Typography variant="body2" color="initial">
                        {contact.phone}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Typography variant="body1" fontWeight="bold" color="initial">
                        Email
                      </Typography>
                      <Typography variant="body2" color="initial">
                        {contact.email}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Typography variant="body1" fontWeight="bold" color="initial">
                        Role
                      </Typography>
                      <Typography variant="body2" color="initial">
                        {contact.designation?.name ?? contact.designation}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Typography variant="body1" fontWeight="bold" color="initial">
                        Department
                      </Typography>
                      <Typography variant="body2" color="initial">
                        {contact.department}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={1}>
                    <Tooltip title="Remove person">
                      <Box
                        onClick={!disableFields ? () => deletePersonField(contact.id) : undefined}
                        sx={{
                          bgcolor: disableFields ? colors.lightGray : "#ff21211f",
                          p: 1,
                          borderRadius: "4px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          cursor: "pointer",
                        }}
                      >
                        <DeleteTwoTone sx={{ color: disableFields ? colors.textLight : colors.danger }} size={35} />
                      </Box>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                </Fragment>
              ))}
            </Grid>
          </Box>

          {/* =================== On-boarding Person =================== */}

          {!id && (
            <Box sx={{ bgcolor: colors.white, p: 3, borderRadius: "12px", mt: 2 }}>
              <Typography variant="body1" fontWeight="bold" color="initial">
                On-Boarding Person Details
              </Typography>
              <Divider sx={{ my: 2 }} />

              <Grid container columnSpacing={2}>
                {/* Name */}
                <Grid item xs={12} md={6}>
                  <InputField
                    disable={disableFields}
                    label="Name"
                    error={errors?.OBPersonName?.message}
                    register={register("OBPersonName", {
                      required: "Name is required",
                      onChange: (e) => {
                        setButtonEnable(true);
                      },
                    })}
                  />
                </Grid>
                {/* Phone */}
                <Grid item xs={12} md={6}>
                  <PhoneInputField
                    disable={disableFields}
                    error={errors?.OBPersonTelephone?.message}
                    register={register("OBPersonTelephone", {
                      required: "Phone number is required",
                    })}
                    onChange={(e) => {
                      setValue("OBPersonTelephone", e);
                      setButtonEnable(true);
                    }}
                    // value={OBPersonPhone}
                  />
                </Grid>
                {/* Email */}
                <Grid item xs={12} md={6}>
                  <InputField
                    disable={disableFields}
                    label="Email"
                    error={errors?.OBPersonEmail?.message}
                    register={register("OBPersonEmail", {
                      required: "Email is required",
                      pattern: {
                        value: emailRegex,
                        message: "Please enter a valid email address",
                      },
                      onChange: (e) => {
                        setButtonEnable(true);
                      },
                    })}
                  />
                </Grid>
                {/* Designation  */}
                <Grid item xs={12} md={6}>
                  <InputField
                    disable={disableFields}
                    label="Designation"
                    error={errors?.ObPersonDesignation?.message}
                    register={register("ObPersonDesignation", {
                      required: "Designation is required.",
                      onChange: () => setButtonEnable(true),
                    })}
                  />
                </Grid>
                {/* Gender */}
                <Grid item xs={12} md={6}>
                  <FormControl>
                    <Typography variant="body1" sx={{ fontSize: "14px", display: "inline-flex", fontWeight: "bold" }}>
                      Gender
                    </Typography>
                    <RadioGroup row defaultValue={"male"}>
                      <FormControlLabel
                        value={"male"}
                        control={<Radio />}
                        label={"Male"}
                        sx={{ "& .MuiTypography-root": { fontSize: "14px" } }}
                        {...register("gender", {
                          required: "Select any gender",
                        })}
                      />
                      <FormControlLabel
                        value={"female"}
                        control={<Radio />}
                        label={"Female"}
                        sx={{ "& .MuiTypography-root": { fontSize: "14px" } }}
                        {...register("gender", {
                          required: "Select any gender",
                        })}
                      />
                    </RadioGroup>
                    {errors?.gender?.message && <Error message={errors?.gender?.message} />}
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
          )}

          <Box sx={{ mt: 2, display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <SecondaryButton data-testId="back-btn" title="Go Back" onClick={() => navigate(-1)} />
            {buttonEnable && <PrimaryButton data-testId="save-btn" title={id ? "Save changes" : "Add Company"} type="submit" loading={loading} />}
          </Box>
        </Typography>
      )}
    </Fragment>
  );
}

export default CompanyForm;
