import { Avatar } from "@mui/material";
import colors from "app/styles/colors";

export const ImageAvatar = ({ image }) => {

  return (
    <Avatar
      src={image}
      sx={{
        width: 40,
        height: 40,
        border: `1px solid ${colors.textSecondary}`,
        '.MuiAvatar-img': { objectFit: 'contain' }
      }}
    />
  );
}