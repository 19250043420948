import { get, post, patch, deleted } from "app/apis";
import AddressesRoutes from "./Addresses.Routes";

const AddressesServices = {
  getCities: async () => {
    const data = await get(AddressesRoutes.getCities);
    return data;
  },
  getCountries: async () => {
    const data = await get(AddressesRoutes.getCountries);
    return data;
  },
};

export default AddressesServices;
