import BookingDetail from "app/views/Bookings/BookingDetail";
import Bookings from "app/views/Bookings/Bookings";
import AddCompany from "app/views/Companies/AddCompany";
import Companies from "app/views/Companies/Companies";
import ViewCompany from "app/views/Companies/ViewCompany";
import DailyTrips from "app/views/DailyTrips/DailyTrips";
import Passengers from "app/views/Passengers/Passengers";
import SupportTicket from "app/views/Support/SupportTicket";
import SupportTicketDetail from "app/views/Support/SupportTicketDetail";
import VehicleManagement from "app/views/VehicleManagement/VehicleManagement";
import Vehicles from "app/views/Vehicles/Vehicles";
import DailyTripDetail from "app/views/DailyTrips/DailyTripDetail";
import ChatSupport from "app/views/Support/ChatSupport/ChatSupport";
import PricingConfig from "app/views/PricingConfig/PricingConfig";
import FuelType from "app/views/PricingConfig/FuelType";

const AdminRoutes = [
  {
    path: "/companies",
    component: <Companies />,
  },
  {
    path: "/vehicle-management",
    component: <VehicleManagement />,
  },
  {
    path: "/vehicles",
    component: <Vehicles />,
  },
  {
    path: "/company/add-company",
    component: <AddCompany />,
  },
  {
    path: "/company-information/:id",
    component: <ViewCompany />,
  },
  {
    path: "/bookings",
    component: <Bookings />,
  },
  {
    path: "/booking-detail/:id",
    component: <BookingDetail />,
  },
  {
    path: "/daily-trips",
    component: <DailyTrips />,
  },
  {
    path: "/daily-trips/:id",
    component: <DailyTrips />,
  },
  {
    path: "/trip-details/:id",
    component: <DailyTripDetail />,
  },
  {
    path: "/passengers",
    component: <Passengers />,
  },
  {
    path: "/pricing",
    component: <PricingConfig />,
  },
  {
    path: "/fueltype",
    component: <FuelType />,
  },
  {
    path: "/support-ticket",
    component: <SupportTicket />,
  },
  {
    path: "/ticket-detail/:id",
    component: <SupportTicketDetail />,
  },
  {
    path: "/chat-support",
    component: <ChatSupport />,
  },
];

export default AdminRoutes;
