// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDg7DPMDiOHqwxOQQNT-RSaVRaYtbKujBw",
  authDomain: "takseem-firebase.firebaseapp.com",
  projectId: "takseem-firebase",
  storageBucket: "takseem-firebase.appspot.com",
  messagingSenderId: "367098937676",
  appId: "1:367098937676:web:bfa63e5afec113eab1cfe9",
  measurementId: "G-S8GDCW0HDJ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);



export const getUserToken = () => {
  return getToken(messaging, { vapidKey: 'BH3i4gixNf1uieWAF54YKlM6TBw7GlhbRTU6Ns5kElOtxSP4_HP_SNHJ5h7RD-RZBRL81Q8mXNerI9RksRZcozw' })
    .then((currentToken) => {
      if (currentToken) {
        return currentToken
      }
      else {
        console.log("no auth")
      }
    })
    .catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
    });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      // console.log("🚀 ~ file: firebase.js:40 ~ onMessage ~ payload", payload)
      resolve(payload);
    });
  });
