import React, { Fragment, useState } from "react";
import { Grid, Box, MenuItem, Menu, Avatar, Typography, Divider, Button, AppBar, Toolbar } from "@mui/material";
import { NotificationsNone, SettingsOutlined, Person, PersonAdd } from "@mui/icons-material";

import useAuth from "app/hooks/useAuth";
import colors from "app/styles/colors";
import Images from "app/assets/Images";

//  *Imported Component
import { PrimaryButton } from "app/components/Buttons/Button";

function Header() {
  const { logout, name, email } = useAuth();

  // *Anchor Box
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    if (anchorEl) {
      setAnchorEl(null);
    }
    else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Fragment>
      <AppBar position="fixed" open={true} sx={{ mb: 20, bgcolor: colors.white, boxShadow: 0 }}>
        <Toolbar >
          <Grid
            container
            py={2}
            px={3}
            justifyContent={"space-between"}
          >
            <Grid item xs={3}>
            </Grid>
            <Grid item xs={9}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  float: "right",
                }}
              >
                {/* <Box
                  sx={{
                    borderRadius: "8px",
                    backgroundColor: colors.primaryLight,
                    px: 1,
                    py: 0.75,
                    mr: 3,
                  }}
                >
                  <NotificationsNone
                    sx={{ color: colors.primary, fontSize: "22px" }}
                  />
                </Box> */}
                <Box
                  onClick={(e) => { handleClick(e) }}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    p: 1,
                    borderRadius: "22px",
                    backgroundColor: open ? colors.secondary : colors.lightGray,
                    "&:hover": {
                      backgroundColor: colors.secondary,
                      cursor: "pointer",
                      ".MuiSvgIcon-root": { color: colors.lightGray },
                    },
                  }}
                >
                  <img src={Images.userProfile} alt="User Profile" />
                  <SettingsOutlined
                    sx={{ color: open ? colors.lightGray : colors.secondary, fontSize: "26px", ml: 2 }}
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}

            PaperProps={{
              elevation: 0,
              sx: {
                width: '350px',
                px: 2,
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 60,
                  height: 60,
                  ml: 1,
                  mr: 1,

                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <Typography sx={{
              fontSize: '16px', fontWeight: 500, color: colors.black,
              p: 1.5
            }}>User Profile</Typography>
            <Box sx={{ display: 'flex', alignItems: "center", cursor: "pointer" }}>
              <Avatar src={Images.userProfile} sx={{ borderRadius: '50px' }} />
              <Box>
                <Typography
                  sx={{
                    fontSize: '18px',
                    fontWeight: "bold"
                  }}>{name}</Typography>
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: 500,
                    color: colors.textSecondary
                  }}>{email}</Typography>
              </Box>
            </Box>
            <Divider sx={{ my: 1 }} />
            <MenuItem sx={{ p: 1, borderRadius: "8px" }} >
              <Box sx={{ display: 'flex' }}>
                <Button sx={{
                  bgcolor: colors.primaryLight,
                  mr: 1,
                  borderRadius: '10px'
                }}><Person fontSize="small" />
                </Button>
                <Box >
                  <Typography
                    sx={{
                      fontSize: '1rem',

                    }}>My Profile</Typography>
                  <Typography variant="h6"
                    sx={{
                      fontSize: '0.875rem',
                      color: colors.textSecondary
                    }}>Account Settings</Typography>
                </Box>
              </Box>
            </MenuItem>
            {/* <Divider sx={{ my: 1 }} />
            <MenuItem sx={{ p: 1, borderRadius: "8px" }} >
              <Box sx={{ display: 'flex' }} >
                <Button
                  sx={{
                    bgcolor: colors.primaryLight,
                    mr: 1,
                    borderRadius: '10px'
                  }}><PersonAdd fontSize="small" />
                </Button>
                <Box>
                  <Typography variant="h5"
                    sx={{
                      fontSize: '1rem'
                    }}>Account</Typography>
                  <Typography variant="h6"
                    sx={{
                      fontSize: '0.875rem',
                      color: colors.textSecondary
                    }}>Add Another Account</Typography>
                </Box>
              </Box>
            </MenuItem> */}
            <Box sx={{ my: 1.5 }}>
              <PrimaryButton
                data-testId="logout-btn"
                title="logout"
                onClick={logout}
                fullWidth={true}
              />
            </Box>
          </Menu>
        </Toolbar>
      </AppBar>

    </Fragment>
  );
}

export default Header;
