import React, { Fragment, useState } from "react";
import { Box, Chip, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Tooltip, Typography } from "@mui/material";
import { DeleteTwoTone } from "@mui/icons-material";
import { BsPlusLg } from "react-icons/bs";
import { KeyboardArrowLeft, KeyboardArrowRight, CheckRounded, ClearRounded } from "@mui/icons-material";
import colors from "app/styles/colors";
import { ErrorToaster, SuccessToaster } from "app/components/Toaster/Toaster";
import { Loading } from "app/assets/Images";
import SimpleDialog from "app/components/Dialog/ValidationDialog";
import { useEffect } from "react";
import { useTheme } from "@emotion/react";
import PricingServices from "app/apis/PricingConfig/PricingServices";
import AddFuelTypeDialog from "app/components/Dialog/AddFuelTypeDialog";
import AddressesServices from "app/apis/Addresses/AddressesServices";
import FuelTypeFilter from "app/components/Filters/FuelTypeFilter";
import AddOilPriceDialog from "app/components/Dialog/AddOilPriceDialog";

// Table Pagination ActionsComponent
function TablePaginationActions(props) {
  const theme = useTheme;

  const { count, page, rowsPerPage, onPageChange } = props;

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="next page">
        {theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
    </Box>
  );
}

const tableHeader = ["Country", "FuelType", "Price", "Currency", "GHG Emission", "Action"];

const FuelType = () => {
  const [loaderStatus, setLoaderStatus] = useState(false);
  const [loading, setLoading] = useState(false);

  //* For Pagination
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [checkFilter, setCheckFilter] = useState("");

  //* For DELETE Fuel Type
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [fuelId, setFuelId] = useState("");
  //* For Creating New Fuel Type
  const [addFuelDialog, setAddFuelDialog] = useState(false);

  //* For Update Oil Price
  const [addOilPriceDialog, setAddOilPriceDialog] = useState(false);

  const [fuelTypeData, setFuelTypeData] = useState([]);

  //*  For Editable Data Table
  const [fuelData, setFuelData] = useState();
  const [priceData, setPriceData] = useState();
  const [ghgData, setGhgData] = useState();
  const [update, setUpdate] = useState("");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getFuelType(newPage + 1, rowsPerPage, checkFilter);
  };

  const handleChangeRowsPerPage = (event) => {
    // setRowsPerPage(parseInt(event.target.value, 2));
    setRowsPerPage(Math.floor(event.target.value));
    setPage(0);
    getFuelType(1, event.target.value, checkFilter);
  };

  //* For Filteration For Get Status ID's.... update 25/5/23
  const dataFilter = (filterObj) => {
    setCheckFilter(filterObj, true);
    setRowsPerPage(10);
    setPage(0);
    getFuelType(1, 10, filterObj);
  };
  // const dataFilter = (country, search) => {
  //   setCheckCountry(country);
  //   setCheckSearch(search);
  //   setRowsPerPage(10);
  //   setPage(0);
  //   getFuelType(1, 10, search ? search : "", country ? country : "");
  // };

  //* Api For Get Fuel Type
  const getFuelType = async (page, row, filter) => {
    try {
      setLoaderStatus(true);
      let obj = {
        page: page ? page : 1,
        size: row ? row : 10,
        ...filter,
      };
      const params = new URLSearchParams(obj);
      const { status, responseCode, data } = await PricingServices.getFuelType(params);
      if (status === true && responseCode === 200) {
        setFuelTypeData(data.fuel_types);
        setCount(data.count);
      }
    } catch (error) {
      ErrorToaster(error);
    } finally {
      setLoaderStatus(false);
    }
  };

  //*  For Editable Data Table
  const onChangefuel = (e, id, type) => {
    setUpdate(type);
    const { value } = e.target;
    setFuelData(value);
  };
  const onChangePrice = (e, id, type) => {
    setUpdate(type);
    const { value } = e.target;
    setPriceData(value);
  };
  const onChangeGhg = (e, id, type) => {
    setUpdate(type);
    const { value } = e.target;
    setGhgData(value);
  };

  const handleTableUpdate = (id, country, fuel, price, ghg) => {
    updatePricing(id, country, fuelData ? fuelData : fuel, priceData ? priceData : price, ghgData ? ghgData : ghg);
  };
  //* for Update Specific Field
  const updatePricing = async (id, country, fuel, price, ghg) => {
    try {
      let obj = {
        id: id,
        country_id: country,
        fuel: fuel,
        price: price,
        ghg_emission: ghg,
      };
      const { data, message } = await PricingServices.updateFuelType(obj);
      if (data === null) return;
      setUpdate(false);
      SuccessToaster(message);
      getFuelType();
    } catch (error) {
      ErrorToaster(error);
    }
  };
  //

  //* API For Get Countries Name
  const [countryName, setCountryName] = useState([]);
  const getCountriesName = async () => {
    try {
      const { status, responseCode, data } = await AddressesServices.getCountries();
      if (status === true && responseCode === 200) {
        setCountryName(data.rows);
      }
    } catch (error) {
      ErrorToaster(error);
    }
  };

  //* For DELETE Fuel Type

  const fuelDelete = async () => {
    setDeleteDialog(true);
    setLoading(true);
    try {
      const { data } = await PricingServices.deleteFuelType(fuelId);
      if (data === null) return;
      SuccessToaster("Fuel Type Deleted");
      getFuelType();
      setDeleteDialog(false);
    } catch (error) {
      ErrorToaster(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getFuelType("", "", { countries: 2 });
    getCountriesName();
  }, []);

  return (
    <Fragment>
      <AddFuelTypeDialog
        open={addFuelDialog}
        handleClose={() => setAddFuelDialog(!addFuelDialog)}
        toggleFunction={() => setAddFuelDialog(false)}
        getFuelType={() => getFuelType()}
        countryName={countryName}
      />
      <AddOilPriceDialog
        open={addOilPriceDialog}
        handleClose={() => setAddOilPriceDialog(!addOilPriceDialog)}
        toggleFunction={() => setAddOilPriceDialog(false)}
        getFuelType={() => getFuelType()}
        countryName={countryName}
      />
      {/* ========== Delete Dialog ========== */}
      <SimpleDialog open={deleteDialog} onClose={() => setDeleteDialog(false)} title={"Are you sure to remove this?"} action={fuelDelete} loading={loading} />

      <Box
        sx={{
          backgroundColor: colors.lightGray,
          p: 1,
          borderRadius: "12px",
        }}
      >
        <FuelTypeFilter dataFilter={(filterObj) => dataFilter(filterObj)} countryName={countryName} />
        {/* <FuelTypeFilter dataFilter={(country, search) => dataFilter(country, search)} countryName={countryName} /> */}
        <Box sx={{ display: "flex", justifyContent: "flex-end", bgcolor: colors.white, p: 1, borderRadius: "12px", textAlign: "right", mt: 2 }}>
          <Box
            onClick={() => setAddOilPriceDialog(!addOilPriceDialog)}
            sx={{
              mr: 1,
              display: "flex",
              alignItems: "center",
              borderRadius: 1,
              p: 1,
              cursor: "pointer",
              color: colors.ebonyClay,
              bgcolor: colors.dairyCream,
            }}
          >
            <BsPlusLg sx={{ color: colors.primary, fontSize: "20px" }} />
            <Typography sx={{ fontSize: "12px", ml: 1 }}> Update Oil Price</Typography>
          </Box>
          <Box
            onClick={() => setAddFuelDialog(!addFuelDialog)}
            sx={{
              display: "flex",
              alignItems: "center",
              borderRadius: 1,
              p: 1,
              cursor: "pointer",
              color: colors.primary,
              bgcolor: colors.primaryLight,
            }}
          >
            <BsPlusLg sx={{ color: colors.primary, fontSize: "20px" }} />
            <Typography sx={{ fontSize: "12px", ml: 1 }}> Add Fuel Type</Typography>
          </Box>
        </Box>
        {loaderStatus ? (
          <Box sx={{ width: 1, textAlign: "center", pt: 5 }}>
            <Loading />
          </Box>
        ) : (
          <TableContainer
            component={Paper}
            sx={{
              "&::-webkit-scrollbar": {
                bgcolor: colors.white,
                height: "8px",
                borderRadius: "10px",
                cursor: "pointer",
              },
              "&::-webkit-scrollbar-thumb": {
                bgcolor: colors.primary,
                borderRadius: "10px",
                cursor: "pointer",
              },
              "&.MuiPaper-root": {
                borderRadius: "12px",
                mt: 2,
                boxShadow: 0,
              },
            }}
          >
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow sx={{ bgcolor: colors.lightGray }}>
                  {tableHeader?.map((row, index) => (
                    <TableCell key={index} sx={{ fontWeight: "bold" }}>
                      {row}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {fuelTypeData?.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      <Chip label={item?.country?.name} key={index} size="small" sx={{ color: colors.bondiBlue, pt: 0.5 }} />
                    </TableCell>
                    <TableCell>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Box>
                          <input
                            onChange={(e) => onChangefuel(e, item?.id, `fuelType${index}`)}
                            defaultValue={item?.fuel}
                            type="text"
                            style={{ border: "none", fontSize: "15px", padding: "12px", width: 125 }}
                          />
                        </Box>
                        {update === `fuelType${index}` && (
                          <Box>
                            <IconButton
                              onClick={() => {
                                handleTableUpdate(item?.id, item?.country_id, item?.fuel, item?.price, item?.ghg_emission);
                              }}
                              sx={{ cursor: "pointer", color: colors.primary }}
                            >
                              <CheckRounded />
                            </IconButton>
                            <IconButton
                              onClick={() => {
                                setUpdate("");
                              }}
                              sx={{ cursor: "pointer", color: colors.primary }}
                            >
                              <ClearRounded sx={{ color: colors.danger }} />
                            </IconButton>
                          </Box>
                        )}
                      </Box>
                    </TableCell>

                    <TableCell>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Box>
                          <input
                            onChange={(e) => onChangePrice(e, item?.id, `price${index}`)}
                            defaultValue={item?.price}
                            type="text"
                            style={{ border: "none", fontSize: "15px", padding: "12px", width: 120 }}
                          />
                        </Box>
                        {update === `price${index}` && (
                          <Box>
                            <IconButton
                              onClick={() => {
                                handleTableUpdate(item?.id, item?.country_id, item?.fuel, item?.price, item?.ghg_emission);
                              }}
                              sx={{ cursor: "pointer", color: colors.primary }}
                            >
                              <CheckRounded />
                            </IconButton>
                            <IconButton
                              onClick={() => {
                                setUpdate("");
                              }}
                              sx={{ cursor: "pointer", color: colors.primary }}
                            >
                              <ClearRounded sx={{ color: colors.danger }} />
                            </IconButton>
                          </Box>
                        )}
                      </Box>
                    </TableCell>

                    <TableCell>{item?.country?.currency}</TableCell>

                    <TableCell>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Box>
                          <input
                            onChange={(e) => onChangeGhg(e, item?.id, `emission${index}`)}
                            defaultValue={item?.ghg_emission}
                            type="text"
                            style={{ border: "none", fontSize: "15px", padding: "12px", width: 120 }}
                          />
                        </Box>
                        {update === `emission${index}` && (
                          <Box>
                            <IconButton
                              onClick={() => {
                                handleTableUpdate(item?.id, item?.country_id, item?.fuel, item?.price, item?.ghg_emission);
                              }}
                              sx={{ cursor: "pointer", color: colors.primary }}
                            >
                              <CheckRounded />
                            </IconButton>
                            <IconButton
                              onClick={() => {
                                setUpdate("");
                              }}
                              sx={{ cursor: "pointer", color: colors.primary }}
                            >
                              <ClearRounded sx={{ color: colors.danger }} />
                            </IconButton>
                          </Box>
                        )}
                      </Box>
                    </TableCell>

                    <TableCell>
                      <Tooltip title="Delete">
                        <IconButton
                          onClick={() => {
                            setFuelId(item?.id);
                            setDeleteDialog(true);
                          }}
                        >
                          <DeleteTwoTone sx={{ color: colors.danger }} />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Table sx={{ marginTop: "12px" }}>
              <TablePagination
                sx={{ borderBottom: "none", bgcolor: colors.white, borderRadius: "12px" }}
                rowsPerPageOptions={[10, 20, 30]}
                colSpan={12}
                count={+count}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    "aria-label": "rows per page",
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </Table>
          </TableContainer>
        )}
      </Box>
    </Fragment>
  );
};

export default FuelType;
