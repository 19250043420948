import React, { useEffect, useState } from "react";
import { Box, Checkbox, Dialog, DialogContent, DialogTitle, Divider, FormControl, Grid, IconButton, InputLabel, ListItemText, MenuItem, Select, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { CloseRounded } from "@mui/icons-material";
import { ErrorToaster } from "app/components/Toaster/Toaster";
import { InputField } from "app/components/UI/TextField";
import { Error } from "app/components/UI/Error";
import { PrimaryButton } from "app/components/Buttons/Button";

function VehicleModelDialog({ open, close, add, update, loading, updateData, vehicleMakes, countryName }) {
  const [vehicleMakeSelected, setVehicleMakeSelected] = useState({});
  const [countrySelected, setCountrySelected] = useState([]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm();

  const countryLoop = (data) => {
    let countryId = [];
    for (let i = 0; i < data.length; i++) {
      let country = data[i].country_id;
      countryId = [...countryId, country];
    }
    setCountrySelected(countryId);
  };
  const handleCountryChange = (event) => {
    const {
      target: { value },
    } = event;
    setCountrySelected(
      // On autofill we get a stringified value.
      // typeof value === 'string' ? value.split(',') : value,
      value
    );
  };

  // *For Submit Form
  const submitForm = async (formData) => {
    try {
      let obj = {
        make_id: vehicleMakeSelected,
        name: formData.model,
        fuel_avg: formData.fuelAvg,
        // engine_class: formData.engineClass,
        engine_cc: parseInt(formData.engineCC),
        tyre_cost: formData.tyreCost,
        oil_capacity: formData.oilCapacity,
        maintenance_cost: formData.maintenance,
        // countries: formData.country,
        countries: countrySelected,
      };
      if (updateData) {
        obj.id = updateData.id;
        update(obj);
      } else {
        add(obj);
      }
    } catch (error) {
      ErrorToaster(error);
    }
  };

  useEffect(() => {
    if (updateData) {
      setValue("model", updateData.name);
      // setValue("engineClass", updateData.engineClass);
      setValue("fuelAvg", updateData.fuel_avg);
      setValue("engineCC", updateData.engine_cc);
      setValue("tyreCost", updateData.tyre_cost);
      setValue("oilCapacity", updateData.oil_capacity);
      setValue("maintenance", updateData.maintenance_cost);
      setValue("vehicleMake", updateData.make_id);
      countryLoop(updateData?.countries);
      setVehicleMakeSelected(updateData.make_id);
    } else {
      setVehicleMakeSelected("");
      setCountrySelected([]);
    }
  }, [updateData]);

  return (
    <Dialog open={open} fullWidth sx={{ "& .MuiDialog-paper": { borderRadius: "12px" } }}>
      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        Vehicle Model
        <IconButton
          onClick={() => {
            close();
            reset();
          }}
        >
          <CloseRounded />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Typography component={"form"} onSubmit={handleSubmit(submitForm)}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <FormControl size="small" fullWidth variant="outlined">
                <InputLabel> Select Countries</InputLabel>
                <Select
                  multiple
                  label={"Select Countries"}
                  value={countrySelected}
                  onChange={handleCountryChange}
                  renderValue={(selected) => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.2 }}>
                      {selected.map((item, index) => (
                        <Typography key={index}>{countryName?.find((e) => e.id === item)?.name} </Typography>
                        // <Chip key={index} label={countryName?.find((e) => e.id === item)?.name} />
                      ))}
                    </Box>
                  )}
                  sx={{ textAlign: "left", mb: 2 }}
                >
                  {countryName?.map((item, index) => (
                    <MenuItem key={index} value={item.id}>
                      <Checkbox checked={countrySelected?.indexOf(item.id) > -1} />
                      <ListItemText primary={`${item.id}-${item.name}`} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl size="small" fullWidth variant="outlined">
                <InputLabel>Vehicle Make</InputLabel>
                <Select
                  label={"Vehicle Make"}
                  value={vehicleMakeSelected}
                  sx={{ textAlign: "left", mb: 2 }}
                  error={errors?.vehicleMake?.message && true}
                  {...register("vehicleMake", {
                    required: "Please select vehicle make.",
                  })}
                >
                  {vehicleMakes?.map((item, index) => (
                    <MenuItem
                      key={index}
                      value={item.id}
                      onClick={() => {
                        setVehicleMakeSelected(item.id);
                      }}
                    >
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
                {errors?.vehicleMake?.message && <Error message={errors?.vehicleMake?.message} />}
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <InputField
                label="Model"
                error={errors?.model?.message}
                register={register("model", {
                  required: "Please enter model.",
                })}
              />
            </Grid>
            <Grid item xs={6}>
              <InputField
                label="Fuel Average"
                error={errors?.fuelAvg?.message}
                register={register("fuelAvg", {
                  required: "Please enter fuel Average.",
                })}
              />
            </Grid>
            <Grid item xs={6}>
              <InputField
                label="CC"
                type={"number"}
                error={errors?.engineCC?.message}
                register={register("engineCC", {
                  required: "Please enter cc.",
                })}
              />
            </Grid>
            <Grid item xs={6}>
              <InputField
                label="Tyre Cost"
                type={"number"}
                error={errors?.tyreCost?.message}
                register={register("tyreCost", {
                  required: "Please enter tyre cost.",
                })}
              />
            </Grid>
            <Grid item xs={6}>
              <InputField
                label="Oil Capacity"
                type={"number"}
                error={errors?.oilCapacity?.message}
                register={register("oilCapacity", {
                  required: "Please enter oil capacity.",
                })}
              />
            </Grid>
            <Grid item xs={6}>
              <InputField
                label="Maintenance Cost"
                type={"number"}
                error={errors?.maintenance?.message}
                register={register("maintenance", {
                  required: "Please enter maintenance cost.",
                })}
              />
            </Grid>
            <Grid item xs={12} display="flex" justifyContent="center">
              <Box>
                <PrimaryButton data-testId="submit-btn" title="Submit" type="submit" loading={loading} fullWidth={true} />
              </Box>
            </Grid>
          </Grid>
        </Typography>
      </DialogContent>
    </Dialog>
  );
}

export default VehicleModelDialog;
