import React, { Fragment, useEffect, useState } from 'react'
import { Alert, AlertTitle, Box, Divider, Grid, IconButton, InputAdornment, Switch, TextField, Tooltip, Typography } from '@mui/material'
import { useForm } from 'react-hook-form'
import { FaMapMarkerAlt } from 'react-icons/fa'
import { AddLocationAltTwoTone, DeleteTwoTone, EditTwoTone, GroupAddTwoTone, LoopTwoTone } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import useAuth from 'app/hooks/useAuth'
import { ErrorToaster, SuccessToaster } from 'app/components/Toaster/Toaster'
import colors from 'app/styles/colors'
import Layout from 'app/layout/Layout'
import ValidationDialog from 'app/components/Dialog/ValidationDialog'
import MapModal from 'app/components/Dialog/MapDialogBox'
import { InputField, OutlineInputField, PhoneInputField } from 'app/components/UI/TextField'
import { Loading } from 'app/assets/Images'
import BreadCrumbs from 'app/components/Breadcrumbs/Breadcrumbs'
import SelectField from 'app/components/UI/SelectFields'
import { empStrengthRange, industryTypes } from 'app/utils/CustomData'
import { emailRegex } from 'app/utils'
import CompaniesServices from 'app/apis/Companies/CompaniesServices'
import { PrimaryButton } from 'app/components/Buttons/Button'


// *import components


function CompanyDetails() {

  const { register, handleSubmit, formState: { errors }, setValue } = useForm();

  const { role, companyId } = useAuth()

  const navigate = useNavigate()

  // const [ownerNumber, setOwnerNumber] = useState("")
  // const [contactPersonDetails, setContactPersonDetails] = useState({ name: "", email: "", phone: "" })

  const [rolesList, setRolesList] = useState([]);
  const [cities, setCities] = useState([]);

  const [companyData, setCompanyData] = useState({})
  const [telephoneNumber, setTelephoneNumber] = useState("")
  const [empStrength, setEmpStrength] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [updatedAddress, setUpdatedAddress] = useState("")
  const [currentLocation, setCurrentLocation] = useState({ latitude: 0, longitude: 0 })
  const [latLng, setLatLng] = useState({ lat: 0, lng: 0 })
  const [updatedCity, setUpdatedCity] = useState("")
  const [updatedIndustryType, setUpdatedIndustryType] = useState("")
  const [buttonEnable, setButtonEnable] = useState(false)
  const [loading, setLoading] = useState(false)
  // const [currentAddress, setCurrentAddress] = useState({})

  const [addAddressFields, setAddAddressFields] = useState([])
  const [contactPersonFields, setContactPersonFields] = useState([])
  const [updatedRole, setUpdatedRole] = useState("")

  const [addressAndLocationName, setAddressAndLocationName] = useState({
    locationName: "", address: ""
  })
  const [contactPersonDetails, setContactPersonDetails] = useState({
    name: "", phone: "", email: "", department: ""
  })

  // for disable all field
  const [disableFields, setDisableFields] = useState(true)

  // *for switch
  const [switchStatus, setSwitchStatus] = useState("")

  // *for loader
  const [loaderStatus, setLoaderStatus] = useState(true);

  const [validationDialog, setValidationDialog] = useState(false);
  const [validationMessage, setValidationMessage] = useState("");

  const changeLocation = (val) => {
    const latitude = val.lat()
    const longitude = val.lng()
    setLatLng({ lat: latitude, lng: longitude })
  }

  const handleAddress = (val) => {
    let findCityID = cities.find((e) => e.name.toLowerCase() == val.city.toLowerCase())
    if (findCityID) {
      setUpdatedAddress(val.address)
      setValue("pinLocation", val.address)
      setUpdatedCity({
        id: findCityID.id,
        name: val.city
      })
      setValue("city", val.city)
      setLatLng({ lat: val.lat, lng: val.lng })
    }
  }

  // const revertChanges = () => {
  //   setValue("name", companyData?.name)
  //   setValue("email", companyData?.email)
  //   setValue("phone", companyData?.telephone)
  //   setTelephoneNumber(companyData?.telephone)
  //   setValue("emp_strength", companyData?.employee_strength)
  //   setEmpStrength(companyData?.employee_strength)
  //   setValue("typeOfIndustry", companyData?.type_industry)
  //   setValue("ntn", companyData?.ntn)
  //   setValue("website", companyData?.website)
  //   setAddAddressFields(companyData.company_addresses)
  //   setContactPersonFields(companyData.company_contactpersons)
  // }

  // *For get company detail
  const getCompanyDetail = async () => {
    try {
      const { company } = await CompaniesServices.getCompanyDetail(companyId)
      const { status, responseCode, data } = company
      if (status === true && responseCode === 200) {
        setCompanyData(data)
        setValue("name", data?.name)
        setValue("email", data?.email)
        setValue("telephone", data?.telephone)
        setTelephoneNumber(data?.telephone)
        setValue("emp_strength", data?.employee_strength)
        setEmpStrength(data?.employee_strength)
        setUpdatedIndustryType(data?.type_industry)
        setValue("typeOfIndustry", data?.type_industry)
        setValue("ntn", data?.ntn)
        setValue("website", data?.website)
        setAddAddressFields(data?.company_addresses)
        setContactPersonFields(data?.company_contactpersons)
        setLoaderStatus(false)
      }
    } catch (error) {
      console.log("🚀 ~ file: CompanyDetail.js:133 ~ getCompanyDetail ~ error:", error)
      ErrorToaster(error)
    }
  }

  // *For updated company details
  const updatedCompanyDetail = async (formData) => {
    try {
      let sortedAddresses = []

      for (let index = 0; index < addAddressFields.length; index++) {
        let addressSort = {
          city_id: addAddressFields[index].city?.id,
          formatted_address: addAddressFields[index].formatted_address,
          google_address: addAddressFields[index].google_address,
          latitude: addAddressFields[index].latitude,
          longitude: addAddressFields[index].longitude,
          name: addAddressFields[index].name,
        }
        sortedAddresses.push(addressSort)
      }
      let sortedPersons = []
      for (let i = 0; i < contactPersonFields.length; i++) {
        let personSort = {
          phone: contactPersonFields[i].phone,
          name: contactPersonFields[i].name,
          email: contactPersonFields[i].email,
          designation: contactPersonFields[i]?.designation?.name ? contactPersonFields[i]?.designation.name : contactPersonFields[i]?.designation,
          department: contactPersonFields[i].department,
          company_role_id: 1
        }
        sortedPersons.push(personSort)
      }

      let obj = {
        company: {
          id: companyId,
          name: formData.name,
          email: formData.email,
          type_industry: formData.typeOfIndustry,
          employee_strength: empStrength,
          telephone: formData.telephone,
          ntn: formData.ntn,
          website: formData.website
        },
        contact_persons: sortedPersons,
        addresses: sortedAddresses
      }

      if (contactPersonFields.length === 0 && addAddressFields.length === 0) {
        ErrorToaster("Please enter atleast 1 address and contact person")
      }
      else if (contactPersonFields.length === 0) {
        ErrorToaster("Please enter atleast 1 contact person")
      }
      else if (addAddressFields.length === 0) {
        ErrorToaster("Please enter atleast 1 address")
      }
      else {
        const { status, responseCode } = await CompaniesServices.updatedCompanyDetail(obj)
        if (status === true && responseCode === 200) {
          SuccessToaster("Company details updated")
          setButtonEnable(false)
          setDisableFields(true)
        }
      }

    } catch (error) {
      console.log('file: CompanyDetail.js:201 => updatedCompanyDetail => error', error)
      ErrorToaster(error)
    }
    finally {
      setLoading(false)
    }
  }

  // *For add address details
  const handleAddressFields = (id) => {
    if (addressAndLocationName.locationName && addressAndLocationName.address && updatedAddress && updatedCity) {
      let obj = {
        id: id,
        name: addressAndLocationName.locationName,
        formatted_address: addressAndLocationName.address,
        google_address: updatedAddress,
        city: updatedCity,
        latitude: latLng.lat,
        longitude: latLng.lng
      }
      let fields = [...addAddressFields]
      fields.push(obj)
      setAddAddressFields(fields)
      setAddressAndLocationName({
        locationName: "", address: ""
      })
      setValue("city", "")
      setValue("pinLocation", "")
      setValue("locationName", "")
      setValue("address", "")
      setUpdatedCity("")
      setUpdatedAddress("")
    }
    else {
      ErrorToaster("All address fields must be filled to enter new address")
    }
  }

  // *For add contact persons details
  const handleContactPerson = (id) => {
    if (contactPersonDetails.name && contactPersonDetails.phone && contactPersonDetails.email && contactPersonDetails.department && updatedRole) {
      let obj = {
        id: id,
        name: contactPersonDetails.name,
        phone: contactPersonDetails.phone,
        email: contactPersonDetails.email,
        designation: updatedRole,
        department: contactPersonDetails.department,
        company_role_id: 1
      }
      let fields = [...contactPersonFields]
      fields.push(obj)
      setContactPersonFields(fields)
      setContactPersonDetails({
        name: "", phone: "", email: "", department: ""
      })
      setUpdatedRole("")
      setValue("contactPersonName", "")
      setValue("contactPersonTelephone", "")
      setValue("contactPersonEmail", "")
      setValue("contactPersonRole", "")
      setValue("contactPersonDepartment", "")
    }
    else {
      ErrorToaster("All contact person fields must be filled to enter new")
    }
  }

  const deleteAddressFields = (item) => {
    let filterAddress = addAddressFields.filter((e) => e.id !== item.id)
    setAddAddressFields(filterAddress)
    setButtonEnable(true)
  }

  const deletePersonField = (item) => {
    let filterPerson = contactPersonFields.filter((e) => e.id !== item.id)
    setContactPersonFields(filterPerson)
    setButtonEnable(true)
  }

  // *For update employee
  const updateCompanyStatus = async (data) => {
    try {
      let obj = {
        id: companyId,
        is_active: data ? false : true,
      }
      const { status, responseCode, message } = await CompaniesServices.updatedCompanyDetail(obj)
      if (status === true && responseCode === 200) {
        SuccessToaster(message)
        setSwitchStatus("")
        getCompanyDetail()
        setDisableFields(true)
      }

    } catch (error) {
      ErrorToaster(error)
    }
  }

  const handlePromise = (myValue) => {
    if (myValue) {
      setValidationDialog(false)
      updateCompanyStatus(switchStatus)
    }
    else {
      setValidationDialog(false)
    }
  }

  const getCurrentPosition = () => {
    navigator.geolocation.getCurrentPosition((location) => {
      const latitude = location.coords.latitude
      const longitude = location.coords.longitude
      setCurrentLocation({ latitude: latitude, longitude: longitude })
      setLatLng({ lat: latitude, lng: longitude })
    })
  }

  const getCompanyRoles = async () => {
    try {
      const { status, responseCode, data } = await CompaniesServices.getCompanyRoles()
      if (status === true && responseCode === 200) {
        setRolesList(data.roles)
      }
    } catch (error) {
      ErrorToaster(error)
    }
  }

  const getCities = async () => {
    try {
      const { status, responseCode, message } = await CompaniesServices.getCities()
      if (status === true && responseCode === 200) {
        setCities(message.data)
      }
    } catch (error) {
      ErrorToaster(error)
    }
  }

  useEffect(() => {
    getCompanyRoles()
    getCities()
    getCompanyDetail()
    getCurrentPosition()
  }, [])


  return (
    <Fragment>

      {/* ======= validation dialog =====  */}
      <ValidationDialog message={validationMessage} handlePromise={handlePromise} open={validationDialog} handleClose={() => setValidationDialog(!validationDialog)} />

      {openDialog &&
        <MapModal
          currentLocation={currentLocation}
          updatedLocation={latLng}
          locationChange={changeLocation}
          open={openDialog}
          handleAddress={handleAddress}
          onClose={() => setOpenDialog(!openDialog)} />
      }

      <Box sx={{ backgroundColor: colors.lightGray, p: 3, borderRadius: "12px", }}>

        <Grid container
          alignItems="center"
          justifyContent="space-between"
          sx={{
            bgcolor: colors.white, p: 2, borderRadius: "12px",
          }}>
          <Grid item xs={6}>
            <Typography variant="body1" fontWeight="bold" color="initial">Details</Typography>
          </Grid>
          <Grid item xs={6} sx={{ display: "flex", justifyContent: "flex-end" }}>
            <BreadCrumbs currentPage={['Details']} />
          </Grid>
        </Grid>

        {loaderStatus ? (
          <Box sx={{ width: 1, textAlign: "center", pt: 5 }}>
            <Loading />
          </Box>
        ) : (
          <Fragment>
            {!companyData?.is_verified ? (
              <Alert severity="warning" sx={{ mt: 2 }}>
                <AlertTitle>Verification Status</AlertTitle>
                Your company is not <strong>verified</strong> yet.
              </Alert>
            ) : (
              <Alert severity="success" sx={{ mt: 2 }}>
                <AlertTitle>Verification Status</AlertTitle>
                Your company is <strong>verified</strong>
              </Alert>
            )}

            <Typography component={"form"} onSubmit={handleSubmit(updatedCompanyDetail)}>

              {/* =================== Company Details ===================*/}
              <Box sx={{ bgcolor: colors.white, p: 3, borderRadius: "12px", mt: 2 }}>
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "flex-end" }}>
                  <Typography variant="body1" fontWeight="bold" color="initial">
                    {disableFields &&
                      <Box
                        onClick={() => { setDisableFields(false); }}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          borderRadius: 1,
                          p: 1,
                          mb: 1,
                          cursor: "pointer",
                          width: "fit-content",
                          color: colors.primary, bgcolor: colors.primaryLight,
                        }}>
                        <EditTwoTone sx={{ color: colors.primary, mr: 1, fontSize: "20px" }} />
                        <Typography sx={{ fontSize: "14px" }} >Edit</Typography>
                      </Box>
                    }
                    Company Details
                  </Typography>
                  <Box sx={{ textAlign: "center", display: "flex", alignItems: "center" }}>
                    <Box sx={{ mr: 3 }}>
                      <Typography variant="body2" sx={{ fontSize: "12px", color: colors.secondaryDark }}>Company Status</Typography>
                      <Switch size="small"
                        disabled={disableFields}
                        checked={companyData?.is_active}
                        onChange={() => { setSwitchStatus(companyData?.is_active); setValidationDialog(!validationDialog); setValidationMessage("Are you sure you want to change status") }}
                      />
                      <Typography variant="body2" color="primary" sx={{ fontWeight: "bold" }}>
                        {companyData?.is_active ? "Activated" : "De-activated"}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="body2" sx={{ fontSize: "12px", color: colors.secondaryDark }}>Company Code</Typography>
                      <Typography variant="h4" color="primary" sx={{ fontWeight: "bold" }}>{companyData.company_code?.code}</Typography>
                    </Box>
                  </Box>
                </Box>
                <Divider sx={{ my: 2 }} />
                <Grid container spacing={2} alignItems="center">
                  {/* Name */}
                  <Grid item xs={12} md={6}>
                    <InputField
                      disable={disableFields}
                      label="Company Name"
                      error={errors?.name?.message}
                      register={register("name", {
                        required: 'Please enter your name.',
                        onChange: () => setButtonEnable(true)
                      })
                      }
                    />
                  </Grid>
                  {/* Email */}
                  <Grid item xs={12} md={6}>
                    <InputField
                      disable={true}
                      label="Email"
                      error={errors?.email?.message}
                      register={register("email", {
                        required: 'Please enter your email.',
                        onChange: () => setButtonEnable(true),
                        pattern: {
                          value: emailRegex,
                          message: 'Please enter a valid email address',
                        }
                      })}
                    />
                  </Grid>
                  {/* Telephone */}
                  <Grid item xs={12} md={4}>
                    <PhoneInputField
                      disable={disableFields}
                      error={errors?.telephone?.message}
                      register={register("telephone", {
                        required: 'Please enter your telephone number.'
                      })}
                      onChange={(e) => {
                        setValue("telephone", e)
                        setButtonEnable(true)
                      }}
                      value={telephoneNumber}
                    />
                  </Grid>
                  {/* Employee Strength */}
                  <Grid item xs={12} md={3}>
                    <SelectField
                      data={empStrengthRange}
                      label="Employee Strength"
                      error={errors?.emp_strength?.message}
                      register={register("emp_strength", {
                        required: 'Please select employee strength.',
                        onChange: () => setButtonEnable(true)
                      })}
                      disable={disableFields}
                      stateValue={empStrength}
                      setState={(e) => setEmpStrength(e)}
                    />
                  </Grid>
                  {/* Type Of Industry */}
                  <Grid item xs={12} md={5}>
                    <SelectField
                      data={industryTypes}
                      label="Type Of Industry"
                      error={errors?.typeOfIndustry?.message}
                      register={register("typeOfIndustry", {
                        required: 'Please select industry type.',
                        onChange: () => setButtonEnable(true)
                      })}
                      disable={disableFields}
                      stateValue={updatedIndustryType}
                      setState={(e) => setUpdatedIndustryType(e)}
                    />
                  </Grid>
                  {/* NTN */}
                  <Grid item xs={12} md={6}>
                    <InputField
                      disable={disableFields}
                      label="NTN (optional)"
                      error={errors?.ntn?.message}
                      register={register("ntn", {
                        onChange: () => setButtonEnable(true)
                        // required: 'Please enter ntn number.',
                      })}
                    />
                  </Grid>
                  {/* Website */}
                  <Grid item xs={12} md={6}>
                    <InputField
                      disable={disableFields}
                      label="Website (optional)"
                      error={errors?.website?.message}
                      register={register("website", {
                        onChange: () => setButtonEnable(true)
                        // required: 'Please enter your website url.',
                      })}
                    />
                  </Grid>
                </Grid>
              </Box>

              {/* Address Section */}
              <Box sx={{ bgcolor: colors.white, p: 3, borderRadius: "12px", mt: 2 }}>
                <Typography variant="body1" fontWeight="bold" color="initial">Company Address</Typography>
                <Divider sx={{ my: 2 }} />
                <Grid container spacing={2} alignItems="center">

                  <Grid item container xs={11} spacing={2}>
                    {/* Location Name */}
                    <Grid item xs={12} md={6}>
                      <InputField
                        disable={disableFields}
                        label="Location Name"
                        register={register("locationName", {
                          onChange: (e) => { setButtonEnable(true); setAddressAndLocationName({ ...addressAndLocationName, locationName: e.target.value }) }
                        })
                        }
                      />
                    </Grid>
                    {/* Address */}
                    <Grid item xs={12} md={6}>
                      <InputField
                        disable={disableFields}
                        label="Address"
                        register={register("address", {
                          onChange: (e) => { setButtonEnable(true); setAddressAndLocationName({ ...addressAndLocationName, address: e.target.value }) }
                        })
                        }
                      />
                    </Grid>
                    {/* Pin Location */}
                    <Grid item xs={12} md={6}>
                      <OutlineInputField
                        disable={disableFields}
                        label="Pin Location"
                        updatedValue={updatedAddress}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              onClick={!disableFields ? (e) => { e.stopPropagation(); setOpenDialog(true) } : undefined}
                              edge="end" sx={{ color: colors.darkMintGreen, fontSize: "18px" }}
                            >
                              <FaMapMarkerAlt />
                            </IconButton>
                          </InputAdornment>
                        }
                        register={register("pinLocation")
                        }
                        onClick={!disableFields ? () => { setOpenDialog(true); setButtonEnable(true); } : undefined}
                      />
                    </Grid>
                    {/* City */}
                    <Grid item xs={12} md={6}>
                      <SelectField
                        data={cities}
                        label="City"
                        register={register("city", {
                          onChange: () => setButtonEnable(true)
                        })}
                        disable={disableFields}
                        stateValue={updatedCity?.name}
                        setState={(e) => setUpdatedCity(e)} />
                    </Grid>
                  </Grid>
                  {/* Add address icon*/}
                  <Grid item xs={12} md={1}>
                    <Tooltip title="Add Address" >
                      <Box
                        onClick={!disableFields ? () => handleAddressFields(addAddressFields.length + 1) : undefined}
                        sx={{
                          bgcolor: disableFields ? colors.lightGray : colors.primaryLight, p: 1, borderRadius: "4px",
                          display: "flex", alignItems: "center", justifyContent: "center",
                          cursor: "pointer"
                        }}>
                        <AddLocationAltTwoTone sx={{ color: disableFields ? colors.textLight : colors.primaryDark }} size={35} />
                      </Box>
                    </Tooltip>
                  </Grid>

                  {/* Additional Address fields */}
                  {addAddressFields?.map((item, index) => (
                    <Fragment key={index}>
                      <Grid item container xs={11} spacing={2}>
                        <Grid item xs={12} md={6}>
                          <Typography variant="body1" fontWeight="bold" color="initial">Location Name</Typography>
                          <Typography variant="body2" color="initial">{item.name}</Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Typography variant="body1" fontWeight="bold" color="initial">City</Typography>
                          <Typography variant="body2" color="initial">{item.city?.name}</Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Typography variant="body1" fontWeight="bold" color="initial">Address</Typography>
                          <Typography variant="body2" color="initial">{item.formatted_address}</Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Typography variant="body1" fontWeight="bold" color="initial">Pin Location</Typography>
                          <Typography variant="body2" color="initial">{item.google_address}</Typography>
                        </Grid>

                      </Grid>
                      <Grid item xs={12} md={1} >
                        <Tooltip title="Remove address" >
                          <Box
                            onClick={!disableFields ? () => deleteAddressFields(item) : undefined}
                            sx={{
                              bgcolor: disableFields ? colors.lightGray : "#ff21211f", p: 1, borderRadius: "4px",
                              display: "flex", alignItems: "center", justifyContent: "center",
                              cursor: "pointer"
                            }}>
                            <DeleteTwoTone sx={{ color: disableFields ? colors.textLight : colors.danger }} size={35} />
                          </Box>
                        </Tooltip>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                    </Fragment>
                  ))}
                </Grid>
              </Box>

              {/* =================== Contact Person =================== */}
              <Box sx={{ bgcolor: colors.white, p: 3, borderRadius: "12px", mt: 2 }}>
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                  <Typography variant="body1" fontWeight="bold" color="initial">Contact Person Details</Typography>
                  {/* Add Cp icon*/}
                  <Grid item xs={12} md={1}>
                    <Tooltip title="Add Contact Person" >
                      <Box
                        onClick={!disableFields ? () => handleContactPerson(contactPersonFields.length + 1) : undefined}
                        sx={{
                          bgcolor: disableFields ? colors.lightGray : colors.primaryLight, p: 1, borderRadius: "4px",
                          display: "flex", alignItems: "center", justifyContent: "center",
                          cursor: "pointer"
                        }}>
                        <GroupAddTwoTone sx={{ color: disableFields ? colors.textLight : colors.primaryDark }} size={35} />
                      </Box>
                    </Tooltip>
                  </Grid>
                </Box>
                <Divider sx={{ my: 2 }} />

                <Grid container columnSpacing={2}>
                  {/* Name */}
                  <Grid item xs={12} md={4}>
                    <InputField
                      disable={disableFields}
                      label="Person Name"
                      register={register("contactPersonName", {
                        onChange: (e) => { setButtonEnable(true); setContactPersonDetails({ ...contactPersonDetails, name: e.target.value }) }
                      })
                      }
                    />
                  </Grid>
                  {/* Phone */}
                  <Grid item xs={12} md={4}>
                    <PhoneInputField
                      disable={disableFields}
                      error={errors?.contactPersonTelephone?.message}
                      register={register("contactPersonTelephone")}
                      onChange={(e) => {
                        setValue("contactPersonTelephone", e)
                        setContactPersonDetails({ ...contactPersonDetails, phone: e })
                        setButtonEnable(true)
                      }}
                      value={contactPersonDetails.phone}
                    />
                  </Grid>
                  {/* Email */}
                  <Grid item xs={12} md={4}>
                    <InputField
                      disable={disableFields}
                      label="Person Email"
                      register={register("contactPersonEmail", {
                        onChange: (e) => { setButtonEnable(true); setContactPersonDetails({ ...contactPersonDetails, email: e.target.value }) },
                        // pattern: {
                        //   value: emailRegex,
                        //   message: 'Please enter a valid email address',
                        // }
                      })}
                    />
                  </Grid>
                  {/* Roles */}
                  <Grid item xs={12} md={6}>
                    <SelectField
                      data={rolesList}
                      label="Person Role"
                      register={register("contactPersonRole", {
                        onChange: () => setButtonEnable(true)
                      })}
                      disable={disableFields}
                      stateValue={updatedRole?.name}
                      setState={(e) => setUpdatedRole(e)} />
                  </Grid>
                  {/* Department  */}
                  <Grid item xs={12} md={6}>
                    <InputField
                      disable={disableFields}
                      label="Person Department"
                      register={register("contactPersonDepartment", {
                        onChange: (e) => { setButtonEnable(true); setContactPersonDetails({ ...contactPersonDetails, department: e.target.value }) }
                        // onChange: () => setButtonEnable(true)
                      })
                      }
                    />
                  </Grid>
                </Grid>

                <Grid container alignItems={"center"}>
                  {contactPersonFields?.map((item, index) => (
                    <Fragment key={index}>
                      <Grid item container xs={11} spacing={2}>
                        <Grid item xs={12} md={4}>
                          <Typography variant="body1" fontWeight="bold" color="initial">Name</Typography>
                          <Typography variant="body2" color="initial">{item.name}</Typography>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Typography variant="body1" fontWeight="bold" color="initial">Phone</Typography>
                          <Typography variant="body2" color="initial">{item.phone}</Typography>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Typography variant="body1" fontWeight="bold" color="initial">Email</Typography>
                          <Typography variant="body2" color="initial">{item.email}</Typography>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Typography variant="body1" fontWeight="bold" color="initial">Role</Typography>
                          <Typography variant="body2" color="initial">{item.designation?.name ? item.designation?.name : item.designation}</Typography>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Typography variant="body1" fontWeight="bold" color="initial">Department</Typography>
                          <Typography variant="body2" color="initial">{item.department}</Typography>
                        </Grid>

                      </Grid>
                      <Grid item xs={12} md={1} >
                        <Tooltip title="Remove person" >
                          <Box
                            onClick={!disableFields ? () => deletePersonField(item) : undefined}
                            sx={{
                              bgcolor: disableFields ? colors.lightGray : "#ff21211f", p: 1, borderRadius: "4px",
                              display: "flex", alignItems: "center", justifyContent: "center",
                              cursor: "pointer"
                            }}>
                            <DeleteTwoTone sx={{ color: disableFields ? colors.textLight : colors.danger }} size={35} />
                          </Box>
                        </Tooltip>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider sx={{ pt: 1 }} />
                      </Grid>
                    </Fragment>
                  ))}
                </Grid>
              </Box>

              {/* On boarding Person */}
              <Box sx={{ bgcolor: colors.white, p: 3, borderRadius: "12px", mt: 2 }}>
                <Typography variant="body1" fontWeight="bold" color="initial">Contact Person Details</Typography>
                <Divider sx={{ my: 2 }} />
                <Grid container spacing={2}>
                  {/* Name */}
                  <Grid item xs={12} md={4}>
                    <Typography variant="body1" fontWeight="bold" color="initial">Name</Typography>
                    <Typography variant="body2" color="initial">Adeel</Typography>
                  </Grid>
                  {/* Phone */}
                  <Grid item xs={12} md={4}>
                    <Typography variant="body1" fontWeight="bold" color="initial">Phone Number</Typography>
                    <Typography variant="body2" color="initial">03112197221</Typography>
                  </Grid>
                  {/* Email */}
                  <Grid item xs={12} md={4}>
                    <Typography variant="body1" fontWeight="bold" color="initial">Email</Typography>
                    <Typography variant="body2" color="initial">adeel.mangotech@gmail.com</Typography>
                  </Grid>
                  {/* Designation */}
                  <Grid item xs={12} md={4}>
                    <Typography variant="body1" fontWeight="bold" color="initial">Designation</Typography>
                    <Typography variant="body2" color="initial">Developer</Typography>
                  </Grid>
                  {/* Gender */}
                  <Grid item xs={12} md={4}>
                    <Typography variant="body1" fontWeight="bold" color="initial">Gender</Typography>
                    <Typography variant="body2" color="initial">male</Typography>
                  </Grid>
                </Grid>
              </Box>

              <Box sx={{ mt: 1, display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                {/* <PrimaryButton
                  myStyle={{ my: 2, borderRadius: "12px", bgcolor: colors.secondary, "&:hover": { bgcolor: colors.secondaryDark } }}
                  // onClick={() => { setButtonEnable(false); setDisableFields(true); revertChanges(); setAddAddressFields([]) }}
                  onClick={() => { navigate("/") }}
                >
                  Go back
                </PrimaryButton> */}
                {role === 'admin' &&
                  <PrimaryButton
                    data-testId="save-btn"
                    title={"GO Back"}
                    onClick={() => { navigate("/") }}
                  />
                }
                {buttonEnable &&
                  // <PrimaryButton
                  //   myStyle={{ my: 2, borderRadius: "12px" }}
                  //   type="submit"
                  //   loading={loading}
                  // >
                  //   Save changes
                  // </PrimaryButton>
                  <PrimaryButton
                    data-testId="save-btn"
                    title={"Save Changes"}
                    type={"submit"}
                    loading={loading}
                  />
                }
              </Box>

            </Typography>
          </Fragment>
        )}
      </Box>
    </Fragment>
  )
}

export default CompanyDetails