import { get, post, patch, deleted } from "app/apis";
import DailyTripsRoutes from "./DailyTrips.Routes";

const DailyTripsServices = {
  getAllTrips: async (id, count) => {
    const data = await get(DailyTripsRoutes.getAllTrips + `?booking_id=${id}&limit=${count}`);
    return data;
  },
  getTripDetails: async (id) => {
    const data = await get(DailyTripsRoutes.getTripDetails + `?ride_id=${id}`);
    return data;
  },
};

export default DailyTripsServices;
