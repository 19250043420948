import NotificationContext from "app/context/NotificationContext";
import useProvideNotification from "app/hooks/useProvideNotification";


function NotificationProvider({ children }) {
  const notify = useProvideNotification()
  return (
    <NotificationContext.Provider value={notify}>
      {children}
    </NotificationContext.Provider>
  )
}

export default NotificationProvider;