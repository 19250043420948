import React, { Fragment, useEffect, useState } from 'react'
import { Box, Chip, Divider, Grid, IconButton, Paper, Tab, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, Tabs, Tooltip, tooltipClasses, Typography } from '@mui/material'
import { TabContext, TabPanel } from '@mui/lab'
import { AddBusinessTwoTone, BuildCircleTwoTone, ColorLensTwoTone, DeleteTwoTone, DirectionsCarFilledTwoTone, EditTwoTone, Email, KeyboardArrowLeft, KeyboardArrowRight, Person, PersonOutlineTwoTone, PhoneAndroid, TwoWheelerTwoTone, VisibilityTwoTone } from '@mui/icons-material'
import { BsPlusLg } from 'react-icons/bs'
import VehiclesServices from 'app/apis/Vehicles/VehiclesServices'
import Layout from 'app/layout/Layout'
import AddVehicleTypeDialog from 'app/components/Dialog/VehicleDialogs/VehicleTypeDialog'
import colors from 'app/styles/colors'
import { ErrorToaster, SuccessToaster } from 'app/components/Toaster/Toaster'
import AddVehicleColorDialog from 'app/components/Dialog/VehicleDialogs/VehicleColorDialog'
import AddVehicleMakeDialog from 'app/components/Dialog/VehicleDialogs/VehicleMakeDialog'
import AddVehicleModelDialog from 'app/components/Dialog/VehicleDialogs/VehicleModelDialog'
import BreadCrumbs from 'app/components/Breadcrumbs/Breadcrumbs'
import { Loading } from 'app/assets/Images'
import { useTheme } from '@emotion/react'
import { styled } from '@mui/styles'
import SimpleDialog from 'app/components/Dialog/ValidationDialog'
import { useNavigate, useParams } from 'react-router-dom'
import BookingsServices from 'app/apis/Bookings/BookingsServices'
import { StatusChip } from 'app/components/UI/Chip'
import { ImageAvatar } from 'app/components/Avatar/ImageAvatar'
import { getDate, minuteIntoHours } from 'app/utils'
import PassengerDialog from 'app/components/Dialog/PassengerDialog'
import { GoogleMap, MarkerF, useJsApiLoader } from '@react-google-maps/api'
import { SecondaryButton } from 'app/components/Buttons/Button';

// Table Pagination ActionsComponent
function TablePaginationActions(props) {

  const theme = useTheme

  const { count, page, rowsPerPage, onPageChange } = props;

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };



  useEffect(() => {
    window.scrollTo({ top: 0 })
  }, [])


  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
    </Box>
  );
}

// custom tooltip
const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 200,
    textAlign: "center",
    background: "#0D253C",
    "&>.MuiTooltip-arrow": {
      "&:before": {
        color: "#0D253C"
      }
    }
  },
});

const googleApi = "AIzaSyCk9wRPeLZuSkgyBsJVAqQSUz59VAj-CYg"

const libraries = ['places']

// Geocode.setApiKey(googleApi);
// Geocode.setRegion("pk");

function BookingDetail() {

  const navigate = useNavigate()
  const { id } = useParams()

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: googleApi,
    libraries: libraries,
  });

  const tableHead = ['ID', 'Name', 'Image', 'Email', 'Phone', 'Gender']

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  // *For Loading
  const [loading, setLoading] = useState(true);

  // *For Booking Detail
  const [bookingDetail, setBookingDetail] = useState([]);
  const [allCoords, setAllCords] = useState("")

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // *For Get Booking Detail
  const getBookingDetails = async () => {
    setLoading(true)
    try {
      const { status, responseCode, data } = await BookingsServices.getBookingDetails(id)
      if (status === true && responseCode === 200) {
        setBookingDetail(data)
        let address = []
        data.passengers.map((item) => {
          let coords = `${item?.request.latitude},${item?.request.longitude}`
          address.push(coords)
        })
        setAllCords(address)
      }
    } catch (error) {
      ErrorToaster(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getBookingDetails()
  }, [id])

  return (
    <Fragment>

      <Box sx={{ backgroundColor: colors.lightGray, p: 3, borderRadius: "12px", width: "calc(100vw - 370px)" }}>
        <Grid container
          alignItems="center"
          justifyContent="space-between"
          sx={{
            bgcolor: colors.white, p: 2, borderRadius: "12px",
          }}>
          <Grid item xs={6}>
            <Typography variant="body1" fontWeight="bold" color="initial">Booking Detail</Typography>
          </Grid>
          <Grid item xs={6} sx={{ display: "flex", justifyContent: "flex-end" }}>
            <BreadCrumbs currentPage={['Booking', 'Booking Detail']} />
          </Grid>
        </Grid>
        {loading ? (
          <Box sx={{ width: 1, textAlign: "center", pt: 5 }}>
            <Loading />
          </Box>
        ) : (
          <Fragment>

            <Box sx={{ backgroundColor: colors.white, p: 2, my: 2, borderRadius: "12px", display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant="body1" >Booking ID: <b>{bookingDetail?.id}</b></Typography>
              <Typography variant="body1" >Created at: <b>{getDate(bookingDetail?.createdAt)}</b></Typography>
            </Box>

            <Box sx={{ backgroundColor: colors.white, p: 2, my: 2, borderRadius: "12px" }}>
              <Typography variant="body1" fontWeight="bold" >Driver</Typography>
              <Divider flexItem sx={{ my: 1 }} />
              <Box sx={{ my: 2, display: 'flex', alignItems: 'center' }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <ImageAvatar image={bookingDetail?.vehicle?.driver?.picture} />
                  <Typography variant="body1" sx={{ ml: 1 }} >{bookingDetail?.vehicle?.driver?.name}</Typography>
                </Box>
                <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />
                <Typography variant="body1" ><Email fontSize="small" sx={{ color: colors.primary, verticalAlign: 'middle', mr: 1 }} />{bookingDetail?.vehicle?.driver?.email}</Typography>
                <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />
                <Typography variant="body1" ><PhoneAndroid fontSize="small" sx={{ color: colors.primary, verticalAlign: 'middle', mr: 1 }} />{bookingDetail?.vehicle?.driver?.phone}</Typography>
                <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />
                <Typography variant="body1" ><Person fontSize="small" sx={{ color: colors.primary, verticalAlign: 'middle', mr: 1 }} />{bookingDetail?.vehicle?.driver?.gender}</Typography>
              </Box>
            </Box>

            <Box sx={{ backgroundColor: colors.white, p: 2, my: 2, borderRadius: "12px" }}>
              <Typography variant="body1" fontWeight="bold" >Vehicle</Typography>
              <Divider flexItem sx={{ my: 1 }} />
              <Box sx={{ my: 2, display: 'flex', alignItems: 'center' }}>
                <Typography variant="body2" >No. Plate: <b>{bookingDetail?.vehicle?.plate_number}</b></Typography>
                <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />
                <Typography variant="body2" >Color: <b>{bookingDetail?.vehicle?.color}</b></Typography>
                <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />
                <Typography variant="body2" >Transmission: <b>{bookingDetail?.vehicle?.transmission}</b></Typography>
                <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />
                <Typography variant="body2" >Fuel Type: <b>{bookingDetail?.vehicle?.fuel_type}</b></Typography>
              </Box>
            </Box>

            <Box sx={{ backgroundColor: colors.white, p: 2, my: 2, borderRadius: "12px" }}>
              <Typography variant="body1" fontWeight="bold" >Booking Days</Typography>
              <Divider flexItem sx={{ my: 1 }} />
              <Box sx={{ my: 2, display: 'flex', alignItems: 'center' }}>
                {bookingDetail.monday &&
                  <Chip
                    label={`Monday`}
                    sx={{
                      bgcolor: colors.tertiary + 26,
                      color: colors.tertiary,
                      height: 26,
                      textTransform: 'capitalize',
                      cursor: 'pointer',
                      m: 0.5
                    }}
                  />
                }
                {bookingDetail.tuesday &&
                  <Chip
                    label={`Tuesday`}
                    sx={{
                      bgcolor: colors.tertiary + 26,
                      color: colors.tertiary,
                      height: 26,
                      textTransform: 'capitalize',
                      cursor: 'pointer',
                      m: 0.5
                    }}
                  />
                }
                {bookingDetail.wednesday &&
                  <Chip
                    label={`Wednesday`}
                    sx={{
                      bgcolor: colors.tertiary + 26,
                      color: colors.tertiary,
                      height: 26,
                      textTransform: 'capitalize',
                      cursor: 'pointer',
                      m: 0.5
                    }}
                  />
                }
                {bookingDetail.thursday &&
                  <Chip
                    label={`Thursday`}
                    sx={{
                      bgcolor: colors.tertiary + 26,
                      color: colors.tertiary,
                      height: 26,
                      textTransform: 'capitalize',
                      cursor: 'pointer',
                      m: 0.5
                    }}
                  />
                }
                {bookingDetail.friday &&
                  <Chip
                    label={`Friday`}
                    sx={{
                      bgcolor: colors.tertiary + 26,
                      color: colors.tertiary,
                      height: 26,
                      textTransform: 'capitalize',
                      cursor: 'pointer',
                      m: 0.5
                    }}
                  />
                }
                {bookingDetail.saturday &&
                  <Chip
                    label={`Saturday`}
                    sx={{
                      bgcolor: colors.tertiary + 26,
                      color: colors.tertiary,
                      height: 26,
                      textTransform: 'capitalize',
                      cursor: 'pointer',
                      m: 0.5
                    }}
                  />
                }
                {bookingDetail.sunday &&
                  <Chip
                    label={`Sunday`}
                    sx={{
                      bgcolor: colors.tertiary + 26,
                      color: colors.tertiary,
                      height: 26,
                      textTransform: 'capitalize',
                      cursor: 'pointer',
                      my: 0.5
                    }}
                  />
                }
              </Box>
            </Box>

            <Box sx={{ backgroundColor: colors.white, p: 2, my: 2, borderRadius: "12px" }}>
              <Typography variant="body1" fontWeight="bold" >Location</Typography>
              <Divider flexItem sx={{ my: 1 }} />
              <Grid container spacing={2} justifyContent="space-between">
                <Grid item md={5}>
                  <Box sx={{ my: 2 }}>
                    <Typography variant='body2' noWrap sx={{ mb: 0.5 }}>
                      <b>Start Time: </b>
                      {minuteIntoHours(bookingDetail?.start_time) ?? '--'}
                    </Typography>
                    <Typography variant='body2' noWrap sx={{ mb: 0.5 }}>
                      <b>End Time: </b>
                      {minuteIntoHours(bookingDetail?.return_time) ?? '--'}
                    </Typography>
                    <Typography variant='body2' sx={{ mb: 0.5 }}>
                      <b>Address: </b>
                      {bookingDetail?.address ?? '--'}
                    </Typography>
                  </Box>
                  <SecondaryButton title={"View Routes"}
                    onClick={() => window.open(`https://www.google.com/maps/dir/${bookingDetail.latitude},${bookingDetail.longitude}/${allCoords.join('/')}/${bookingDetail?.company_address?.latitude},${bookingDetail?.company_address?.longitude}/`, "_blank")} />
                </Grid>
                {/* <Grid item md={7}>
                  <GoogleMap
                    mapContainerStyle={{
                      width: '100%',
                      height: '250px'
                    }}
                    center={{
                      lat: 24.945249,
                      lng: 67.048790
                    }}
                    zoom={15}
                    options={{
                      fullscreenControl: false,
                      streetViewControl: false,
                      mapTypeControl: false,
                      scrollwheel: false,
                      styles: {
                        width: '100%',
                        height: '250px'
                      },
                    }}
                  >
                    <MarkerF
                      position={{
                        lat: 24.945249,
                        lng: 67.048790
                      }}
                    />
                  </GoogleMap>
                </Grid> */}
              </Grid>
            </Box>

            <Box sx={{ backgroundColor: colors.white, p: 2, my: 2, borderRadius: "12px", }}>
              <Typography variant="body1" fontWeight="bold" >Passengers</Typography>
              <Divider flexItem sx={{ my: 1 }} />
              <TableContainer component={Paper}
                sx={{
                  '&::-webkit-scrollbar': {
                    bgcolor: colors.white,
                    height: "8px",
                    borderRadius: "10px",
                    cursor: "pointer"
                  },
                  '&::-webkit-scrollbar-thumb': {
                    bgcolor: colors.primary,
                    borderRadius: "10px",
                    cursor: "pointer"
                  },
                  "&.MuiPaper-root": {
                    borderRadius: "12px",
                    mt: 2,
                    boxShadow: 'rgb(32 40 45 / 8%) 0px 2px 14px 0px',
                  }
                }}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead >
                    <TableRow >
                      {tableHead.map((item, index) => (
                        <TableCell key={index} sx={{ fontWeight: "bold", whiteSpace: 'nowrap' }}>{item}</TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(rowsPerPage > 0 ? bookingDetail?.passengers?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : bookingDetail?.passengers).map((row, index) => (
                      <TableRow key={index}>
                        <TableCell sx={{ textAlign: 'center' }}>
                          {row.passenger_id}
                        </TableCell>
                        <TableCell sx={{ width: '250px' }}>
                          {row?.user?.name ?? '--'}
                        </TableCell>
                        <TableCell >
                          <ImageAvatar image={row?.user?.picture} />
                        </TableCell>
                        <TableCell>
                          {row?.user?.email ?? '--'}
                        </TableCell>
                        <TableCell>
                          {row?.user?.phone}
                        </TableCell>
                        <TableCell>
                          {row?.user?.gender}
                        </TableCell>
                      </TableRow>
                    ))}

                  </TableBody>
                  {/* <TableFooter>
                    <TableRow>
                      <TablePagination
                        rowsPerPageOptions={[10, 25, 50]}
                        colSpan={15}
                        count={bookingDetail?.passengers?.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        SelectProps={{
                          inputProps: {
                            'aria-label': 'rows per page',
                          },
                          native: true,
                        }}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                      />
                    </TableRow>
                  </TableFooter> */}
                </Table>
              </TableContainer>
            </Box>

          </Fragment>
        )}

      </Box>
    </Fragment>
  )
}

export default BookingDetail