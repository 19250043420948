import {
  ForumTwoTone,
  SettingsApplicationsTwoTone,
  DirectionsCarTwoTone,
  CarRepairTwoTone,
  GroupTwoTone,
  CommuteTwoTone,
  AssignmentTurnedInTwoTone,
  DisplaySettings,
  DynamicForm,
  Menu,
  PermIdentity,
  Settings,
  HelpCenter,
  LocalGasStation,
  ArticleTwoTone,
} from "@mui/icons-material";
import { BsSpeedometer2 } from "react-icons/bs";

const Navigation = [
  {
    label: "Dashboard",
    icon: <BsSpeedometer2 />,
    type: "",
    path: "/",
  },
  {
    label: "Company Settings",
    icon: <Settings />,
    type: "company",
    path: "/account-settings",
  },
  {
    label: "Company Main Menu",
    icon: <Menu />,
    type: "company", // must have to enter type here for role "company" or "admin"
    childPath: ["/company-details", "/employees-details"], // must have to enter childpath here if item has children
    children: [
      {
        label: "Details",
        icon: <DisplaySettings />,
        path: "/company-details",
      },
      {
        label: "Employees",
        icon: <PermIdentity />,
        path: "/employees-details",
      },
    ],
  },
  {
    label: "Companies",
    icon: <DynamicForm />,
    path: "/companies",
    type: "admin", // must have to enter type here for role  "company" or "admin"
  },
  {
    label: "Vehicles",
    icon: <DirectionsCarTwoTone />,
    path: "/vehicles",
    type: "admin", // must have to enter type here for role "company" or "admin"
  },
  {
    label: "Vehicle Management",
    icon: <CarRepairTwoTone />,
    path: "/vehicle-management",
    type: "admin", // must have to enter type here for role "company" or "admin"
  },
  {
    label: "Bookings",
    icon: <AssignmentTurnedInTwoTone />,
    path: "/bookings",
    type: "admin", // must have to enter type here for role "company" or "admin"
  },
  {
    label: "Daily Trips",
    icon: <CommuteTwoTone />,
    path: "/daily-trips",
    type: "admin", // must have to enter type here for role "company" or "admin"
  },
  {
    label: "Passengers",
    icon: <GroupTwoTone />,
    path: "/passengers",
    type: "admin", // must have to enter type here for role "company" or "admin"
  },
  {
    label: "Employees",
    type: "admin",
    icon: <PermIdentity />,
    path: "/employees-details",
  },
  {
    label: "Pricing Configuration",
    type: "admin",
    icon: <SettingsApplicationsTwoTone />,
    childPath: ["/pricing", "/fueltype"], // must have to enter childpath here if item has children
    children: [
      // {
      //   label: "Price Settings",
      //   icon: <DisplaySettings />,
      //   path: "/pricing",
      // },
      {
        label: "Fuel Type",
        icon: <LocalGasStation />,
        path: "/fueltype",
      },
    ],
  },
  {
    label: "Support Ticket",
    icon: <HelpCenter />,
    path: "/support-ticket",
    type: "admin", // must have to enter type here for role "company" or "admin"
  },
  {
    label: "Chat Support",
    icon: <ForumTwoTone />,
    path: "/chat-support",
    type: "admin", // must have to enter type here for role "company" or "admin"
  },
  {
    label: "Co2 Emissions Report",
    icon: <ArticleTwoTone />,
    path: "/co2-emissions-report",
    type: "company", // must have to enter type here for role "company" or "admin"
  },
];
export default Navigation;
