import { get, post, patch, deleted } from 'app/apis';
import SupportTicketsRoutes from './SupportTickets.Routes';

const SupportTicketServices = {
  getTickets: async () => {
    const data = await get(SupportTicketsRoutes.getTickets);
    return data;
  },
  getTicketDetails: async (id) => {
    const data = await get(SupportTicketsRoutes.getTicketDetails + `?ticket_id=${id}`);
    return data;
  },
  sendTicket: async (obj) => {
    const data = await post(SupportTicketsRoutes.sendTicket, obj);
    return data;
  },
  updateTicketStatus: async (obj) => {
    const data = await patch(SupportTicketsRoutes.updateTicketStatus, obj)
    return data;
  },
  createTicket: async (obj) => {
    const data = await post(SupportTicketsRoutes.createTicket, obj)
    return data;
  },
  getTicketType: async () => {
    const data = await get(SupportTicketsRoutes.getTicketType)
    return data;
  },
}

export default SupportTicketServices