import React, { useEffect } from 'react'
import { Dialog, DialogContent, DialogTitle, Divider, Typography, IconButton } from '@mui/material';
import { useForm } from 'react-hook-form';
import { CloseRounded } from '@mui/icons-material';
import { InputField } from 'app/components/UI/TextField';
import { ErrorToaster } from 'app/components/Toaster/Toaster';
import { PrimaryButton } from 'app/components/Buttons/Button';

function VehicleTypeDialog({ open, close, add, update, loading, updateData }) {

  const { register, handleSubmit, formState: { errors }, setValue, reset } = useForm();

  // *For Submit Form
  const submitForm = async (formData) => {
    try {
      let obj = {
        type: formData.vehicleType,
        max_seats: formData.maxSeats,
      }
      if (updateData) {
        obj.id = updateData.id
        update(obj)
      } else {
        add(obj)
      }
    } catch (error) {
      ErrorToaster(error)
    }
  }

  useEffect(() => {
    if (updateData) {
      setValue("vehicleType", updateData.type)
      setValue("maxSeats", updateData.max_seats)
    }
  }, [updateData])

  return (
    <Dialog open={open} fullWidth sx={{ '& .MuiDialog-paper': { borderRadius: "12px" } }}>
      <DialogTitle sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        Vehicle Type
        <IconButton onClick={() => { close(); reset() }}>
          <CloseRounded />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent >
        <Typography component={"form"} onSubmit={handleSubmit(submitForm)}>
          <InputField
            label="Vehicle Type"
            error={errors?.vehicleType?.message}
            register={register("vehicleType", {
              required: 'Please enter vehicle type.'
            })}
          />
          <InputField
            type="number"
            label="Max Seats"
            error={errors?.maxSeats?.message}
            register={register("maxSeats", {
              required: 'Please enter max seats.'
            })}
          />
          <PrimaryButton
            data-testId="submit-btn"
            title="Submit"
            type="submit"
            loading={loading}
            fullWidth={true}
          />
        </Typography>
      </DialogContent>
    </Dialog>
  )
}

export default VehicleTypeDialog