import React, { Fragment, useEffect } from 'react'
import { Box, Grid, Typography } from '@mui/material';
import colors from 'app/styles/colors';
import BreadCrumbs from 'app/components/Breadcrumbs/Breadcrumbs';
//* Send Bird
import SendbirdApp from "@sendbird/uikit-react/App";
import SendbirdChat from '@sendbird/chat';
import "@sendbird/uikit-react/dist/index.css";
import { GroupChannelModule } from '@sendbird/chat/groupChannel';
import GroupChannelHandler from '@sendbird/uikit-react/handlers/GroupChannelHandler';
import useAuth from 'app/hooks/useAuth';
import { getUserToken } from 'app/firebase/firebase';
import useNotification from 'app/hooks/useNotification';

const myColorSet = {
  '--sendbird-light-primary-500': '#0cba70',
  '--sendbird-light-primary-400': '#f6f6f6',
  '--sendbird-light-primary-300': '#0cba70',
  '--sendbird-light-primary-200': 'rgb(0, 200, 83)',
  '--sendbird-light-primary-100': '#d5f7e9',
};

function ChatSupport() {
  const { setNotifyMessage, notifyMessage } = useNotification()
  const messageNotification = async () => {
    try {
      const sb = await SendbirdChat.init({
        appId: 'FC455F61-2850-438B-964F-BFC3C7E8F07C',
        localCacheEnabled: true,
        modules: [new GroupChannelModule()],
      });
      // const token = getUserToken();
      const user = await sb.connect("0100");
      const channelHandler = new GroupChannelHandler({
        onMessageReceived: (channel, message) => {
          if (message) {
            setNotifyMessage(true)
            console.log("🚀 ~ file: ChatSupports.js:34 ~ messageNotification ~ message:", message)
          }
        },
      });

      sb.groupChannel.addGroupChannelHandler("0100", channelHandler);
      console.log("======> Code Run <===========")
    } catch (error) {
      console.log("🚀 ~ file: Login.js:86 ~ onLogin ~ error:", error)
    }
  }

  useEffect(() => {
    messageNotification()
    setNotifyMessage(false)
  }, [notifyMessage])

  return (
    <Fragment>
      <Box sx={{
        backgroundColor: colors.lightGray, p: 3, borderRadius: "12px", width: "calc(100vw - 370px)"
      }}>
        <Grid container alignItems="center" justifyContent="space-between"
          sx={{
            bgcolor: colors.white, p: 2, borderRadius: "12px",
          }}
        >
          <Grid item xs={6}>
            <Typography variant="body1" fontWeight="bold" color="initial">Chat Support</Typography>
          </Grid>
          <Grid item xs={6} sx={{ display: "flex", justifyContent: "flex-end" }}>
            <BreadCrumbs currentPage={['Chat Support']} />
          </Grid>
        </Grid>
        <Box sx={{ display: "flex", justifyContent: "flex-end", bgcolor: colors.white, p: 1, borderRadius: "12px", mt: 2 }}>
          <Box sx={{ height: "500px", width: '100vw' }}>
            <SendbirdApp
              appId={"FC455F61-2850-438B-964F-BFC3C7E8F07C"}
              userId={"0100"}
              nickname={"Takseem"}
              colorSet={myColorSet}
              showSearchIcon={false}
              isReactionEnabled={false}
              disableMarkAsDelivered={false}
              isTypingIndicatorEnabledOnChannelList={true}
              isMessageReceiptStatusEnabledOnChannelList={false}
              useMessageGrouping={false}
            // userListQuery={null}
            // disableUserProfile={true}
            />
          </Box>
        </Box>
      </Box>
    </Fragment>
  )
}

export default ChatSupport