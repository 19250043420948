const PricingConfigRoutes = {
  getPricing: "companies/pricing",
  updatePricing: "companies/pricing",
  getFuelType: "companies/fueltype",
  createFuelType: "companies/fueltype/create",
  updateFuelType: "companies/fueltype/update",
  deleteFuelType: "companies/fueltype/delete",
  updateOilPrice: "companies/oilprice/update",
};

export default PricingConfigRoutes;
