export const empStrengthRange = [
  "11-50", "50-200", "200-500", "500-1000", "1000+"
]

export const cities = [
  { id: 1, name: "Karachi" },
  { id: 2, name: "Lahore" },
]

export const roles = ["Procurement", "HR", "Finance", "Billing", "Marketing"]


export const vehicleType = [
  "Bike", "Car", "Van", "Bus"
]

// map styling
export const customMapStyle = [
  {
    "featureType": "all",
    "elementType": "all",
    "stylers": [
      {
        "hue": "#0cba70"
      }
    ]
  },
  {
    "featureType": "administrative.locality",
    "elementType": "geometry",
    "stylers": [
      {
        "visibility": "on"
      }
    ]
  },
  {
    "featureType": "administrative.neighborhood",
    "elementType": "geometry",
    "stylers": [
      {
        "visibility": "on"
      }
    ]
  },
  {
    "featureType": "landscape.man_made",
    "elementType": "geometry",
    "stylers": [
      {
        "visibility": "on"
      }
    ]
  },
  {
    "featureType": "landscape.natural.terrain",
    "elementType": "geometry",
    "stylers": [
      {
        "visibility": "on"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "all",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "geometry",
    "stylers": [
      {
        "visibility": "on"
      },
      {
        "hue": "#0cba70"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "labels",
    "stylers": [
      {
        "visibility": "on"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#0cba70"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "labels.icon",
    "stylers": [
      {
        "visibility": "simplified"
      },
      {
        "weight": "1.21"
      }
    ]
  },
  {
    "featureType": "poi.business",
    "elementType": "labels.icon",
    "stylers": [
      {
        "weight": "0.74"
      },
      {
        "gamma": "1.00"
      },
      {
        "lightness": "0"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "geometry",
    "stylers": [
      {
        "visibility": "on"
      },
      {
        "hue": "#0cba70"
      },
      {
        "saturation": "-42"
      },
      {
        "lightness": "-17"
      },
      {
        "gamma": "2.37"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "all",
    "stylers": [
      {
        "saturation": -70
      }
    ]
  },
  {
    "featureType": "transit",
    "elementType": "geometry",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "transit.line",
    "elementType": "geometry",
    "stylers": [
      {
        "visibility": "on"
      }
    ]
  },
  {
    "featureType": "transit.station",
    "elementType": "geometry",
    "stylers": [
      {
        "saturation": "-62"
      },
      {
        "lightness": "-48"
      },
      {
        "gamma": "0.00"
      }
    ]
  },
  {
    "featureType": "transit.station",
    "elementType": "labels.text",
    "stylers": [
      {
        "visibility": "on"
      }
    ]
  },
  {
    "featureType": "transit.station",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "visibility": "on"
      }
    ]
  },
  {
    "featureType": "transit.station",
    "elementType": "labels.text.stroke",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "transit.station",
    "elementType": "labels.icon",
    "stylers": [
      {
        "visibility": "on"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "all",
    "stylers": [
      {
        "visibility": "simplified"
      },
      {
        "saturation": -60
      }
    ]
  }
]

export const industryTypes = [
  "Accounting",
  "Advertisement",
  "AutoMobile",
  "Architecture",
  "Banking",
  "Broadcaster",
  "Clothing",
  "Communications ",
  "Digital Media ",
  "Distribution and Logistics",
  "Entertainment",
  "Electronics",
  "Event Management",
  "Garments",
  "Health & Fitness",
  "Hospitality",
  "Beverage",
  "Interior Designer"
]
