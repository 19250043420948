import React, { useState } from "react";
import Layout from "app/layout/Layout";
import { Box, Grid, Table, TableContainer, TableHead, TableRow, Typography, TableCell, Paper, TableBody, IconButton, Tooltip, Chip, TablePagination } from "@mui/material";
import colors from "app/styles/colors";
import BreadCrumbs from "app/components/Breadcrumbs/Breadcrumbs";
import { Loading } from "app/assets/Images";
import { Fragment, useEffect } from "react";
import { PersonAddAltTwoTone, ViewCozyTwoTone, KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import AddNewTicket from "app/components/Dialog/AddNewTicket";
import { useNavigate } from "react-router-dom";
import { ErrorToaster } from "app/components/Toaster/Toaster";
import { getDate, getTime } from "./../../utils/index";
import SupportTicketServices from "app/apis/SupportTickets/SupportTicketsServices";
import { useTheme } from "@emotion/react";

// Table Pagination ActionsComponent
function TablePaginationActions(props) {
  const theme = useTheme;

  const { count, page, rowsPerPage, onPageChange } = props;

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="next page">
        {theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
    </Box>
  );
}

function SupportTicket() {
  const navigate = useNavigate();
  //* For Pagination
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  //* For Loader
  const [loaderStatus, setLoaderStatus] = useState(false);
  //* For Creating New Ticket
  const [addTicketDialog, setAddTicketDialog] = useState(false);
  const [ticketData, setTicketData] = useState([]);

  //* Api For Get All Tickets
  const getTickets = async () => {
    try {
      setLoaderStatus(true);
      const { status, responseCode, data } = await SupportTicketServices.getTickets();
      if (status === true && responseCode === 200) {
        setTicketData(data.rows);
      }
    } catch (error) {
      ErrorToaster(error);
    } finally {
      setLoaderStatus(false);
    }
  };
  //* Api For Get All Ticket Types
  const [ticketType, setTicketType] = useState([]);
  const getTicketType = async () => {
    try {
      setLoaderStatus(true);
      const { status, responseCode, data } = await SupportTicketServices.getTicketType();
      if (status === true && responseCode === 200) {
        setTicketType(data);
      }
    } catch (error) {
      ErrorToaster(error);
    } finally {
      setLoaderStatus(false);
    }
  };

  useEffect(() => {
    getTicketType();
    getTickets();
  }, []);
  return (
    <Fragment>
      <AddNewTicket
        open={addTicketDialog}
        handleClose={() => setAddTicketDialog(!addTicketDialog)}
        toggleFunction={() => setAddTicketDialog(false)}
        getTickets={() => getTickets()}
        ticketType={ticketType}
      />
      <Box
        sx={{
          backgroundColor: colors.lightGray,
          p: 3,
          borderRadius: "12px",
          width: "calc(100vw - 370px)",
        }}
      >
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          sx={{
            bgcolor: colors.white,
            p: 2,
            borderRadius: "12px",
          }}
        >
          <Grid item xs={6}>
            <Typography variant="body1" fontWeight="bold" color="initial">
              Support Ticket
            </Typography>
          </Grid>
          <Grid item xs={6} sx={{ display: "flex", justifyContent: "flex-end" }}>
            <BreadCrumbs currentPage={["Support Ticket"]} />
          </Grid>
        </Grid>
        {loaderStatus ? (
          <Box sx={{ width: 1, textAlign: "center", pt: 5 }}>
            <Loading />
          </Box>
        ) : (
          <Fragment>
            <Box sx={{ display: "flex", justifyContent: "flex-end", bgcolor: colors.white, p: 1, borderRadius: "12px", textAlign: "right", mt: 2 }}>
              <Box
                onClick={() => setAddTicketDialog(!addTicketDialog)}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  borderRadius: 1,
                  p: 1,
                  cursor: "pointer",
                  color: colors.primary,
                  bgcolor: colors.primaryLight,
                }}
              >
                <PersonAddAltTwoTone sx={{ color: colors.primary, mr: 1, fontSize: "20px" }} />
                <Typography sx={{ fontSize: "12px" }}> Add New Ticket</Typography>
              </Box>
            </Box>
            <TableContainer
              component={Paper}
              sx={{
                "&::-webkit-scrollbar": {
                  bgcolor: colors.white,
                  height: "8px",
                  borderRadius: "10px",
                  cursor: "pointer",
                },
                "&::-webkit-scrollbar-thumb": {
                  bgcolor: colors.primary,
                  borderRadius: "10px",
                  cursor: "pointer",
                },
                "&.MuiPaper-root": {
                  borderRadius: "12px",
                  mt: 2,
                  boxShadow: 0,
                },
              }}
            >
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow sx={{ bgcolor: colors.lightGray }}>
                    <TableCell sx={{ fontWeight: "bold" }}>Ticket ID</TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>Subject</TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>Message</TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>Status</TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>Priority</TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>Created Time</TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>Last Update</TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>View</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(rowsPerPage > 0 ? ticketData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : ticketData).map((item, index) => (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">
                        {item?.logs[0]?.support_ticket_id}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {item?.subject}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Tooltip title={item?.messages[0]?.message}>
                          <Typography
                            sx={{
                              textOverflow: "ellipsis",
                              fontSize: "15px",
                              minWidth: "300px",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              maxWidth: "350px",
                            }}
                          >
                            {item?.messages[0]?.message}
                          </Typography>
                        </Tooltip>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Chip
                          size="small"
                          color={item?.status === "pending" ? "warning" : item?.status === "resolved" ? "primary" : item?.status === "closed" ? "error" : "info"}
                          label={item?.status}
                          variant="outlined"
                          sx={{
                            color: item?.status === "pending" ? colors.pending : item?.status === "resolved" ? colors.primary : item?.status === "closed" ? colors.danger : colors.bondiBlueLight,
                            width: "90px",
                            fontSize: "12px",
                          }}
                        />
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Chip
                          size="small"
                          color={item?.priority === "medium" ? "warning" : item?.priority === "low" ? "info" : "error"}
                          label={item?.priority}
                          variant="outlined"
                          sx={{
                            color: item?.priority === "low" ? colors.bondiBlue : item?.priority === "high" ? colors.danger : colors.pending,
                            width: "90px",
                            fontSize: "12px",
                          }}
                        />
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {getDate(item?.createdAt)} {getTime(item?.createdAt)}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {getDate(item?.logs[item?.logs.length - 1]?.createdAt)} {getTime(item?.logs[item?.logs.length - 1]?.createdAt)}
                      </TableCell>
                      <TableCell>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Tooltip title="View">
                            <IconButton onClick={() => navigate(`/ticket-detail/${item.id}`)}>
                              <ViewCozyTwoTone color="secondary" sx={{ fontSize: "20px" }} />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Table sx={{ marginTop: "12px" }}>
              <TablePagination
                sx={{ borderBottom: "none", bgcolor: colors.white, borderRadius: "12px" }}
                rowsPerPageOptions={[10, 20, 30]}
                colSpan={12}
                count={ticketData?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    "aria-label": "rows per page",
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </Table>
          </Fragment>
        )}
      </Box>
    </Fragment>
  );
}

export default SupportTicket;
