import { Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@mui/material'
import React from 'react'
import { CloseRounded, } from '@mui/icons-material';
import { Divider, TextField, FormControl, Box } from '@mui/material';
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import 'swiper/css';
import "swiper/css/navigation";
//* import required modules
import { Navigation } from "swiper";
import colors from 'app/styles/colors';

function VehicleDocument({ open, handleClose, vehicleDocs }) {
  console.log("🚀 ~ file: VehicleDocument.js:14 ~ VehicleDocument ~ vehicleDocs:", vehicleDocs)

  return (
    <Dialog open={open}
      fullWidth
      onClose={() => { handleClose(); }}
      sx={{ '& .MuiDialog-paper': { borderRadius: "12px" } }}
    >
      <DialogTitle sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        Vehicle Documents
        <IconButton onClick={() => { handleClose(); }}>
          <CloseRounded />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={12}>
            {!vehicleDocs.length ? <Typography>No Docs Uploaded</Typography> :
              <Swiper navigation={true} modules={[Navigation]} >
                {vehicleDocs?.map((item, index) => (
                  <SwiperSlide key={index} style={{}}>
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      {item.document_identifier === "drivingLicense" ?
                        <Typography sx={{ fontWeight: "bold", color: colors.primaryDark }}>DRIVING LICENSE</Typography> :
                        item.document_identifier === "registrationPapers" ?
                          <Typography sx={{ fontWeight: "bold", color: colors.primaryDark }}>REGISTRATION PAPER</Typography> :
                          <Typography sx={{ fontWeight: "bold", color: colors.primaryDark }}>INSURANCE PAPER</Typography>
                      }
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <img src={item.document_url} alt="Document" loading="lazy" style={{ width: "430px", height: "340px", borderRadius: "10px", objectFit: "cover" }} />
                    </Box>
                  </SwiperSlide>
                ))}
              </Swiper>
            }
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

export default VehicleDocument