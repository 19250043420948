import { Box, Divider, FormControl, Grid, TextField, Typography } from "@mui/material";
import PricingServices from "app/apis/PricingConfig/PricingServices";
import { Loading } from "app/assets/Images";
import BreadCrumbs from "app/components/Breadcrumbs/Breadcrumbs";
import { PrimaryButton, SecondaryButton } from "app/components/Buttons/Button";
import { ErrorToaster, SuccessToaster } from "app/components/Toaster/Toaster";
import SelectField from "app/components/UI/SelectFields";
import { InputField } from "app/components/UI/TextField";
import colors from "app/styles/colors";
import React, { Fragment } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

function PricingConfig() {
  const navigate = useNavigate();
  const [enableBtn, setEnableBtn] = useState(false);
  const [country, setCountry] = useState("Pakistan");

  //* Auto Calculate Cost Of Parts
  const [costOfParts, setCostOfParts] = useState("");
  const [value1, setValue1] = useState("");
  const updateResult = (val) => {
    if (val !== 0) {
      setCostOfParts(parseFloat(val / 20000).toFixed(3));
    } else {
      setCostOfParts("");
    }
  };
  //

  //* Auto Calculate Cost of Engine Oil
  const [oilLiter, setOilLiter] = useState("");
  const [oilPrice, setOilPrice] = useState("");
  const [costOfOil, setCostOfOil] = useState("");

  const oilInLiter = (val) => {
    setOilLiter(val);
    calculate(val, oilPrice ? oilPrice : pricing?.oil_required_ltr);
  };
  const oilInPrice = (val) => {
    setOilPrice(val);
    calculate(oilLiter ? oilLiter : pricing?.oil_cost, val);
  };

  const calculate = (val1, val2) => {
    if (val2 !== 0) {
      setCostOfOil(parseFloat(val1 / val2).toFixed(3));
    } else {
      setCostOfOil(parseFloat(pricing?.oil_cost / parseFloat(val2)).toFixed(3));
    }
  };
  //
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const setConfig = async (formData) => {
    try {
      let obj = {
        id: country === "Dubai" ? 2 : 1,
        country_id: country === "Dubai" ? 1 : 2,
        cost_of_fuel: formData.fuel,
        tyre_life_km: formData.tyreLife,
        tyre_cost_avg: formData.tyre,
        oil_required_ltr: formData.oilLiter,
        oil_cost: formData.oilPrice,
        oil_cost_per_km: costOfOil ? costOfOil : pricing.oil_cost_per_km,
        other_cost: formData.otherService,
        other_cost_per_km: costOfParts ? costOfParts : pricing?.cost_per_km,
        avg_speed: formData.avg,
        ghg_petrol: formData.petrol,
        ghg_diesel: formData.diesel,
      };
      const { data, message } = await PricingServices.updatePricing(obj);
      if (data === null) return;
      SuccessToaster(message);
      getPricing();
    } catch (error) {
      ErrorToaster(error);
    }
  };

  const [pricing, setPricing] = useState([]);
  const [dubaiPrice, setDubaiPrice] = useState([]);
  const [loading, setLoading] = useState(true);

  const formValues = () => {
    if (country !== "Dubai") {
      setValue("fuel", dubaiPrice?.cost_of_fuel);
      setValue("tyre", dubaiPrice?.tyre_cost_avg);
      setValue("tyreLife", dubaiPrice?.tyre_life_km);
      setValue("oilLiter", dubaiPrice?.oil_required_ltr);
      setValue("oilPrice", dubaiPrice?.oil_cost);
      setValue("otherService", dubaiPrice.other_cost);
      setValue("avg", dubaiPrice.avg_speed);
      setValue("diesel", dubaiPrice.ghg_diesel);
      setValue("petrol", dubaiPrice.ghg_petrol);
    } else {
      setValue("fuel", pricing?.cost_of_fuel);
      setValue("tyre", pricing?.tyre_cost_avg);
      setValue("tyreLife", pricing?.tyre_life_km);
      setValue("oilLiter", pricing?.oil_required_ltr);
      setValue("oilPrice", pricing?.oil_cost);
      setValue("otherService", pricing.other_cost);
      setValue("avg", pricing.avg_speed);
      setValue("diesel", pricing.ghg_diesel);
      setValue("petrol", pricing.ghg_petrol);
    }
  };

  // *For Get Pricing
  const getPricing = async () => {
    try {
      const { status, responseCode, data } = await PricingServices.getPricing();
      if (status === true && responseCode === 200) {
        setPricing(data[0]);
        setDubaiPrice(data[1]);
      }
    } catch (error) {
      ErrorToaster(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPricing();
  }, []);

  return (
    <Fragment>
      <Box
        sx={{
          backgroundColor: colors.lightGray,
          p: 3,
          borderRadius: "12px",
          width: "calc(100vw - 370px)",
        }}
      >
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          sx={{
            bgcolor: colors.white,
            p: 2,
            borderRadius: "12px",
          }}
        >
          <Grid item xs={6}>
            <Typography variant="body1" fontWeight="bold" color="initial">
              Pricing Configuration
            </Typography>
          </Grid>
          <Grid item xs={6} sx={{ display: "flex", justifyContent: "flex-end" }}>
            <BreadCrumbs currentPage={["Pricing Configuration"]} />
          </Grid>
        </Grid>
        {loading ? (
          <Box sx={{ width: 1, textAlign: "center", pt: 5 }}>
            <Loading />
          </Box>
        ) : (
          <Fragment>
            <Grid item xs={12} md={6} sx={{ bgcolor: colors.white, p: 3, borderRadius: "12px", mt: 2 }}>
              <SelectField
                defaultValue={"Pakistan"}
                data={["Pakistan", "Dubai"]}
                label="Select Country"
                stateValue={country}
                setState={(e) => {
                  setCountry(e);
                  formValues();
                }}
              />
            </Grid>
            <Box sx={{ bgcolor: colors.white, p: 3, borderRadius: "12px", mt: 2 }}>
              {country === "Pakistan" ? (
                <Typography component={"form"} onSubmit={handleSubmit(setConfig)}>
                  <Grid container spacing={2} alignItems="center">
                    {/* Cost of Fuel */}
                    <Grid item xs={12} md={6}>
                      <InputField
                        defaultValue={pricing?.cost_of_fuel}
                        label="Cost of Fuel"
                        register={register("fuel", {
                          required: "Please fill all fields",
                          onChange: () => setEnableBtn(true),
                          pattern: {
                            message: "Please fill all fields",
                          },
                        })}
                      />
                    </Grid>
                    {/* Cost of Tyres */}
                    <Grid item xs={12} md={6}>
                      <InputField
                        defaultValue={pricing?.tyre_cost_avg}
                        label="Cost of 4 set of Tyre"
                        error={errors?.tyre?.message}
                        register={register("tyre", {
                          required: "Please fill the field",
                          onChange: () => setEnableBtn(true),
                        })}
                      />
                    </Grid>
                    {/* Tyre Life in KM */}
                    <Grid item xs={12} md={6}>
                      <InputField
                        defaultValue={pricing?.tyre_life_km}
                        label="Tyre Life in KM"
                        error={errors?.tyreLife?.message}
                        register={register("tyreLife", {
                          required: "Please fill the field",
                          onChange: () => setEnableBtn(true),
                        })}
                      />
                    </Grid>
                    {/* Engine Oil in liter */}
                    <Grid item xs={12} md={6}>
                      <InputField
                        defaultValue={pricing?.oil_required_ltr}
                        label="Engine Oil in liter"
                        error={errors?.oilLiter?.message}
                        register={register("oilLiter", {
                          required: "Please fill the field",
                          onChange: (e) => {
                            oilInLiter(e.target.value);
                            setEnableBtn(true);
                          },
                        })}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControl variant="standard" fullWidth>
                        <TextField
                          value={costOfOil ? costOfOil : pricing.oil_cost_per_km}
                          sx={{
                            mb: 2,
                          }}
                          size={"small"}
                          variant="outlined"
                          label="Cost of Engine Oil per KM"
                          disabled
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControl variant="standard" fullWidth>
                        <TextField
                          value={costOfParts ? costOfParts : pricing?.other_cost_per_km}
                          sx={{
                            mb: 2,
                          }}
                          size={"small"}
                          variant="outlined"
                          label="Cost of Parts per KM"
                          disabled
                        />
                      </FormControl>
                    </Grid>
                    {/* Engine Oil Price */}
                    <Grid item xs={12} md={6}>
                      <InputField
                        defaultValue={pricing?.oil_cost}
                        label="Engine Oil Price"
                        error={errors?.oilPrice?.message}
                        register={register("oilPrice", {
                          required: "Please fill the field",
                          onChange: (e) => oilInPrice(e.target.value),
                        })}
                      />
                    </Grid>
                    {/* Parts and Service */}
                    <Grid item xs={12} md={6}>
                      <InputField
                        defaultValue={pricing.other_cost}
                        label="Other Parts and Service"
                        error={errors?.otherService?.message}
                        register={register("otherService", {
                          required: "Please fill the field",
                          onChange: (e) => {
                            setEnableBtn(true);
                            setValue1(e.target.value);
                            updateResult(e.target.value);
                          },
                        })}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <InputField
                        defaultValue={pricing.avg_speed}
                        label="Average Speed"
                        error={errors?.avg?.message}
                        register={register("avg", {
                          required: "Please fill the field",
                          onChange: () => setEnableBtn(true),
                        })}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <InputField
                        defaultValue={pricing.ghg_petrol}
                        label="GHG Petrol"
                        error={errors?.petrol?.message}
                        register={register("petrol", {
                          required: "Please fill the field",
                          onChange: () => setEnableBtn(true),
                        })}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <InputField
                        defaultValue={pricing.ghg_diesel}
                        label="GHG Diesel"
                        error={errors?.diesel?.message}
                        register={register("diesel", {
                          required: "Please fill the field",
                          onChange: () => setEnableBtn(true),
                        })}
                      />
                    </Grid>
                  </Grid>
                  {enableBtn && (
                    <Box
                      sx={{
                        mt: 2,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <SecondaryButton data-testId="back-btn" title="Go Back" onClick={() => navigate(-1)} />

                      <PrimaryButton data-testId="save-btn" title={"Save changes"} type="submit" />
                    </Box>
                  )}
                </Typography>
              ) : (
                <Typography component={"form"} onSubmit={handleSubmit(setConfig)}>
                  <Grid container spacing={2} alignItems="center">
                    {/* Cost of Fuel */}
                    <Grid item xs={12} md={6}>
                      <InputField
                        defaultValue={dubaiPrice?.cost_of_fuel}
                        label="Cost of Fuel"
                        register={register("fuel", {
                          required: "Please fill all fields",
                          onChange: () => setEnableBtn(true),
                          pattern: {
                            message: "Please fill all fields",
                          },
                        })}
                      />
                    </Grid>
                    {/* Cost of Tyres */}
                    <Grid item xs={12} md={6}>
                      <InputField
                        defaultValue={dubaiPrice?.tyre_cost_avg}
                        label="Cost of 4 set of Tyre"
                        error={errors?.tyre?.message}
                        register={register("tyre", {
                          required: "Please fill the field",
                          onChange: () => setEnableBtn(true),
                        })}
                      />
                    </Grid>
                    {/* Tyre Life in KM */}
                    <Grid item xs={12} md={6}>
                      <InputField
                        defaultValue={dubaiPrice?.tyre_life_km}
                        label="Tyre Life in KM"
                        error={errors?.tyreLife?.message}
                        register={register("tyreLife", {
                          required: "Please fill the field",
                          onChange: () => setEnableBtn(true),
                        })}
                      />
                    </Grid>
                    {/* Engine Oil in liter */}
                    <Grid item xs={12} md={6}>
                      <InputField
                        defaultValue={dubaiPrice?.oil_required_ltr}
                        label="Engine Oil in liter"
                        error={errors?.oilLiter?.message}
                        register={register("oilLiter", {
                          required: "Please fill the field",
                          onChange: (e) => {
                            oilInLiter(e.target.value);
                            setEnableBtn(true);
                          },
                        })}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControl variant="standard" fullWidth>
                        <TextField
                          value={costOfOil ? costOfOil : dubaiPrice.oil_cost_per_km}
                          sx={{
                            mb: 2,
                          }}
                          size={"small"}
                          variant="outlined"
                          label="Cost of Engine Oil per KM"
                          disabled
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControl variant="standard" fullWidth>
                        <TextField
                          value={costOfParts ? costOfParts : dubaiPrice?.other_cost_per_km}
                          sx={{
                            mb: 2,
                          }}
                          size={"small"}
                          variant="outlined"
                          label="Cost of Parts per KM"
                          disabled
                        />
                      </FormControl>
                    </Grid>
                    {/* Engine Oil Price */}
                    <Grid item xs={12} md={6}>
                      <InputField
                        defaultValue={dubaiPrice?.oil_cost}
                        label="Engine Oil Price"
                        error={errors?.oilPrice?.message}
                        register={register("oilPrice", {
                          required: "Please fill the field",
                          onChange: (e) => oilInPrice(e.target.value),
                        })}
                      />
                    </Grid>
                    {/* Parts and Service */}
                    <Grid item xs={12} md={6}>
                      <InputField
                        defaultValue={dubaiPrice.other_cost}
                        label="Other Parts and Service"
                        error={errors?.otherService?.message}
                        register={register("otherService", {
                          required: "Please fill the field",
                          onChange: (e) => {
                            setEnableBtn(true);
                            setValue1(e.target.value);
                            updateResult(e.target.value);
                          },
                        })}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <InputField
                        defaultValue={dubaiPrice.avg_speed}
                        label="Average Speed"
                        error={errors?.avg?.message}
                        register={register("avg", {
                          required: "Please fill the field",
                          onChange: () => setEnableBtn(true),
                        })}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <InputField
                        defaultValue={dubaiPrice.ghg_petrol}
                        label="GHG Petrol"
                        error={errors?.petrol?.message}
                        register={register("petrol", {
                          required: "Please fill the field",
                          onChange: () => setEnableBtn(true),
                        })}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <InputField
                        defaultValue={dubaiPrice.ghg_diesel}
                        label="GHG Diesel"
                        error={errors?.diesel?.message}
                        register={register("diesel", {
                          required: "Please fill the field",
                          onChange: () => setEnableBtn(true),
                        })}
                      />
                    </Grid>
                  </Grid>
                  {enableBtn && (
                    <Box
                      sx={{
                        mt: 2,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <SecondaryButton data-testId="back-btn" title="Go Back" onClick={() => navigate(-1)} />

                      <PrimaryButton data-testId="save-btn" title={"Save changes"} type="submit" />
                    </Box>
                  )}
                </Typography>
              )}
            </Box>
          </Fragment>
        )}
      </Box>
    </Fragment>
  );
}

export default PricingConfig;
