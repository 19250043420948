import React, { Fragment, useState } from "react";
import { Box, Chip, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Tooltip, Typography } from "@mui/material";
import { DeleteTwoTone, EditTwoTone } from "@mui/icons-material";
import { BsPlusLg } from "react-icons/bs";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import VehiclesServices from "app/apis/Vehicles/VehiclesServices";
import colors from "app/styles/colors";
import { ErrorToaster, SuccessToaster } from "app/components/Toaster/Toaster";
import { Loading } from "app/assets/Images";
import SimpleDialog from "app/components/Dialog/ValidationDialog";
import VehicleModelDialog from "app/components/Dialog/VehicleDialogs/VehicleModelDialog";
import { useEffect } from "react";
import { useTheme } from "@emotion/react";
import ModelFilter from "app/components/Filters/ModelFilter";

//* Table Pagination ActionsComponent
function TablePaginationActions(props) {
  const theme = useTheme;

  const { count, page, rowsPerPage, onPageChange } = props;

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="next page">
        {theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
    </Box>
  );
}

const tableHeader = ["Make", "Model", "Engine CC", "Fuel Average(km)", "Tyre Cost", "Oil Capacity(Ltr) ", "Maintenance Cost", "Countries", "Action"];
function VehicleModels({ count, getVehicleModels, vehicleModels, vehicleMakes, countryName, loader }) {
  //* For Pagination
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [checkFilter, setCheckFilter] = useState("");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getVehicleModels(newPage + 1, rowsPerPage, checkFilter);
  };

  const handleChangeRowsPerPage = (event) => {
    // setRowsPerPage(parseInt(event.target.value, 2));
    setRowsPerPage(Math.floor(event.target.value));
    setPage(0);
    getVehicleModels(1, event.target.value, checkFilter);
  };

  //* For Filteration
  // *For Get Status ID's ....  updated 25/05/2023
  const sortByStatus = (filterObj) => {
    setRowsPerPage(10);
    setPage(0);
    setCheckFilter(filterObj);
    getVehicleModels(1, 10, filterObj);
  };
  //* For Filteration
  // *For Get Status ID's
  // const sortByStatus = (country, make, search) => {
  //   setCheckCountry(country);
  //   setCheckMake(make);
  //   setCheckSearch(search);
  //   setRowsPerPage(10);
  //   setPage(0);
  //   getVehicleModels(1, 10, country ? country : "", make ? make : "", search ? search : "");
  // };
  // *For Alert Dialog
  const [alertDialog, setAlertDialog] = useState(false);

  // *For Delete
  const [deleteId, setDeleteId] = useState();

  // *For Dialog Box
  const [openDialog, setOpenDialog] = useState(false);

  // *For Update Data
  const [updateData, setUpdateData] = useState();

  // *For Loading
  const [loading, setLoading] = useState(false);

  // *For Add Vehicle Model
  const createVehicleModel = async (obj) => {
    setLoading(true);
    try {
      const { status, responseCode, message } = await VehiclesServices.createVehicleModel(obj);
      if (status === true && responseCode === 200) {
        getVehicleModels();
        SuccessToaster(message);
        onClose();
      }
    } catch (error) {
      ErrorToaster(error);
    } finally {
      setLoading(false);
    }
  };

  // *For Update Vehicle Model
  const updateVehicleModel = async (obj) => {
    setLoading(true);
    try {
      const { status, responseCode, message } = await VehiclesServices.updateVehicleModel(obj);
      if (status === true && responseCode === 200) {
        getVehicleModels(page + 1);
        setCheckFilter("");
        SuccessToaster(message);
        onClose();
      }
    } catch (error) {
      ErrorToaster(error);
    } finally {
      setLoading(false);
    }
  };

  // *For Delete Models
  const deleteVehicleModels = async () => {
    try {
      const { status, responseCode, message } = await VehiclesServices.deleteVehicleModel(deleteId);
      if (status === true && responseCode === 200) {
        getVehicleModels();
        SuccessToaster(message);
        setAlertDialog(false);
      }
    } catch (error) {
      ErrorToaster(error);
    }
  };

  const onClose = () => {
    setUpdateData("");
    setOpenDialog(false);
  };

  return (
    <Fragment>
      {/* ======= Alert Dialog =====  */}
      <SimpleDialog open={alertDialog} onClose={() => setAlertDialog(false)} title={"Are you sure to remove this?"} action={() => deleteVehicleModels()} />

      {/* ========== Vehicle Model Dialog ========== */}
      <VehicleModelDialog
        open={openDialog}
        close={() => onClose()}
        add={(data) => createVehicleModel(data)}
        update={(data) => updateVehicleModel(data)}
        loading={loading}
        updateData={updateData}
        vehicleMakes={vehicleMakes}
        countryName={countryName}
      />
      <Box
        sx={{
          backgroundColor: colors.lightGray,
          p: 1,
          borderRadius: "12px",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "flex-end", bgcolor: colors.white, p: 1, borderRadius: "12px", textAlign: "right", mt: 2 }}>
          <Box
            onClick={() => setOpenDialog(true)}
            sx={{
              display: "flex",
              alignItems: "center",
              borderRadius: 1,
              p: 1,
              cursor: "pointer",
              color: colors.primary,
              bgcolor: colors.primaryLight,
            }}
          >
            <BsPlusLg sx={{ color: colors.primary, mr: 1, fontSize: "20px" }} />
            <Typography sx={{ fontSize: "12px" }}> Add New Model</Typography>
          </Box>
        </Box>
        <Box>
          <ModelFilter sortData={(filterObj) => sortByStatus(filterObj)} countryName={countryName} vehicleMakes={vehicleMakes} />
          {/* <ModelFilter sortData={(country, make, search) => sortByStatus(country, make, search)} countryName={countryName} vehicleMakes={vehicleMakes} /> */}
        </Box>
        {loader ? (
          <Box display="flex" justifyContent="center">
            <Loading />
          </Box>
        ) : (
          <Fragment>
            {vehicleModels.length > 0 ? (
              <TableContainer
                component={Paper}
                sx={{
                  "&::-webkit-scrollbar": {
                    bgcolor: colors.white,
                    height: "8px",
                    borderRadius: "10px",
                    cursor: "pointer",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    bgcolor: colors.primary,
                    borderRadius: "10px",
                    cursor: "pointer",
                  },
                  "&.MuiPaper-root": {
                    borderRadius: "12px",
                    mt: 2,
                    boxShadow: 0,
                  },
                }}
              >
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow sx={{ bgcolor: colors.lightGray }}>
                      {tableHeader.map((row, index) => (
                        <TableCell key={index} sx={{ fontWeight: "bold" }}>
                          {row}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {vehicleModels.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell component="th" scope="row">
                          {item?.make?.name}
                        </TableCell>
                        <TableCell>{item?.name}</TableCell>
                        <TableCell>{item?.engine_cc}</TableCell>
                        <TableCell>{item?.fuel_avg}</TableCell>
                        <TableCell>{item?.tyre_cost}</TableCell>
                        <TableCell>{item?.oil_capacity}</TableCell>
                        <TableCell>{item?.maintenance_cost}</TableCell>
                        <TableCell component="th" scope="row">
                          {item?.countries?.map((val, index) => (
                            <Fragment>{val?.country?.name && <Chip label={val?.country?.name} key={index} size="small" sx={{ m: 0.2, pt: 0.5, color: colors.bondiBlue }} />}</Fragment>
                          ))}
                        </TableCell>
                        <TableCell>
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Tooltip title="Edit">
                              <IconButton
                                onClick={() => {
                                  setOpenDialog(true);
                                  setUpdateData(item);
                                }}
                              >
                                <EditTwoTone sx={{ color: colors.secondary }} />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Delete">
                              <IconButton
                                onClick={() => {
                                  setAlertDialog(true);
                                  setDeleteId(item.id);
                                }}
                              >
                                <DeleteTwoTone sx={{ color: colors.danger }} />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <Table sx={{ marginTop: "12px" }}>
                  <TablePagination
                    sx={{ borderBottom: "none", bgcolor: colors.white, borderRadius: "12px" }}
                    rowsPerPageOptions={[10, 20, 30]}
                    colSpan={12}
                    count={+count}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: {
                        "aria-label": "rows per page",
                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </Table>
              </TableContainer>
            ) : (
              <Box sx={{ width: 1, textAlign: "center", pt: 5 }}>
                <Typography>No Data Found</Typography>
              </Box>
            )}
          </Fragment>
        )}
      </Box>
    </Fragment>
  );
}

export default VehicleModels;
