import React, { Fragment, useEffect, useState } from "react";
import { Box, Grid, Tab, Tabs, Typography } from "@mui/material";
import { BuildCircleTwoTone, ColorLensTwoTone, DirectionsCarFilledTwoTone, PersonOutlineTwoTone } from "@mui/icons-material";
import SwipeableViews from "react-swipeable-views";
import colors from "app/styles/colors";
import BreadCrumbs from "app/components/Breadcrumbs/Breadcrumbs";
import VehicleTypes from "./Manage/VehicleTypes";
import VehicleColors from "./Manage/VehicleColors";
import VehicleMakes from "./Manage/VehicleMakes";
import VehicleModels from "./Manage/VehicleModels";
import { ErrorToaster } from "app/components/Toaster/Toaster";
import VehiclesServices from "app/apis/Vehicles/VehiclesServices";
import AddressesServices from "app/apis/Addresses/AddressesServices";

function VehicleManagement() {
  // *For Tab Lists
  const tabList = [
    { icon: <PersonOutlineTwoTone />, title: "Types" },
    { icon: <BuildCircleTwoTone />, title: "Make" },
    { icon: <DirectionsCarFilledTwoTone />, title: "Model" },
    { icon: <ColorLensTwoTone />, title: "Colors" },
  ];

  //* for table page count
  const [count, setCount] = useState("");

  // *For Change Tabs
  const [currentTab, setCurrentTab] = useState(0);

  // *For Vehicle Types
  const [vehicleTypes, setVehicleTypes] = useState([]);

  // *For Vehicle Colors
  const [vehicleColors, setVehicleColors] = useState([]);

  // *For Vehicle Makes
  const [vehicleMakes, setVehicleMakes] = useState([]);

  // *For Countries Name
  const [countryName, setCountryName] = useState([]);

  // *For Vehicle Models
  const [vehicleModels, setVehicleModels] = useState([]);

  //*For Vehicles Model Loader
  const [loader, setLoader] = useState(true);
  // *For Get Vehicle Types
  const getVehicleTypes = async () => {
    try {
      const { status, responseCode, data } = await VehiclesServices.getVehicleTypes();
      if (status === true && responseCode === 200) {
        setVehicleTypes(data);
      }
    } catch (error) {
      ErrorToaster(error);
    }
  };

  // *For Get Vehicle Colors
  const getVehicleColors = async () => {
    try {
      const { status, responseCode, data } = await VehiclesServices.getVehicleColors();
      if (status === true && responseCode === 200) {
        setVehicleColors(data);
      }
    } catch (error) {
      ErrorToaster(error);
    }
  };

  // *For Get Vehicle Makes
  const getVehicleMakes = async () => {
    try {
      const { status, responseCode, data } = await VehiclesServices.getVehicleMakes();
      if (status === true && responseCode === 200) {
        setVehicleMakes(data);
      }
    } catch (error) {
      ErrorToaster(error);
    }
  };

  // *For Get Countries Name
  const getCountriesName = async () => {
    try {
      const { status, responseCode, data } = await AddressesServices.getCountries();
      if (status === true && responseCode === 200) {
        setCountryName(data.rows);
      }
    } catch (error) {
      ErrorToaster(error);
    }
  };

  // *For Get Vehicle Models
  // const getVehicleModels = async (page, rows, country, make, search) => {
  //   setLoader(true);
  //   try {
  //     const { status, responseCode, data } = await VehiclesServices.getVehicleModels(page ? page : 1, rows ? rows : 10, search ? search : "", country ? country : "", make ? make : "");
  //     if (status === true && responseCode === 200) {
  //       setVehicleModels(data.models);
  //       setCount(data.count);
  //     }
  //   } catch (error) {
  //     ErrorToaster(error);
  //   } finally {
  //     setLoader(false);
  //   }
  // };

  // * For Get Vehicle Models update 25/05/23
  const getVehicleModels = async (page, rows, filterObj) => {
    setLoader(true);
    try {
      let obj = {
        page: page ? page : 1,
        size: rows ? rows : 10,
        ...filterObj,
      };
      const params = new URLSearchParams(obj);
      const { status, responseCode, data } = await VehiclesServices.getVehicleModels(params);
      if (status === true && responseCode === 200) {
        setVehicleModels(data.models);
        setCount(data.count);
      }
    } catch (error) {
      ErrorToaster(error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    getVehicleTypes();
    getVehicleColors();
    getVehicleMakes();
    getVehicleModels("", "", { countries: 2 });
    getCountriesName();
  }, []);

  return (
    <Fragment>
      <Box sx={{ backgroundColor: colors.lightGray, p: 3, borderRadius: "12px", width: "calc(100vw - 370px)" }}>
        <Grid container alignItems="center" justifyContent="space-between" sx={{ bgcolor: colors.white, p: 2, borderRadius: "12px" }}>
          <Grid item xs={6}>
            <Typography variant="body1" fontWeight="bold" color="initial">
              Vehicles Management
            </Typography>
          </Grid>
          <Grid item xs={6} sx={{ display: "flex", justifyContent: "flex-end" }}>
            <BreadCrumbs currentPage={["Vehicles Management"]} />
          </Grid>
        </Grid>

        <Box sx={{ bgcolor: colors.white, p: 3, borderRadius: "12px", mt: 2 }}>
          {/* ========== Tabs ========== */}
          <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 1 }}>
            <Tabs value={currentTab} onChange={(e, newValue) => setCurrentTab(newValue)} sx={{ ".Mui-selected": { fontWeight: "bold" } }}>
              {tabList.map((item, index) => (
                <Tab
                  key={index}
                  icon={item.icon}
                  iconPosition="start"
                  label={item.title}
                  sx={{
                    fontWeight: 100,
                    textTransform: "capitalize",
                    minHeight: "auto",
                  }}
                />
              ))}
            </Tabs>
          </Box>

          <SwipeableViews index={currentTab}>
            {/* ========== Types ========== */}
            <VehicleTypes getVehicleTypes={getVehicleTypes} vehicleTypes={vehicleTypes} />

            {/* ========== Makes ========== */}
            <VehicleMakes getVehicleMakes={getVehicleMakes} vehicleMakes={vehicleMakes} vehicleTypes={vehicleTypes} />

            {/* ========== Models ========== */}
            <VehicleModels loader={loader} count={count} getVehicleModels={getVehicleModels} vehicleModels={vehicleModels} vehicleMakes={vehicleMakes} countryName={countryName} />

            {/* ========== Colors ========== */}
            <VehicleColors getVehicleColors={getVehicleColors} vehicleColors={vehicleColors} />
          </SwipeableViews>
        </Box>
      </Box>
    </Fragment>
  );
}

export default VehicleManagement;
