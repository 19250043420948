import React from "react";
import { Box, Dialog, DialogContent, DialogTitle, FormControl, FormControlLabel, FormLabel, Grid, IconButton, Radio, RadioGroup, Typography } from "@mui/material";
import { CloseRounded } from "@mui/icons-material";
import SelectField from "app/components/UI/SelectFields";
import { Divider } from "@mui/material";
import { InputField } from "app/components/UI/TextField";
import { PrimaryButton } from "app/components/Buttons/Button";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { SuccessToaster, ErrorToaster } from "app/components/Toaster/Toaster";
import PricingServices from "app/apis/PricingConfig/PricingServices";

const AddFuelTypeDialog = ({ open, handleClose, getFuelType, countryName }) => {
  const [loading, setLoading] = useState(false);
  const [countryId, setCountryId] = useState("");
  const [typeName, setTypeName] = useState("");
  const [radioValue, setRadioValue] = useState("petrol");
  const [currency, setCurrency] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors },
    // setValue,
    reset,
  } = useForm();

  const handleRadio = (event) => {
    setRadioValue(event.target.value);
  };

  // *For add New Fuel Type
  const addFuelType = async (formData) => {
    try {
      let obj = {
        country_id: countryId,
        fuel: formData.fuel,
        price: formData.price,
        ghg_emission: formData.ghg,
        oil_type: radioValue,
      };
      const { status, responseCode, message } = await PricingServices.createFuelType(obj);
      if (status === true && responseCode === 200) {
        SuccessToaster(message);
        reset();
        handleClose();
        getFuelType();
      }
    } catch (error) {
      ErrorToaster(error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Dialog
      open={open}
      fullWidth
      onClose={() => {
        handleClose();
      }}
      sx={{ "& .MuiDialog-paper": { borderRadius: "12px" } }}
    >
      <DialogTitle sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        Add Fuel Type
        <IconButton
          onClick={() => {
            handleClose();
          }}
        >
          <CloseRounded />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Typography component={"form"} onSubmit={handleSubmit(addFuelType)}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={6}>
              <SelectField
                setState={(item) => {
                  setCountryId(item.id);
                  setTypeName(item.name);
                  setCurrency(item.currency);
                }}
                stateValue={typeName}
                data={countryName}
                label={"Country"}
                error={errors?.countryId?.message}
                register={register("countryId", {
                  required: "Please Select Country ID.",
                })}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputField
                label="Fuel Type"
                error={errors?.fuel?.message}
                register={register("fuel", {
                  required: "Please enter ID.",
                })}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputField
                label="Price"
                type="number"
                error={errors?.price?.message}
                register={register("price", {
                  required: "Please enter Ride ID.",
                })}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputField
                label="GHG Emission"
                error={errors?.ghg?.message}
                register={register("ghg", {
                  required: "Please enter subject of ticket.",
                })}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Grid item xs={5.8} display="flex" justifyContent="flex-end">
                <Typography sx={{ fontSize: "11px", mt: -4 }}>{currency}</Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl>
                <FormLabel id="demo-controlled-radio-buttons-group">OilType</FormLabel>
                <RadioGroup row aria-labelledby="demo-controlled-radio-buttons-group" name="controlled-radio-buttons-group" value={radioValue} onChange={handleRadio}>
                  <FormControlLabel value="petrol" control={<Radio size="small" />} label="Petrol" />
                  <FormControlLabel value="diesel" control={<Radio size="small" />} label="Diesel" />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <PrimaryButton
              // data-testId="save-btn"
              type="submit"
              title={"Add"}
              loading={loading}
            />
          </Box>
        </Typography>
      </DialogContent>
    </Dialog>
  );
};

export default AddFuelTypeDialog;
