import React, { Fragment, useEffect, useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Autocomplete, Box } from '@mui/material';
import { GoogleMap, MarkerF, useJsApiLoader } from '@react-google-maps/api';
import { useForm } from 'react-hook-form';
import Geocode from "react-geocode";
import usePlacesAutocomplete from "use-places-autocomplete";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { ErrorToaster } from '../Toaster/Toaster';
import { customMapStyle } from 'app/utils/CustomData';


const googleApi = "AIzaSyCk9wRPeLZuSkgyBsJVAqQSUz59VAj-CYg"

const libraries = ['places']

Geocode.setApiKey(googleApi);
Geocode.setRegion("pk");

function MapModal({ onClose, open, currentLocation, handleAddress, updatedLocation }) {

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: googleApi,
    libraries: libraries,
  });

  const { register, handleSubmit, formState: { errors }, setValue } = useForm();

  const [updatedAddress, setUpdatedAddress] = useState("")
  const [updateCenter, setUpdateCenter] = useState({
    lat: updatedLocation.lat,
    lng: updatedLocation.lng
  })

  const [city, setCity] = useState("")

  const [selectedCity, setSelectedCity] = useState('');
  const [selectedDistrict, setSelectedDistrict] = useState('');


  let center = {
    lat: updatedLocation.lat,
    lng: updatedLocation.lng
  };

  const containerStyle = {
    width: '450px',
    height: '450px'
  };


  const findCity = (location) => {
    let city
    for (let i = 0; i < location.length; i++) {
      for (let j = 0; j < location[i].types.length; j++) {
        switch (location[i].types[j]) {
          case "locality":
            city = location[i].long_name;
            setValue("city", city)
            setCity(city)
            break;
          default:
            break;
        }
      }
    }
  }

  const geoLocation = (latitude, longitude) => {
    Geocode.fromLatLng(latitude, longitude).then(
      (response) => {
        const address = response.results[0].formatted_address;
        setUpdatedAddress(address)
        setValue("address", address)
        findCity(response.results[0].address_components)
        setUpdateCenter({
          lat: latitude,
          lng: longitude,
        })
      },
      (error) => {
        ErrorToaster(error)
      }
    );
  }

  const geoAddress = (address) => {
    Geocode.fromAddress(address).then(
      (response) => {

        findCity(response.results[0].address_components)
        setUpdateCenter({
          lat: response.results[0]?.geometry?.location?.lat,
          lng: response.results[0]?.geometry?.location?.lng,
        })
      }
    );
  }

  const handleInformation = () => {
    if (updatedAddress !== null) {
      handleAddress({ address: updatedAddress, city: city, lat: updateCenter.lat, lng: updateCenter.lng })
      onClose()
    }
    else {
      ErrorToaster("please provide address")
    }
  }

  useEffect(() => {
    if (currentLocation.latitude !== 0 && currentLocation.longitude !== 0) {
      geoLocation(currentLocation.latitude, currentLocation.longitude)
    }

  }, [currentLocation])

  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { borderRadius: "2px" } }}
      open={open}
    >
      <DialogTitle sx={{ m: 0, p: 2, fontWeight: "bold", fontSize: "1.5rem" }}>
        Is this your exact location?
      </DialogTitle>
      <Box component={"form"} onSubmit={handleSubmit(handleInformation)}>
        <DialogContent dividers>
          {isLoaded &&
            (<Fragment>
              <PlacesAutocomplete
                setUpdatedAddress={setUpdatedAddress}
                updatedAddress={updatedAddress}
                geoAddress={geoAddress}
                handleAddress={handleAddress}
                register={register("address", {
                  onChange: (e) => setUpdatedAddress(e.target.value)
                })}
              />
              <GoogleMap
                mapContainerStyle={containerStyle}
                center={updateCenter?.lat ? updateCenter : center}
                zoom={15}
                options={{
                  fullscreenControl: false,
                  streetViewControl: false,
                  mapTypeControl: false,
                  scrollwheel: false,
                  styles: customMapStyle,
                }}
              >
                <MarkerF
                  draggable
                  position={updateCenter?.lat ? updateCenter : center}
                  onDragEnd={(e) => {
                    let latitude = e.latLng.lat()
                    let longitude = e.latLng.lng()
                    geoLocation(latitude, longitude)
                  }
                  }
                />
              </GoogleMap>
            </Fragment>)
          }
          {/* {window.google === undefined ? (mapWithLoadScript) : (mapWithoutLoadScript)} */}
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center" }}>
          <Button fullWidth variant='contained' type="submit" >
            Submit
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}

export default MapModal




const PlacesAutocomplete = ({ updatedAddress, geoAddress, setUpdatedAddress }) => {
  const {
    setValue,
    suggestions: { data }
  } = usePlacesAutocomplete({
    requestOptions: {
      region: "pk",
      componentRestrictions: { country: "pk" }
    },
  });

  return (
    <Autocomplete
      sx={{ my: 2 }}
      size='small'
      fullWidth
      id="combo-box-demo"
      onInputChange={(event, newInputValue) => {
        setValue(newInputValue);
      }}
      onChange={(event, newValue) => {
        geoAddress(newValue)
        setUpdatedAddress(newValue)
      }}
      defaultValue={updatedAddress}
      value={updatedAddress}
      options={data.map((option) => option.description)}
      renderInput={(params) => <TextField {...params} label="Enter Your Pin Location" />}
    />
  );
};

