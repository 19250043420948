import { Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography } from "@mui/material";
import React from "react";
import { CloseRounded, DeleteTwoTone, SimCardDownloadTwoTone, UploadFileTwoTone } from "@mui/icons-material";
import SelectField from "app/components/UI/SelectFields";
import { Divider, TextField, FormControl } from "@mui/material";
import { InputField } from "app/components/UI/TextField";
import { PrimaryButton } from "app/components/Buttons/Button";
import { useState } from "react";
import { Title } from "chart.js";
import { useForm } from "react-hook-form";
import SupportTicketServices from "app/apis/SupportTickets/SupportTicketsServices";
import { SuccessToaster, ErrorToaster } from "app/components/Toaster/Toaster";

function AddNewTicket({ open, handleClose, getTickets, ticketType }) {
  const [loading, setLoading] = useState(false);
  const [typeId, setTypeId] = useState("");
  const [typeName, setTypeName] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm();

  // *For add New Ticket
  const addTicket = async (formData) => {
    try {
      let obj = {
        owner_id: formData.ownerId,
        type: typeId,
        ride_id: formData.rideId,
        message: formData.message,
        subject: formData.subject,
      };
      const { status, responseCode, message } = await SupportTicketServices.createTicket(obj);
      if (status === true && responseCode === 200) {
        SuccessToaster(message);
        reset();
        handleClose();
        getTickets();
      }
    } catch (error) {
      ErrorToaster(error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Dialog
      open={open}
      fullWidth
      onClose={() => {
        handleClose();
      }}
      sx={{ "& .MuiDialog-paper": { borderRadius: "12px" } }}
    >
      <DialogTitle sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        Add New Ticket
        <IconButton
          onClick={() => {
            handleClose();
          }}
        >
          <CloseRounded />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Typography component={"form"} onSubmit={handleSubmit(addTicket)}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={6}>
              <InputField
                label="Owner Id"
                type="number"
                error={errors?.ownerId?.message}
                register={register("ownerId", {
                  required: "Please enter ID.",
                })}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <SelectField
                setState={(item) => {
                  setTypeId(item.id);
                  setTypeName(item.name);
                }}
                stateValue={typeName}
                data={ticketType}
                label={"Type"}
                error={errors?.typeId?.message}
                register={register("typeId", {
                  required: "Please Select Type ID.",
                })}
              />
              {/* <InputField
                label="Type"
                error={errors?.typeId?.message}
                register={register("typeId", {
                  required: 'Please enter Type ID.',
                })
                }
              /> */}
            </Grid>
          </Grid>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={6}>
              <InputField
                label="Ride Id"
                type="number"
                error={errors?.rideId?.message}
                register={register("rideId", {
                  required: "Please enter Ride ID.",
                })}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputField
                label="Subject"
                error={errors?.subject?.message}
                register={register("subject", {
                  required: "Please enter subject of ticket.",
                })}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={12}>
              {/* <InputField
                label="Message"
                error={errors?.message?.message}
                register={register("message", {
                  required: 'Please enter your message.',
                })
                }
              /> */}
              <FormControl variant="standard" fullWidth>
                <TextField
                  multiline
                  variant="outlined"
                  label={"Message"}
                  rows={3}
                  sx={{
                    mb: 2,
                  }}
                  error={errors?.message?.message}
                  inputProps={register("message", {
                    required: "Please enter your message.",
                  })}
                />
              </FormControl>
            </Grid>
          </Grid>
          <PrimaryButton
            // data-testId="save-btn"
            type="submit"
            title={"Add Ticket"}
            loading={loading}
          />
        </Typography>
      </DialogContent>
    </Dialog>
  );
}

export default AddNewTicket;
