import React, { Fragment, useEffect, useState } from 'react'
import { useTheme } from '@emotion/react'
import { Box, Grid, IconButton, Paper, Switch, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, ToggleButton, ToggleButtonGroup, Tooltip, tooltipClasses, Typography } from '@mui/material'
import { AddBusinessTwoTone, DeleteTwoTone, KeyboardArrowLeft, KeyboardArrowRight, VisibilityTwoTone } from '@mui/icons-material'
import styled from '@emotion/styled'
import { useNavigate } from 'react-router-dom'
import CompaniesServices from 'app/apis/Companies/CompaniesServices'
import { ErrorToaster, SuccessToaster } from 'app/components/Toaster/Toaster'
import Layout from 'app/layout/Layout'
import colors from 'app/styles/colors'
import { Loading } from 'app/assets/Images'
import CompanyFilter from 'app/components/Filters/CompanyFilter'
import BreadCrumbs from 'app/components/Breadcrumbs/Breadcrumbs'
import SimpleDialog from 'app/components/Dialog/ValidationDialog'
import SecondaryCard from 'app/components/Cards/SecondaryCard'
import { BsFillCalendar2MinusFill } from 'react-icons/bs'
import clsx from 'clsx'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
  iconStyle: {
    fontSize: "30px",
    borderRadius: "8px",
    zIndex: 99,
    padding: "10px",
    position: "relative",
    top: -15
  },
  smallIconStyle: {
    borderRadius: "8px",
    padding: 8,
    fontSize: "24px",
    marginRight: 12,
  },
  typeIconBgColor: {
    backgroundColor: "#a8b1af66"
  },
  makeIconBgColor: {
    backgroundColor: "#eddaa98f"
  },
})

function EmissionReports() {

  const navigate = useNavigate()
  const classes = useStyles()

  const [alignment, setAlignment] = useState('quarterly');

  const handleChange = (event, value) => {
    setAlignment(value);
  };

  return (
    <Fragment>

      <Box sx={{ backgroundColor: colors.lightGray, p: 3, borderRadius: "12px", width: "calc(100vw - 370px)" }}>
        <Grid container
          alignItems="center"
          justifyContent="space-between"
          sx={{
            bgcolor: colors.white, p: 2, borderRadius: "12px",
          }}>
          <Grid item xs={6}>
            <Typography variant="body1" fontWeight="bold" color="initial">CO2 Emissions Reports</Typography>
          </Grid>
          <Grid item xs={6} sx={{ display: "flex", justifyContent: "flex-end" }}>
            <BreadCrumbs currentPage={['Co2 Emission Report']} />
          </Grid>
        </Grid>

        {/* <Box sx={{ display: "flex", justifyContent: "flex-end", bgcolor: colors.white, p: 1, borderRadius: "12px", textAlign: "right", my: 2 }}>
          <ToggleButtonGroup
            color="primary"
            value={alignment}
            exclusive
            onChange={handleChange}
            aria-label="Platform"
          >
            <ToggleButton value="quarterly">Quarterly</ToggleButton>
            <ToggleButton value="yearly">Yearly</ToggleButton>
          </ToggleButtonGroup>
        </Box> */}
        <Box sx={{ my: 2 }}>
          <Typography variant="h5" fontWeight="bold" color="initial">Quarterly</Typography>
        </Box>
        <Grid container spacing={1.5} justifyContent={"space-between"} >
          <Grid item xs={12} md={4}>
            <SecondaryCard
              icon={<BsFillCalendar2MinusFill
                className={clsx(classes.smallIconStyle, classes.makeIconBgColor)}
                color={colors.artyClickAmber}
              />}
              number={"12.8 kg"}
              name={"Co2 Footprint could've been produced"}
              color={colors.gunMetal}
              nameColor={colors.artyClickAmber}
              bgColor={colors.eggSour}
              circle1={"#ffc1073d"}
              circle2={colors.eggSour}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <SecondaryCard
              icon={<BsFillCalendar2MinusFill
                className={clsx(classes.smallIconStyle, classes.makeIconBgColor)}
                color={colors.primaryLight}
              />}
              number={'20.6 kg'}
              name={"CO2 Footprint Produce"}
              color={colors.primaryLight}
              nameColor={colors.primaryLight}
              bgColor={colors.secondary}
              circle1={"#100f167a"}
              circle2={"#ffffff2e"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <SecondaryCard
              icon={<BsFillCalendar2MinusFill
                className={clsx(classes.smallIconStyle, classes.makeIconBgColor)}
                color={colors.primaryLight}
              />}
              number={"10.4 kg"}
              name={"Co2 Footprint Saved"}
              color={colors.primaryLight}
              nameColor={colors.primaryLight}
              bgColor={colors.primary}
              circle1={"#0c7548d4"}
              circle2={"#3f6a5838"}
            />
          </Grid>
        </Grid>
        <Box sx={{ my: 2 }}>
          <Typography variant="h5" fontWeight="bold" color="initial">Yearly</Typography>
        </Box>
        <Grid container spacing={1.5} justifyContent={"space-between"} >
          <Grid item xs={12} md={4}>
            <SecondaryCard
              icon={<BsFillCalendar2MinusFill
                className={clsx(classes.smallIconStyle, classes.makeIconBgColor)}
                color={colors.artyClickAmber}
              />}
              number={"27.6 kg"}
              name={"Co2 Footprint could've been produced"}
              color={colors.gunMetal}
              nameColor={colors.artyClickAmber}
              bgColor={colors.eggSour}
              circle1={"#ffc1073d"}
              circle2={colors.eggSour}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <SecondaryCard
              icon={<BsFillCalendar2MinusFill
                className={clsx(classes.smallIconStyle, classes.makeIconBgColor)}
                color={colors.primaryLight}
              />}
              number={'50.1 kg'}
              name={"CO2 Footprint Produce"}
              color={colors.primaryLight}
              nameColor={colors.primaryLight}
              bgColor={colors.secondary}
              circle1={"#100f167a"}
              circle2={"#ffffff2e"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <SecondaryCard
              icon={<BsFillCalendar2MinusFill
                className={clsx(classes.smallIconStyle, classes.makeIconBgColor)}
                color={colors.primaryLight}
              />}
              number={"45.8 kg"}
              name={"Co2 Footprint Saved"}
              color={colors.primaryLight}
              nameColor={colors.primaryLight}
              bgColor={colors.primary}
              circle1={"#0c7548d4"}
              circle2={"#3f6a5838"}
            />
          </Grid>
        </Grid>
      </Box>

    </Fragment>
  )
}

export default EmissionReports