import Layout from 'app/layout/Layout'
import React, { Fragment, useEffect, useState } from 'react'
import colors from 'app/styles/colors';
import { Box, Divider, FormControl, Grid, InputLabel, OutlinedInput, Select, TextField, Typography, Chip, MenuItem } from '@mui/material';
import BreadCrumbs from 'app/components/Breadcrumbs/Breadcrumbs';
import { Loading } from 'app/assets/Images';
import { ImageAvatar } from 'app/components/Avatar/ImageAvatar';
import { PrimaryButton } from 'app/components/Buttons/Button';
import SelectField from 'app/components/UI/SelectFields';
import { SuccessToaster, ErrorToaster } from 'app/components/Toaster/Toaster';
import SupportTicketServices from 'app/apis/SupportTickets/SupportTicketsServices';
import { useParams } from 'react-router-dom';
import { getDate, getTime, relativeTime } from './../../utils/index';

function SupportTicketDetail() {
  const ticketId = useParams()
  const [text, setText] = useState('')
  const [ticketDetails, setTicketDetails] = useState([])
  // console.log("🚀 ~ file: SupportTicketDetail.js:19 ~ SupportTicketDetail ~ ticketDetails:", ticketDetails)
  // *For Loading
  const [loading, setLoading] = useState(false);
  //* Api For Get Detail of Current Ticket
  const getTicketDetails = async () => {
    try {
      setLoading(true)
      const { status, responseCode, data } = await SupportTicketServices.getTicketDetails(ticketId.id)
      if (status === true && responseCode === 200) {
        setTicketDetails(data)
      }
    } catch (error) {
      ErrorToaster(error)
    } finally {
      setLoading(false)
    }
  }
  //* Api and functions For Update Status of Ticket
  const [statusValue, setStatusValue] = useState("");
  const [priorityValue, setPriorityValue] = useState("");

  const status = ["open", "pending", "resolved", "closed"]
  const priority = ["low", "medium", "high"]
  const handlePriority = (event, purpose) => {
    setPriorityValue(event.target.value);
    updateTicketStatus(event.target.value, purpose);
  };

  const handleStatus = (event, purpose) => {
    setStatusValue(event.target.value);
    updateTicketStatus(event.target.value, purpose);
  };
  const updateTicketStatus = async (val, purpose) => {

    if (purpose === "status") {
      let obj = {
        status: val,
        ticket_id: parseInt(ticketId.id),
      }
      try {
        const { status, responseCode, message } = await SupportTicketServices.updateTicketStatus(obj)
        if (status === true && responseCode === 200) {
          SuccessToaster(message)
        }
      }
      catch (error) {
        ErrorToaster(error)
      }
    }
    else {
      let obj = {
        ticket_id: ticketId.id,
        priority: val,
      }
      try {
        const { status, responseCode, message } = await SupportTicketServices.updateTicketStatus(obj)
        console.log("🚀 ~ file: SupportTicketDetail.js:75 ~ updateTicketStatus ~ message:", message)
        if (status === true && responseCode === 200) {
          SuccessToaster("Priority Updated")
        }
      }
      catch (error) {
        ErrorToaster(error)
      }
    }
  }
  //* Api For Sending Message or Reply to User
  const sendTicket = async (text) => {
    let obj = {
      message: text,
      ticket_id: ticketId.id,
      message_from: "support",
    }
    try {
      const { status, responseCode, message } = await SupportTicketServices.sendTicket(obj)
      if (status === true && responseCode === 200) {
        SuccessToaster(message)
        setText("")
      }
    } catch (error) {
      ErrorToaster(error)
    } finally {
      getTicketDetails(ticketId.id)
    }
  }

  const postComment = () => {
    if (text) {
      sendTicket(text)
    }
  }
  useEffect(() => {
    getTicketDetails()
  }, [])

  return (
    <Fragment>
      <Box sx={{ backgroundColor: colors.lightGray, p: 3, borderRadius: "12px", width: "calc(100vw - 370px)" }}>
        <Grid container
          alignItems="center"
          justifyContent="space-between"
          sx={{
            bgcolor: colors.white, p: 2, borderRadius: "12px",
          }}>
          <Grid item xs={6}>
            <Typography variant="body1" fontWeight="bold" color="initial">Ticket Detail</Typography>
          </Grid>
          <Grid item xs={6} sx={{ display: "flex", justifyContent: "flex-end" }}>
            <BreadCrumbs currentPage={['Support Ticket', 'Ticket Detail']} />
          </Grid>
        </Grid>
        {loading ? (
          <Box sx={{ width: 1, textAlign: "center", pt: 5 }}>
            <Loading />
          </Box>
        ) : (
          <Fragment>
            <Box sx={{ backgroundColor: colors.white, p: 2, my: 2, borderRadius: "12px", display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant="body1" >Ticket ID: <b>{ticketDetails?.id}</b></Typography>
              <Typography variant="body1" >Created at: <b>{getDate(ticketDetails?.createdAt)}</b> - {getTime(ticketDetails?.createdAt)}</Typography>
            </Box>
            <Box sx={{ backgroundColor: colors.white, p: 2, my: 2, borderRadius: "12px" }}>
              {/* <Typography variant="body1" fontWeight="bold" >User</Typography>
              <Divider flexItem sx={{ my: 1 }} /> */}
              <Box sx={{ my: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <ImageAvatar image={ticketDetails?.owner?.picture} />
                  <Typography variant="body1" sx={{ ml: 1 }} >{ticketDetails?.owner?.name}</Typography>
                </Box>
                <Box display="flex">
                  <Box sx={{}}>
                    <Typography sx={{ fontSize: "12px" }}>Current Status</Typography>
                    <FormControl size="small" sx={{
                      mr: 1, width: 130,
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderRadius: "10px",
                      }
                    }}>
                      {/* <InputLabel id="multiple-chip-label" shrink={false} >
                        <Chip size='small'
                          sx={{ minWidth: "75px", }}
                          label={ticketDetails?.status} />
                      </InputLabel> */}
                      <Select
                        labelId="multiple-chip-label"
                        id="multiple-chip"
                        defaultValue={ticketDetails?.status}
                        onChange={(e) => handleStatus(e, "status")}
                        // input={<OutlinedInput id="multiple-chip" label="Chip" />}
                        renderValue={(selected) => (
                          <Box sx={{ display: 'flex', }}>
                            <Chip size='small'
                              sx={{
                                "&.MuiChip-root": {
                                  color: selected === "pending" ? colors.pending :
                                    selected === "closed" ? colors.grapeFruit :
                                      selected === "resolved" ? colors.darkMintGreen : colors.bondiBlueLight,
                                  bgcolor: colors.dairyCream
                                },
                                minWidth: "75px",
                              }} key={selected} label={selected} />
                          </Box>
                        )}
                      >
                        {status.map((name) => (
                          <MenuItem
                            key={name}
                            value={name}
                            sx={{
                              "&.Mui-selected": {
                                bgcolor: colors.white, color: colors.white,
                                ":hover": {
                                  bgcolor: colors.white, color: colors.white,
                                }
                              },
                            }}
                          // style={getStyles(name, personName, theme)}
                          >
                            <Chip sx={{ minWidth: "75px" }} label={name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                  {/* For Priority */}
                  <Box sx={{ width: "160px" }}>
                    <Typography sx={{ ml: 1, fontSize: "12px" }}>Priority</Typography>
                    <FormControl size="small" sx={{
                      ml: 1, width: 130,
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderRadius: "10px",
                      }
                    }}>
                      {/* <InputLabel id="multiple-chip-label2" shrink={false}>
                        <Chip size='small'
                          sx={{ minWidth: "75px", }}
                          label={ticketDetails?.priority} />
                      </InputLabel> */}
                      <Select
                        labelId="multiple-chip-label2"
                        id="multiple-chip2"
                        defaultValue={ticketDetails?.priority}
                        onChange={(e) => handlePriority(e, "priority")}
                        // input={<OutlinedInput id="multiple-chip2" label="Chip2" />}
                        renderValue={(selected) => (
                          // console.log("<=======>", selected)
                          <Box sx={{ display: 'flex', }}>
                            <Chip
                              size='small'
                              sx={{
                                // "&.MuiChip-root": { bgcolor: colors.primaryLight },
                                "&.MuiChip-root": {
                                  color: colors.white,
                                  bgcolor: selected === "medium" ? colors.candleLight :
                                    selected === "low" ? colors.bondiBlueLight : colors.danger
                                },
                                minWidth: "75px",
                              }} key={selected} label={selected} />
                          </Box>
                        )}

                      >
                        {priority.map((name) => (
                          <MenuItem
                            key={name}
                            value={name}
                            sx={{
                              "&.Mui-selected": {
                                bgcolor: colors.white, color: colors.white,
                                ":hover": {
                                  bgcolor: colors.white, color: colors.white,
                                }
                              },
                            }}
                          // style={getStyles(name, personName, theme)}
                          >
                            <Chip sx={{ minWidth: "75px" }} label={name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </Box>
              </Box>
              <Box sx={{ my: 2 }}>
                <Typography variant="body1" sx={{ ml: 1 }} >{ticketDetails?.subject}</Typography>
              </Box>
              <Divider flexItem sx={{ my: 1 }} />
              <Box sx={{ backgroundColor: colors.white, p: 2, my: 1, borderRadius: "12px" }}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} md={12}>
                    <TextField fullWidth multiline variant="outlined" label={"write comment"}
                      onChange={(e) => setText(e.target.value)}
                      rows={3}
                      sx={{
                        mb: 2,
                      }}
                    />
                  </Grid>
                </Grid>
                <PrimaryButton
                  onClick={postComment}
                  // data-testId="save-btn"
                  type="submit"
                  title={"Post"}
                />
              </Box>
              {ticketDetails?.messages?.map((item, index) => (
                <Box key={index} sx={{ width: "90%", pl: 6 }}>
                  {item.user_type === "user" ? (

                    <Box key={index} sx={{ width: "40%", my: 2, display: 'flex' }}>
                      <Box sx={{ pr: 1, }}>
                        <ImageAvatar image={item.user_type === "user" ? ticketDetails?.owner?.picture : ticketDetails?.support?.picture} />
                      </Box>
                      <Box>
                        <Box sx={{
                          width: "100%", p: 1, mr: 1,
                          bgcolor: colors.lightGray,
                          borderRadius: "8px",
                        }}>
                          <Typography variant="body2" sx={{ p: 0.5, color: colors.textLight }}>
                            {ticketDetails?.owner?.name}
                          </Typography>
                          <Typography sx={{ p: 0.5 }}>{item.message}</Typography>
                        </Box>
                        <Box>
                          <Typography sx={{ fontSize: "11px", color: colors.primary }}>{relativeTime(item.createdAt)}</Typography>
                        </Box>
                      </Box>

                    </Box>
                  ) : (
                    <Box display="flex" justifyContent="flex-end" p={1}  >
                      <Box display="flex" flexDirection="column" alignItems="flex-end" width="30%" >
                        <Box sx={{ py: 1.5, px: 1, mb: 0.5, width: "100%", borderRadius: "8px", backgroundColor: colors.lightGray }}>
                          <Typography variant="body2" sx={{ pb: 1, color: colors.textLight }}>
                            {ticketDetails?.support?.name}
                          </Typography>
                          <Typography sx={{ mr: 2.5 }}>
                            {item.message}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography sx={{ fontSize: "11px", color: colors.primary }}>{relativeTime(item.createdAt)}</Typography>
                        </Box>
                      </Box>
                      <Box sx={{ pl: 1 }}>
                        <ImageAvatar image={ticketDetails?.support?.picture} />
                      </Box>
                    </Box>
                  )}
                </Box>
              ))}
            </Box>
          </Fragment>
        )}
      </Box>
    </Fragment>
  )
}

export default SupportTicketDetail