import React, { useState } from "react";
import { Box, Button, Checkbox, Chip, Collapse, FormControl, Grid, IconButton, InputAdornment, InputLabel, ListItemText, MenuItem, Select, TextField, Typography } from "@mui/material";
import { FilterAltTwoTone, Search } from "@mui/icons-material";
import colors from "./../../styles/colors";
import { Fragment } from "react";

const FuelTypeFilter = ({ dataFilter, countryName }) => {
  const [filterCollapse, setFilterCollapse] = useState(true);

  const [search, setSearch] = useState("");

  const [countrySelected, setCountrySelected] = useState([2]);
  const handleCountryChange = (event) => {
    const {
      target: { value },
    } = event;
    setCountrySelected(value);
  };

  // *updated on 25/5/23
  const filterData = () => {
    let filterObj = {
      countries: countrySelected,
      search: search,
    };
    dataFilter(filterObj);
  };

  // const filterData = () => {
  //   dataFilter(countrySelected, search);
  // };

  const clearData = () => {
    dataFilter("");
    setSearch("");
    setCountrySelected([]);
    setFilterCollapse(!filterCollapse);
  };

  return (
    <Box sx={{ bgcolor: colors.white, p: 2, borderRadius: "12px", mt: 2 }}>
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <Typography variant="body1" fontWeight="bold" color="initial">
          Filters
        </Typography>
        <IconButton onClick={() => setFilterCollapse(!filterCollapse)} color="primary" sx={{ bgcolor: colors.primaryLight }}>
          <FilterAltTwoTone />
        </IconButton>
      </Box>

      <Collapse in={filterCollapse}>
        <Grid container spacing={1} justifyContent="start" sx={{ mt: 1 }}>
          <Fragment>
            <Grid item xs={3}>
              <FormControl sx={{ my: 1 }}>
                <TextField label="Search" size={"small"} variant="outlined" onChange={(e) => setSearch(e.target.value)} />
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <FormControl size="small" fullWidth variant="outlined">
                <InputLabel> Select Countries</InputLabel>
                <Select
                  multiple
                  label={"Select Countries"}
                  value={countrySelected}
                  onChange={handleCountryChange}
                  renderValue={(selected) => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.2 }}>
                      {selected.map((item, index) => (
                        <Chip key={index} label={countryName?.find((e) => e.id === item)?.name} />
                      ))}
                    </Box>
                  )}
                  sx={{ textAlign: "left" }}
                >
                  {countryName?.map((item, index) => (
                    <MenuItem key={index} value={item.id}>
                      <Checkbox checked={countrySelected?.indexOf(item.id) > -1} />
                      <ListItemText primary={`${item.id}-${item.name}`} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <Box sx={{ p: 1, display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                <Button
                  onClick={clearData}
                  variant="outlined"
                  sx={{
                    mx: 1,
                    textTransform: "capitalize",
                    minWidth: "100px",
                    boxShadow: "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
                  }}
                >
                  Clear
                </Button>
                <Button
                  onClick={filterData}
                  variant="contained"
                  sx={{
                    textTransform: "capitalize",
                    minWidth: "100px",
                    boxShadow: "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
                  }}
                >
                  Search
                </Button>
              </Box>
            </Grid>
          </Fragment>
        </Grid>
      </Collapse>
    </Box>
  );
};

export default FuelTypeFilter;
