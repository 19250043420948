import React from 'react';
import { LoadingButton } from '@mui/lab';
import colors from 'app/styles/colors';

export const PrimaryButton = (props) => {
  return (
    // <LoadingButton
    //   disabled={disable}
    //   variant="contained"
    //   type={type}
    //   onClick={onClick}
    //   loading={loading}
    //   fullWidth={fullWidth}
    //   sx={{
    //     ...myStyle,
    //     textTransform: 'capitalize',
    //     boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)'
    //   }}
    // >
    //   {children}
    // </LoadingButton>
    <LoadingButton
      variant="contained"
      {...props}
      sx={{
        textTransform: 'capitalize',
        minWidth: '100px',
        boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
      }}
    >
      {props.title}
    </LoadingButton>
  );
}

export const SecondaryButton = (props) => {
  return (
    // <LoadingButton
    //   disabled={disable}
    //   variant="contained"
    //   type={type}
    //   onClick={onClick}
    //   loading={loading}
    //   fullWidth={fullWidth}
    //   sx={{
    //     ...myStyle,
    //     textTransform: 'capitalize',
    //     boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)'
    //   }}
    // >
    //   {children}
    // </LoadingButton>
    <LoadingButton
      variant="contained"
      {...props}
      sx={{
        textTransform: 'capitalize',
        minWidth: '100px',
        bgcolor: colors.secondary,
        boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
        "&:hover": { bgcolor: colors.secondaryDark }
      }}
    >
      {props.title}
      {/* <PrimaryButton
        onClick={() => handlePromise(false)}
        myStyle={{ my: 2, px: 4, borderRadius: "12px", bgcolor: colors.secondary, "&:hover": { bgcolor: colors.secondaryDark } }}
      >No</PrimaryButton> */}
    </LoadingButton>
  );
}